import interpolationService from './interpolation.service';
import { getCoord } from '@turf/invariant';
import { VectorSourceService } from './vector-source.service';
import { IRegisterSensorState } from '../../features/sensors/actions/sensors.actions';

class VectorSourceProvider {
	private sources = {} as { [key: string]: VectorSourceService };

	provide(sensor: IRegisterSensorState) {
		if (this.sources[sensor.sensorType]) {
			return this.sources[sensor.sensorType];
		}
		this.sources[sensor.sensorType] = new VectorSourceService(sensor);
		return this.sources[sensor.sensorType];
	}

	setDotVisibility(sensorType: string, dotVisibility: boolean) {
		if (!this.sources[sensorType]) {
			return;
		}
		this.sources[sensorType].setDotVisibility(dotVisibility);

	}

	setLineVisibility(sensorType: string, lineVisibility: boolean) {
		if (!this.sources[sensorType]) {
			return;
		}
		this.sources[sensorType].setLineVisibility(lineVisibility);
	}

	addNewFeature(sensorType: string, point: any,  previousPoint: any, sensorFollowRoute: boolean) {
		if (!this.sources[sensorType]) {
			return;
		}
		this.sources[sensorType].addNewFeature(point, previousPoint);
		if (sensorFollowRoute) {
			interpolationService.addPosition(getCoord(point), point.properties.rotation, Date.now());
		}
	}

	setFollowRoute(value: boolean){
		Object.values(this.sources).forEach(source => {
			source.setFollowRoute(value);
		});
	}

	removeFeaturesFromHead(sensorType: string, count: number) {
		if (!this.sources[sensorType]) {
			return;
		}
		this.sources[sensorType].removeFeaturesFromHead(count);
	}

	clearAll() {
		Object.values(this.sources).forEach(source => {
			source.clear();
		});
		interpolationService.clear();
	}
}

export default new VectorSourceProvider();
