import { getSeverityColors } from '../../definition/severity';
import { config } from '../config';

const hazardStyleCache: string[] = [];
const safetyStyleCache: string[] = [];
const toasterStyleCache: string[] = [];
const circleCache: string[] = [];

export const cartesian = (...a: Array<Array<any>>) => a.reduce((a, b) => a.flatMap(d => b.map(e => [d, e].flat())));

export const selectedOptions = [true, false];
export const severityOptions = [0, 1, 2, 3];

const makeSVGForBrowser = (svg: string) => {
	return 'data:image/svg+xml;utf8,' + encodeURIComponent(svg);
};

const getCircle = (severityColor: string, isSelected: boolean) => {
	const key = `${severityColor}|${isSelected}`;
	if (circleCache[key]) {
		return circleCache[key];
	}
	circleCache[`${severityColor}|${true}`] = `<use stroke="#0F2365" stroke-width="3" fill="${severityColor}" xlink:href="#path-1"></use>`;
	circleCache[`${severityColor}|${false}`] = `<use fill="${severityColor}" xlink:href="#path-1"></use>`;
    return circleCache[key];
};

export const generateHazardIcon = (
	type: number,
	isSelected: boolean,
	severity: number
): string => {
	const styleId = `${isSelected}|${type}|${severity}`;
	if (hazardStyleCache[styleId]) {
		return hazardStyleCache[styleId];
	}
	const hazardAndSafetyIcons = config.hazardAndSafetyIcons;
	const path: string = hazardAndSafetyIcons.hazards[type].path;
	const transform: string = hazardAndSafetyIcons.hazards[type].transform;
	const severityColor: string = getSeverityColor(severity);

	const circle = getCircle(severityColor, isSelected);

	const generatedSVG: string = generateHazardSVG(
		path,
		transform,
		severity,
		isSelected,
		circle
	);

	saveToCache('hazard', type, path, severity, severityColor, transform);

	return makeSVGForBrowser(generatedSVG);
};

const saveToCache = (
	cacheName: string,
	type: number,
	path: string,
	severity: number,
	severityColor: string,
	HazardTransformOrLetters: string = ''
) => {
	if (cacheName === 'hazard') {
		hazardStyleCache[
			`${true}|${type}|${severity}`
		] = makeSVGForBrowser(
			generateHazardSVG(
				path,
				HazardTransformOrLetters,
				severity,
				true,
				getCircle(severityColor, true)
			)
		);
		hazardStyleCache[
			`${false}|${type}|${severity}`
		] = makeSVGForBrowser(
			generateHazardSVG(
				path,
				HazardTransformOrLetters,
				severity,
				false,
				getCircle(severityColor, false)
			)
		);
	} else {
		if (cacheName === 'safety') {
			safetyStyleCache[
				`${true}|${type}|${severity}`
			] = makeSVGForBrowser(
				generateSafetySVG(
					HazardTransformOrLetters,
					path,
					severity,
					true,
					getCircle(severityColor, true)
				)
			);
			safetyStyleCache[
				`${false}|${type}|${severity}`
			] = makeSVGForBrowser(
				generateSafetySVG(
					HazardTransformOrLetters,
					path,
					severity,
					false,
					getCircle(severityColor, false)
				)
			);
		} else {
			//if its toaster
			toasterStyleCache[`${type}|white`] = makeSVGForBrowser(
				generateToasterSVG(type, 'white')
			);
			toasterStyleCache[`${type}|gray`] = makeSVGForBrowser(
				generateToasterSVG(type, 'gray')
			);
		}
	}
};
const saveToCacheToaster = (obstacleType: number) => {
	return saveToCache('toaster', obstacleType, '', 0, '');
};

export const generateSafetyIcon = (
	alert: number,
	isSelected: boolean,
	severity: number
): string => {
	const styleId = `${isSelected}|${alert}|${severity}`;
	if (safetyStyleCache[styleId]) {
		return safetyStyleCache[styleId];
	}

	const hazardAndSafetyIcons = config.hazardAndSafetyIcons;

	const path: string = hazardAndSafetyIcons.safeties[alert].path;
	const letters: string = hazardAndSafetyIcons.safeties[alert].name;
	const severityColor: string = getSeverityColor(severity);
	const circle = getCircle(severityColor, isSelected);
	saveToCache('safety', alert, path, severity, severityColor, letters);

	const generatedSVG: string = generateSafetySVG(
		letters,
		path,
		severity,
		isSelected,
		circle
	);
	return makeSVGForBrowser(generatedSVG);
};

const severityToColorMap = {
    0: '#FFDC7E',
    1: '#FBAE26',
    2: '#FF7100',
    3: '#D5001A',
    default: 'pink'
}

export const getSeverityColor = (severity: number): string => {
    let color = severityToColorMap[severity];
    if (color === undefined) {
        color = severityToColorMap.default;
    }
    return color;
};

const generateSafetySVG = (
	letters: string,
	path: string,
	severity: number,
	isSelected: boolean,
	circle: string
): string => {
	const selectedStr: string = isSelected ? 'Selected' : '';
	const fillColorOfLetters = getSeverityColors(severity).text;
	const svg: string = `<svg width="48px" height="48px" viewBox="0 0 48 48" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
		 <title>${letters}${severity}${selectedStr}</title> <defs> 
		<circle id="path-1" cx="20" cy="20" r="20"></circle> 
		<filter x="-17.5%" y="-12.5%" width="135.0%" height="135.0%" filterUnits="objectBoundingBox" id="filter-2">
		<feOffset dx="0" dy="2" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
		<feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur> 
		<feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix></filter> </defs><g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"> 
		<g id="${letters}${severity}${selectedStr}" transform="translate(4.000000, 2.000000)" fill-rule="nonzero"> <g id="Oval"> <use fill="black" fill-opacity="1" filter="url(#filter-2)" xlink:href="#path-1"></use> 
		${circle} </g> <path d="${path}" id="${letters}"
         fill="${fillColorOfLetters}"></path> </g> </g> 
		</svg>`;
	return svg.replace(/\t|\n/g, '');
};

const generateHazardSVG = (
	path: string,
	transform: string,
	severity: number,
	isSelected: boolean,
	circle: string
): string => {
	const selectedStr: string = isSelected ? 'Selected' : '';
	const fillColorOfLetters = getSeverityColors(severity).text;
	const svg: string =
		// `<?xml version="1.0" encoding="UTF-8"?>
		`<svg width="69px" height="69px" viewBox="0 0 69 69" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
			<title>Hazard${severity}${selectedStr}</title>
			<defs>
				<rect id="path-1" x="0" y="0" width="62" height="62" rx="29.4642857"></rect>
				<filter x="-11.7%" y="-8.3%" width="123.3%" height="123.3%" filterUnits="objectBoundingBox" id="filter-2">
					<feOffset dx="0" dy="2" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
					<feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
					<feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
				</filter>
			</defs>
			<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
			<g id="Tablet-Copy" transform="translate(4,2)" >
<g id="Group-30" ><g id="Rectangle-Copy-10"><use fill="black" fill-opacity="1" filter="url(#filter-2)" xlink:href="#path-1"></use>${circle}</g><g id="Hazard${severity}${selectedStr}" transform="${transform}" fill="${fillColorOfLetters}" fill-rule="nonzero"><path d="${path}" id="Shape"></path></g></g></g></g></svg>`;
	return svg.replace(/\t|\n/g, '');
};

export const generateToasterIcon = (
	obstacleType: number,
	color: string
): string => {
	const styleId = `${obstacleType}|${color}`;
	if (toasterStyleCache[styleId]) {
		return toasterStyleCache[styleId];
	}
	saveToCacheToaster(obstacleType);

	return makeSVGForBrowser(generateToasterSVG(obstacleType, color));
};

const generateToasterSVG = (obstacleType: number, color: string): string => {
	const fillColor: string = color === 'white' ? '#FFFFFF' : '#575656';
	const hazardAndSafetyIcons = config.hazardAndSafetyIcons;
	const path: string = hazardAndSafetyIcons.hazards[obstacleType].path;

	const svg: string = `<svg width="40px" height="40px" viewBox="0 0 41 41" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>Hazard ${obstacleType} ${color}</title>
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Hazard ${obstacleType} ${color}" fill="${fillColor}" fill-rule="nonzero">
            <path d="${path}" id="Shape"></path>
        </g>
    </g>
</svg>`;

	return svg.replace(/\t|\n/g, '');
};
