import React, { useEffect, useState } from 'react';
import useStyles from './truckAlerts.css';
import { useSelector} from 'react-redux';
import { getIsBermVisible, getReverseData } from '../../../store/selectors/mining/mining.selectors';
import olMapService from '../../../core/services/ol-map.service';
import Truck from './truck/Truck';
import DiscAlert from './diskAlert/DiscAlert';
import Arrow from './arrow/Arrow';
import logo from '../../../assets/images/logo.png';

const truckWidthMeters = parseInt(process.env.REACT_APP_VEHICLE_WIDTH_METERS as string, 10);
// const truckImageRatio = parseFloat(process.env.REACT_APP_TRUCK_IMAGE_RATIO as string);
const vehiclePosition = parseFloat(process.env.REACT_APP_REVERSE_AXIS_POSITION as string);
// const vehiclePositionOnAxis = 1 - parseFloat(process.env.REACT_APP_REVERSE_VEHICLE_PORTION_ON_AXIS as string);

function TruckAlerts() {
	const classes = useStyles();
	const reverseData = useSelector(getReverseData);
	const isBermVisible = useSelector(getIsBermVisible);
	const [truckWidthPX, setTruckWidthPX] = useState(200);
	const [resolution, setResolution] = useState(200);

	useEffect(() => {
		const res = olMapService.getResolution();
		setTruckWidthPX(truckWidthMeters / res);
		setResolution(res);
	});
	// const disc1Position = window.innerHeight*vehiclePosition + truckWidthPX * truckImageRatio * vehiclePositionOnAxis -28;
	const disc1Position = window.innerHeight*vehiclePosition+5;
	const disc3Position = Math.min(window.innerHeight*vehiclePosition + reverseData.DistanceToBermCm/(100*resolution),window.innerHeight -60);
	const disc2Position = (disc1Position + disc3Position)/2;
	const showDisks = isBermVisible && (disc3Position- disc1Position) > 100;
	const isAlert = isBermVisible && ((reverseData.SpeedAlert > 0) || (reverseData.ApproachAngleAlert > 0) || (reverseData.MidAxleHeightAlert > 0));
	return (
		<div className={classes.root}>
			<Truck truckWidth={truckWidthPX}/>
			{isBermVisible && <DiscAlert isAlert={reverseData.SpeedAlert > 0} symbol={'SD'} label1={`${reverseData.SpeedKMH} KMH`} label2={`${reverseData.DistanceToBermCm/100} M`} position={disc1Position}/>}
			{showDisks && <DiscAlert isAlert={reverseData.ApproachAngleAlert > 0} symbol={'AA'} label1={`${reverseData.ApproachAngle}°`} position={disc2Position}/>}
			{showDisks && <DiscAlert isAlert={reverseData.MidAxleHeightAlert > 0} symbol={'BH'} label1={`${reverseData.BermHeightCM/100} M`} position={disc3Position}/>}
			{showDisks && <Arrow isAlert={isAlert} start={disc1Position} end={disc3Position}/>}
			<img src={logo} className={classes.reverseScreenLogo} />
		</div>
	);
}

export default TruckAlerts;
