import 'ol/ol.css';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { setActiveMenu } from '../../../store/actions/core.actions';
import xIcon from '../../../assets/images/x-icon.svg';
import './CreateNewLayerPanel.scss';
import { InputText } from 'primereact/inputtext';
import { createAdasLayer } from '../effects/adas-layers.effects';
import { Button } from 'primereact/button';

interface IProps {
	display: boolean;
	activeMenu: string;
	setActiveMenu: (string) => void;
	createAdasLayer: (string) => void;
	errorMessageAction: string;
}

interface ICreateState {
	title: string;
}

class CreateNewLayerPanel extends Component<IProps, ICreateState> {

	state: ICreateState = {
		title: ''
	};

	constructor(props) {
		super(props);
	}

	componentDidUpdate(prevProps) {
		if (prevProps.display !== this.props.display && this.props.display) {
			const element = document.getElementById('float-input');
			if (element) {
				element.focus();
			}
		}
	}

	initState() {
		this.setState({ title: '' });
	}

	get className() {
		return classNames({
			'panel-container': true,
			'create-new-layer-panel-container': true,
			'visible': this.props.display
		});
	}

	handleCreate() {
		this.props.createAdasLayer(this.state.title);
		this.initState();
	}

	closeWindow() {
		this.initState();
		this.props.setActiveMenu('');
	}

	onInputTextChange = (event) => {
		this.setState({ title: event.target.value });
	};

	render() {

		return (
			<div className={this.className}>
					<div className='create-new-layer-header'>
						<div className='title-box'>
							<div className='title'> Create New Layer </div>
						</div>
						<div className='x-icon-box' onClick={() => this.closeWindow()}>
							<img className='x-icon' src={ xIcon }/>
						</div>
					</div>

					<div className='create-new-layer-container'>
						<div className='create-new-layer-input'>
							<span className='p-float-label'>
								<InputText id='float-input' type='text'
										   value={ this.state.title }
										   onChange={(e) => this.onInputTextChange(e)}/>
								<label htmlFor='float-input'>enter layer name</label>
                        	</span>
							<div className='title-line'/>
						</div>


						<div className='create-new-layer-footer'>
							<div className='create-new-layer-footer-row'>
								<Button className={ !this.state.title ? 'create-new-layer-button disabled' : 'create-new-layer-button' }
										label='+ Create'
										disabled={ !this.state.title }
										onClick={() => this.handleCreate()}/>
							</div>
						</div>

					</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	activeMenu: state.core.activeMenu
});

const mapDispatchToProps = {
	setActiveMenu,
	createAdasLayer
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateNewLayerPanel);
