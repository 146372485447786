import React, { Component } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import './StatusItem.scss';

class StatusItem extends Component<any, void> {

	constructor(props) {
		super(props);
	}

	getTextStyle(status): any {
		let sensorTextStyle = {} ;
		if (!status || status === 'off-line') {
			sensorTextStyle = { fontWeight: 'lighter' };
		}
		return sensorTextStyle;
	}

	getCircleStyle(status): any {
		const sensorCircleStyle = {
            background: this.props.sensor.color
        } as  {background: string, opacity?: number};

		if (status === 'off-line') {
			sensorCircleStyle.opacity = 0.2
        }
        
		return sensorCircleStyle;
	}

	render() {
		const status = get(this.props,'sensor.status');

		return (
			<div className="status-item" style={ this.getTextStyle(status) }>
				<span className="oval" style={ this.getCircleStyle(status) }/>
				<span> {this.props.sensor.name} </span>
			</div>
		);
	}
}

export default connect()(StatusItem);
