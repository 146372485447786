import classNames from 'classnames';
import React, { Component } from 'react';
import enhanceWithClickOutside from 'react-click-outside';
import { connect } from 'react-redux';
import { Button } from '@material-ui/core';
import { Checkbox } from 'primereact/checkbox';
import { setActiveMenu } from '../../../store/actions/core.actions';
import { addAdasLayers, editAdasLayer, removeAdasLayer, toggleAdasLayerDisplay } from '../actions/adas-layers.actions';
import './AdasLayersProviderPanel.scss';
import { IAdasLayer } from '../reducers/adas-layers.reducer';
import editIcon from '../../../assets/images/edit-pencil.svg';
import { AdasLayerStylesEnum } from '../../../definition/adas-layer-styles-enum';
import { DrawModes } from '../../../definition/draw-modes';
import { layerTitles } from '../../../core/services/layers/mineceptVectorLayerProvider';
import { Dictionary } from '../../../interfaces/Dictionary';
import { toggleLayerDisplay } from '../../../store/actions/mining.actions';
import profileConfig from '../../../core/profiles/profileConfig';

interface IProps {
	display: boolean;
	parentClass: string;
	menuClass: string;
	adasLayers: IAdasLayer[];
	activeMenu: string;
	mineceptLayers: Dictionary<boolean>
	setActiveMenu: (string) => void;
	addAdasLayers: (string) => void;
	removeAdasLayer: (string) => void;
	toggleAdasLayerDisplay: (string) => void;
	toggleMineceptLayer: (string) => void;
	editAdasLayer: (IEditAdasLayer) => void;
}

class AdasLayersProviderPanel extends Component<IProps, void> {
	handleClickOutside(event) {

		if (event.target.closest(`.${this.props.parentClass}`) || event.target.classList.contains(this.props.parentClass)) {
			return;
		}

		if (event.target.closest(`.${this.props.menuClass}`) || event.target.classList.contains(this.props.menuClass)) {
			return;
		}

		if(!this.props.display){
			return;
		}
		this.props.setActiveMenu('');
	}

	get className() {
		return classNames({
			'panel-container': true,
			'adas-layers-panel-container': true,
			'visible': this.props.display,

		});
	}

	toggleAdasLayer = (adasLayerId: string) => () => {
		this.props.toggleAdasLayerDisplay(adasLayerId);
	};

	toggleMineceptLayer = (layerId:string) => () => {
		this.props.toggleMineceptLayer(layerId);
	}

	editAdasLayer = (adasLayer: IAdasLayer) => () => {
		this.props.setActiveMenu('edit-layer-mode');
		this.props.editAdasLayer({
			id: adasLayer.id,
			title: adasLayer.title,
			style: AdasLayerStylesEnum.HIGHLIGHT,
			drawMode: DrawModes.NONE });
	};

	createNewLayer = () => () => {
		this.props.setActiveMenu('create-new-layer');
	};
	isCreateNewLayerAllowed =() => {
		const isAllowCreateNewLayer =process.env.REACT_APP_IS_ALLOW_CREATE_NEW_LAYER;
			return isAllowCreateNewLayer && isAllowCreateNewLayer.trim().toLowerCase() === 'true';
	}
	render() {
		return (
			<div className={this.className}>

				<div className='adas-layers-table'>

					{ this.isCreateNewLayerAllowed() && <div className='create-new-layer-box' onClick={this.createNewLayer()}>
						<div className='create-new-layer'>
							+ Create new Layer
						</div>
					</div>}

					<ul className='adas-layers-list'>
						{/* Shared Layers */}

						{
							profileConfig().showMineceptLayerFilters &&
							layerTitles &&
							layerTitles.map((mineceptLayer) =>(
								<li className='adas-layers-row' key={ mineceptLayer.id }>
									<div className='full-width' >
										<Checkbox
												inputId={ mineceptLayer.id }
												 className='checkbox-box'
												 checked={ this.props.mineceptLayers[mineceptLayer.id]}
												 value={ mineceptLayer.id }
												 onChange={ this.toggleMineceptLayer(mineceptLayer.id) }
										/>
										<label htmlFor={ mineceptLayer.id }
											   className='adas-layers-description'>
											{ mineceptLayer.title }
										</label>
									</div>
								</li>
							))
						}



						{/* ADAS Layers */}
						{
							this.props.adasLayers &&
							this.props.adasLayers.map((el) =>
								(
										<li className='adas-layers-row' key={ el.id }>

											<div className='full-width' >
												<Checkbox	inputId={ el.id }
															className='checkbox-box'
															checked={ el.isDisplay }
															value={ el.id }
															onChange={ this.toggleAdasLayer(el.id) }/>
												<label htmlFor={ el.id }
													   className='adas-layers-description'>
													{ el.title }
												</label>
											</div>
											{	el.title!== 'obstacles' &&
												<Button className={ el.isDisplay ? 'adas-layers-icon-box' : 'adas-layers-icon-disabled' }
														disabled={ !el.isDisplay }
														onClick={ this.editAdasLayer(el) }>
													<img className='adas-layers-icon' src={ editIcon }/>
												</Button>
											}
										</li>
								)
							)
						}

					</ul>
				</div>

			</div>
		);
	}
}

function flatAdasLayersMap(map): IAdasLayer[] | null {
	if (!map) {
		return null;
	}
	const list: IAdasLayer[] = [];
	for (const [key, value] of map.entries()) {
		list.push({ ...value, id: key });
	}
	return list;
}

const mapStateToProps = (state) => ({
	adasLayers: flatAdasLayersMap(state.adasLayers.adasLayersMap) || [],
	mineceptLayers: state.mining.mineceptLayers,
	activeMenu: state.core.activeMenu
});

const mapDispatchToProps = {
	setActiveMenu,
	removeAdasLayer,
	addAdasLayers,
	toggleAdasLayerDisplay,
	editAdasLayer,
	toggleMineceptLayer: toggleLayerDisplay
};

export default connect(mapStateToProps, mapDispatchToProps)(enhanceWithClickOutside(AdasLayersProviderPanel));
