export const mineceptStatusLabels = [
	'Connected',
	'Connection Warning',
	'Connection Error',
	'No Connection',
];

export const mineceptStatusColors = [
	'rgba(37, 51, 75, 0.8)',
	'#fc9613',
	'#d5001a',
	'#676767'
];
