import React from 'react';
import useStyles from './truck.css';
import truck from '../../../../assets/images/truckWithWheels.png';

interface Interface {
	truckWidth: number
}

const imgRatio = parseFloat(process.env.REACT_APP_TRUCK_IMAGE_RATIO as string);

function Truck({ truckWidth }: Interface) {
	const classes = useStyles({ truckWidth ,imgRatio});
	return (
		<div className={classes.root}>
			<div className={classes.truck}><img src={truck} width={truckWidth}/></div>
		</div>
	);
}

export default Truck;
