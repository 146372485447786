import length from '@turf/length';
import { coordAll } from '@turf/meta';
import { Feature, LineString, Point } from 'geojson';
import { isEmpty } from 'lodash';
import { getSensor } from '../../features/sensors/selectors/sensor.selectors';
import { getSensorLineString } from '../../store/selectors/route.selectors';
import { GeoJsonActionService } from '../services/geo-json-actions.service';
import vectorSourceProvider from '../services/vector-source.provider';
import { ISensorMetadataState } from '../../features/sensors/reducers/sensors.reducer';
import { IupdateRoutePayload, updateRoute } from '../actions/route.actions';
import AdasAlertsService from '../services/adas-alert.service';

export type IFeaturePoint = Feature<Point, { sensorType: string, id: string, rotation: number }>
export interface ISensorData {
	point: IFeaturePoint
}

function buildSensorLineString(point: IFeaturePoint,
	previousPoint: Feature<Point, any>,
	sensorLineString: Feature<LineString, any>,
	sensorType: string,
	isFollowRoute: boolean): Feature<LineString, any> {
	let newLineString;
	if (!isEmpty(sensorLineString)) {
		newLineString = GeoJsonActionService.concatLineString(sensorLineString, point);
	}
	else if (previousPoint) {
		newLineString = GeoJsonActionService.newLineString(previousPoint, point);
	} else {
		newLineString = GeoJsonActionService.newLineString(point, point);
	}

	let count = 0;
	const limit = +(`${process.env.REACT_APP_ROUTE_LENGTH_KM}`);
	while (coordAll(newLineString).length > 2 && (length(newLineString) > limit)) {
		newLineString = GeoJsonActionService.removeLineStringFirstItem(newLineString);
		count++;
	}

	if (count > 0) {
		vectorSourceProvider.removeFeaturesFromHead(sensorType, count);
	}

	vectorSourceProvider.addNewFeature(sensorType, point, previousPoint, isFollowRoute);
	return newLineString;
}

export const addNewFeature = (payload: ISensorData) => (dispatch, getState) => {
	const point: IFeaturePoint = payload.point;
	const sensorType = point.properties.sensorType;
	const sensor: ISensorMetadataState = getSensor(sensorType);
	const sensorLineString: Feature<LineString, any> = getSensorLineString(sensorType) as Feature<LineString, any>;
	const previousPoint: Feature<Point, any> = GeoJsonActionService.getLastLineStringPoint(sensorLineString) as Feature<Point, any>;
	const isFollowRoute: boolean = sensor && sensor.followRoute;

	const newLineString: Feature<LineString, any> = buildSensorLineString(point, previousPoint, sensorLineString, sensorType, isFollowRoute);

	const updateRoutePayload: IupdateRoutePayload = {
		line: newLineString,
		sensorType
	};

	dispatch(updateRoute(updateRoutePayload));

	if (sensorType.toLowerCase() === 'sl') {
		AdasAlertsService.addLatestSensorPosition(point);
	}
};
