import { applyMiddleware, combineReducers, createStore } from 'redux';
import thunk from 'redux-thunk';
import { get as _get } from 'lodash';

import rootReducer from './reducers/root.reducer';
import initSubscriber from 'redux-subscriber';
import { BehaviorSubject } from 'rxjs';
import { composeWithDevTools } from 'redux-devtools-extension';

const subjects = {};

export const createSelector = store => path => {
	const state = store.getState();
	return _get(state, path);
};

/**
 *
 * @param path
 * @description
 *      selector$('core.activeMenu').subscribe( x => {
            console.log('x',x);
            console.log('v',selector$('core.activeMenu').value);
        });
 */
export const selector$ = path => {
	if (!subjects[path]) {
		subjects[path] = new BehaviorSubject(undefined);
	}
	return subjects[path];
};

export default function configureStore(initialState = {}) {
	const composeEnhancers = composeWithDevTools({});
	// const store = createStore(combineReducers(rootReducer), initialState, composeEnhancers(applyMiddleware(thunk, logger)));
	// @ts-ignore
	const store = createStore(combineReducers(rootReducer), initialState, composeEnhancers(applyMiddleware(thunk)));
	initSubscriber(store);
	store.subscribe(() => {
		const state = store.getState();
		Object.keys(subjects).forEach(key => {
			const value = _get(state, key);
			if (subjects[key].value !== value) {
				subjects[key].next(value);
			}
		});
	});
	return store;
}



// log middleware

const logger = ({dispatch, getState}) => next => action => {
	console.log(action);
	(window as any).state = getState();
	return next(action)
};
