import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
	root: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-around',
		zIndex: 1,
		position: 'absolute',
		right: 25,
		height: 210
	},
});
