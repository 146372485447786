import { makeStyles } from '@material-ui/core/styles';
import { getSeverityColors } from '../../definition/severity';

interface Props{
	severity: number
	small: boolean
}

export default makeStyles({
	root: (props)=>({
		width: props.small? 230 :300,
		height: props.small? 60 :80,
		position: 'relative',
		textAlign: 'center',
		margin: '10px 0'
	}),
	label:(props:Props) => ({
		color: getSeverityColors(props.severity).icon,
		width: props.small? 190 : 229,
		height: props.small? 40 : 48,
		borderRadius: 8,
		fontFamily: 'Mukta Mahee',
		fontSize: props.small ? 18 : 22,
		backgroundColor: '#27354D',
		border: `${props.small ?'1px': '0px'} solid #979797`,
		padding: 13,
		marginTop: props.small? 8 : 13,
		position: 'absolute',
		left: props.small? 30 : 60,
		lineHeight: 1,
		boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.5)',
	}),
	symbol:(props)=>({
		width: props.small? 60 :80,
		height: props.small? 60 :80,
		objectFit: 'contain',
		position: 'absolute',
		top: 0,
		left: 0,
	})
});
