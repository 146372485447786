export interface IAdasFeatureAlert {
    id: string
}

export enum adasAlertsActionTypes {
    HIDE_LANE_ALERT = "[AdasAlert] hide lane alert",
    ADD_LANE_ALERT = '[AdasAlert] add lane alert',
    REMOVE_LANE_ALERT = '[AdasAlert] remove lane alert',
    ADD_ZONE_ALERT = '[AdasAlert] add zone alert',
    REMOVE_ZONE_ALERT = '[AdasAlert] remove zone alert',
    CLEAR_ALERTS = '[AdasAlert] clear all alerts'
}

export const hideLaneAlert = () => (dispatch) => {
    dispatch({
        type: adasAlertsActionTypes.HIDE_LANE_ALERT
    })
};

export const addLaneAlert = (featureAlert: IAdasFeatureAlert) => (dispatch) => {
    dispatch({
        type: adasAlertsActionTypes.ADD_LANE_ALERT,
        payload: featureAlert
    });
};

export const removeLaneAlert = (featureAlert: IAdasFeatureAlert) => (dispatch) => {
    dispatch({
        type: adasAlertsActionTypes.REMOVE_LANE_ALERT,
        payload: featureAlert
    });
};

export const addZoneAlert = (featureAlert: IAdasFeatureAlert) => (dispatch) => {
    dispatch({
        type: adasAlertsActionTypes.ADD_ZONE_ALERT,
        payload: featureAlert
    });
};

export const removeZoneAlert = (featureAlert: IAdasFeatureAlert) => (dispatch) => {
    dispatch({
        type: adasAlertsActionTypes.REMOVE_ZONE_ALERT,
        payload: featureAlert
    });
};

export const clearAlerts = () => (dispatch) => {
    dispatch({
        type: adasAlertsActionTypes.CLEAR_ALERTS
    })
}