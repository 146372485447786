import 'ol/ol.css';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import './EditLayerModePanel.scss';
import { setActiveMenu } from '../../../store/actions/core.actions';
import { IAdasLayer, IAdasLayersMap, IEditAdasLayer } from '../reducers/adas-layers.reducer';
import { editAdasLayer } from '../actions/adas-layers.actions';
import { changeAdasLayerTitle, deleteAdasLayer } from '../effects/adas-layers.effects';
import xIcon from '../../../assets/images/x-icon.svg';
import editIcon from '../../../assets/images/edit-pencil.svg';
import deleteIcon from '../../../assets/images/garbish.svg';
import laneIcon from '../../../assets/images/road-lane.svg';
import restrictedZoneIcon from '../../../assets/images/warning-triangle.svg';
import { DrawModes } from '../../../definition/draw-modes';
import { AdasLayerStylesEnum } from '../../../definition/adas-layer-styles-enum';
import Dialog from '@material-ui/core/Dialog/Dialog';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import Button from '@material-ui/core/Button/Button';
import { InputText } from 'primereact/inputtext';

interface IProps {
	display: boolean;
	adasLayerMap: Map<string, IAdasLayersMap>;
	adasLayer: IEditAdasLayer,
	selectedLayer: IAdasLayer
	activeMenu: string;
	setActiveMenu: (string) => void;
	editAdasLayer: (IEditAdasLayer) => void;
	changeAdasLayerTitle: (string, any) => void;
	deleteAdasLayer: (string) => void;
}

export interface ITitleState {
	isTitleDisabled: boolean;
	title: string,
	openDialog: boolean;
}

class EditLayerModePanel extends Component<IProps, ITitleState> {

	state: ITitleState = {
		title: this.props.selectedLayer ? this.props.selectedLayer.title : '',
		isTitleDisabled: true,
		openDialog: false
	};

	constructor(props) {
		super(props);
	}

	get className() {
		return classNames({
			'panel-container': true,
			'edit-layer-mode-panel-container': true,
			'visible': this.props.display,

		});
	}

	onDrawModeChange(drawMode: DrawModes) {
		const newAdasLayer = {...this.props.adasLayer, drawMode};
		this.props.editAdasLayer(newAdasLayer);
	}

	onEdit() {
		this.setState({ isTitleDisabled: false });
		setTimeout(() => {
			const element = document.getElementById('float-input');
			if (element) {
				element.focus();
			}
		}, 50);
	}

	onTitleChange(event: any) {
		this.setState( { title: event.target.value });
	}

	onTitleEditKeyPress(event: any) {
		if (event.key === "Enter") {
			this.setState( { isTitleDisabled: true });
			event.preventDefault();
			this.props.changeAdasLayerTitle(this.props.adasLayer.id, event.target.value);
		}
	}

	onTitleEditBlur(event: any) {
		this.setState( { isTitleDisabled: true });
		event.preventDefault();
		this.props.changeAdasLayerTitle(this.props.adasLayer.id, event.target.value);
	}

	onDeleteAdasLayer() {
		this.props.deleteAdasLayer(this.props.adasLayer.id);
		this.closeDialog();
		this.closeWindow();
	}

	closeWindow() {
		this.setState( { isTitleDisabled: true });
		this.props.editAdasLayer({ ...this.props.adasLayer, style: AdasLayerStylesEnum.DEFAULT, drawMode: DrawModes.NONE });
		this.props.editAdasLayer({ id: '', style: AdasLayerStylesEnum.DEFAULT, drawMode: DrawModes.NONE });
		this.props.setActiveMenu('');
	}

	openDialog() {
		this.setState({ openDialog: true });
	};

	closeDialog() {
		this.setState({ openDialog: false });
	};

	render() {

		return (
			<div className={this.className}>

				<div className='edit-mode-header'>
					<div className='title-box'>
						<div className='title'> Edit mode </div>
					</div>
					<div className='x-icon-box' onClick={() => this.closeWindow()}>
						<img className='x-icon' src={ xIcon }/>
					</div>
				</div>

				<div className='edit-layer-mode-container'>
					<div className='edit-mode-title'>
						{
							this.props.selectedLayer &&
							<div className='edit-title-box'>
								<div className={ !this.state.isTitleDisabled ? '' : 'edit-title-box disabled'}>
									<span className='p-float-label'>
										<InputText id='float-input' type='text'
												   value={ this.state.title ?  this.state.title : this.props.selectedLayer.title }
												   disabled={ this.state.isTitleDisabled }
												   onInput={(e) => this.onTitleChange(e)}
												   onKeyPress={(e) => this.onTitleEditKeyPress(e)}
												   onBlur={(e) => this.onTitleEditBlur(e)} />
                        			</span>
								</div>

								<div className='edit-title-icon-box' onClick={() => this.onEdit()}>
									<img className={ this.state.isTitleDisabled ? 'edit-title-icon' : 'edit-title-icon disabled'}
										 src={ editIcon }/>
								</div>
							</div>
						}
						<div className='edit-title-line'/>
					</div>

					<ul className='draw-mode-table'>
						<li className={ this.props.adasLayer.drawMode === DrawModes.LANE ? 'draw-mode-row selected' : 'draw-mode-row' }
							onClick={() => this.onDrawModeChange(DrawModes.LANE)} >
							<div className='draw-mode-icon-box'>
								<img className='draw-mode-icon' src={ laneIcon } />
							</div>
							<div className='draw-mode-title-box'>
								<div className='draw-mode-title'>
									Create a lane
								</div>
							</div>
						</li>
						<li className={ this.props.adasLayer.drawMode === DrawModes.RESTRICTED_ZONE ? 'draw-mode-row selected' : 'draw-mode-row' }
							onClick={() => this.onDrawModeChange(DrawModes.RESTRICTED_ZONE)}>
							<div className='draw-mode-icon-box'>
								<img className='draw-mode-icon' src={ restrictedZoneIcon } />
							</div>
							<div className='draw-mode-title-box'>
								<div className='draw-mode-description'>
									Mark a restricted zone
								</div>
							</div>
						</li>
					</ul>

					<div className='edit-mode-footer'>
						<div className='edit-mode-footer-row' onClick={() => this.openDialog()}>
							<div className='delete-icon-box'>
								<img className='delete-icon' src={ deleteIcon } />
							</div>
							<div className='delete-title-box'>
								<div className='delete-title'> Delete layer </div>
							</div>
						</div>
					</div>

					<div>
						<Dialog
							open={ this.state.openDialog }
							onClose={ this.closeDialog }
							aria-labelledby="alert-dialog-title"
							aria-describedby="alert-dialog-description">
							<DialogContent>
								<DialogContentText id="alert-dialog-description">
									Are you sure you want to delete this layer?
								</DialogContentText>
							</DialogContent>
							<DialogActions>
								<Button onClick={() => this.closeDialog() } color="primary">
									Cancel
								</Button>
								<Button onClick={() => this.onDeleteAdasLayer()} color="primary" autoFocus={true}>
									Delete
								</Button>
							</DialogActions>
						</Dialog>
					</div>


				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	adasLayerMap: state.adasLayers.adasLayersMap,
	adasLayer: state.adasLayers.adasEditLayer,
	selectedLayer: state.adasLayers.adasLayersMap.get(state.adasLayers.adasEditLayer.id),
	activeMenu: state.core.activeMenu
});

const mapDispatchToProps = {
	setActiveMenu,
	editAdasLayer,
	changeAdasLayerTitle,
	deleteAdasLayer
};

export default connect(mapStateToProps, mapDispatchToProps)(EditLayerModePanel);
