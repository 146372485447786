import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
	root:{
		zIndex:1,
		backgroundColor: '#3a475d',
		borderRadius: 6,
		position: 'absolute',
		left:0,
		top:40,
		width: 160,
	},
	itemRow:{
		display: 'flex',
		margin: 5,
		color: 'white',
		fontFamily: 'Mukta Mahee',
		fontSize: 16,
	},
	icon:{

	},
	text:{
		margin: '5px 5px 5px 10px',
	}
});
