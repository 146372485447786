import React from 'react';
import useStyles from './mineceptStatusBar.css';
import { useSelector } from 'react-redux';
import {getMineceptStatus } from '../../../store/selectors/mining/mining.selectors';
import { mineceptStatusLabels } from '../../../definition/mineceptStatus';
import greenDot from '../../../assets/images/ConnectedGreenDot.svg';

function MineceptStatusBar( ) {
	const status = Math.min(useSelector(getMineceptStatus).status,3);
	const classes = useStyles({status});

	return (
		<span className={classes.root}>
			{status === 0 && <img src={greenDot} className={classes.dot}/>}
			<span className={classes.label}>{mineceptStatusLabels[status]}</span>
		</span>
	);
}

export default MineceptStatusBar;
