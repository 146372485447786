import { createSelector } from 'reselect';
import profileConfig from '../../core/profiles/profileConfig';
import { IUserInfo } from '../reducers/core.reducer';
// import { getSelectedFeatureId } from './mining.selectors';
export const getActiveMenu = (state) => state.core.activeMenu;
export const isFullLengthMenuOpen = (state) => (state.core.activeMenu === 'safety-event-log' ||state.core.activeMenu === 'hazard-log');

//these should be imported but breaks TS.
const getSelectedFeatureId = (state) => state.mining.selected;
const getIsGoingReverse = (state) => state.mining.isGoingReverse;
const getPage = (state) => state.core.page;

export const getBlockedSize = createSelector([getActiveMenu, getSelectedFeatureId],(activeMenu, selectedFeatureId)=> {
	let size = 0;
	if(!!selectedFeatureId) {
		size += 290;
	}
	if(activeMenu === 'safety-event-log' || activeMenu === 'hazard-log') return size + 290;
	if(activeMenu === '') return size;
	return size + 420;
});

export const getVolume = (state) => state.core.volume;
export const getCurrentPage = createSelector(
[getIsGoingReverse, getPage],
(isGoingReverse, currentPage) => {
	if (currentPage) return currentPage;
	if(isGoingReverse) return profileConfig().reverseLayout;
	return profileConfig().forwardLayout;
});
export const getUserInfo = (state): IUserInfo => state.core.userInfo;
