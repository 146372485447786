import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ArchiveIcon from '@material-ui/icons/Archive';
import HistoryIcon from '@material-ui/icons/History';
import useStyles from './moreBar.css';
import PhotoSizeSelectSmallIcon from '@material-ui/icons/PhotoSizeSelectSmall';
import olMapService from '../../../../core/services/ol-map.service';
import { multiSelectInBbox } from '../../../../store/actions/mining.actions';
import Emitter from '../../../../emitter';
import { getIsMultiSelected } from '../../../../store/selectors/mining/mining.selectors';

interface Iprops{
	selectors:{
		multiSelected: any
		controls: any
	},
	actions:{
		archiveMulti: any
		restoreMulti: any
	}
}

function MoreBar({selectors, actions}:Iprops) {
	const dispatch = useDispatch();
	const classes = useStyles();
	const isMultiSelected = useSelector(getIsMultiSelected);
	const multiSelected = useSelector(selectors.multiSelected);
	const controls = useSelector(selectors.controls);

	const startMultiSelect = () => {
		Emitter.emit('closeSubMenus');
		olMapService.getBoxFromMap((bbox)=>{
			dispatch(multiSelectInBbox(bbox));
		});
	}

	const onArchive = () => {
		Emitter.emit('closeSubMenus');
		dispatch(actions.archiveMulti(multiSelected));
	};
	const onRestore = () => {
		Emitter.emit('closeSubMenus');
		dispatch(actions.restoreMulti(multiSelected));
	};

	const showArchive = isMultiSelected;
	const showRestore = isMultiSelected && controls.archive;
	return (
		<div className={classes.root}>
			<div onClick={startMultiSelect} className={classes.itemRow}>
				<PhotoSizeSelectSmallIcon className={classes.icon}/>
				<div className={classes.text}> Select on Map </div>
			</div>
			{
				showArchive &&
				<div onClick={onArchive} className={classes.itemRow}>
					<ArchiveIcon className={classes.icon}/>
					<div className={classes.text}> Archive </div>
				</div>
			}
			{
				showRestore &&
				<div onClick={onRestore} className={classes.itemRow}>
					<HistoryIcon className={classes.icon}/>
					<div className={classes.text}> Restore </div>
				</div>
			}

		</div>
	);
}

export default MoreBar;
