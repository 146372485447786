import AdasLayersProvider from '../../../core/services/layers/adas-layers.provider';
import {
	addAdasLayers,
	changeLayerTitle,
	editAdasLayer,
	removeAdasLayer
} from '../actions/adas-layers.actions';
import { AdasLayerStylesEnum } from '../../../definition/adas-layer-styles-enum';
import { setActiveMenu } from '../../../store/actions/core.actions';
import { DrawModes } from '../../../definition/draw-modes';
import { sendErrorMessage } from '../../error-messages/actions/errorMessages.actions';
import { IErrorSeverity } from '../../../definition/error-enum';

export const getAdasLayers = () => async (dispatch) => {
	const adasLayers = await AdasLayersProvider.provideAdasLayers();
	dispatch(addAdasLayers(adasLayers));
};

export const createAdasLayer = (title: string) => async (dispatch) => {
	const id = await AdasLayersProvider.createNewAdasLayer(title);
	let errorMessage = {
		severity: IErrorSeverity.WARN,
		summary: 'Create new layer failed',
		detail: 'Please try again',
	};
	if (id) {
		const adasLayers = [{ id, title, isDisplay: true }];
		const newLayer = {
			id,
			title,
			style: AdasLayerStylesEnum.HIGHLIGHT,
			drawMode: DrawModes.NONE
		};

		dispatch(addAdasLayers(adasLayers));
		dispatch(editAdasLayer(newLayer));
		dispatch(setActiveMenu('edit-layer-mode'));

		errorMessage = {
			severity: IErrorSeverity.SUCCESS,
			summary: 'Create new layer succedded',
			detail: '',
		};

	} else {
		dispatch(setActiveMenu(''));
	}
	dispatch(sendErrorMessage(errorMessage));
};

export const changeAdasLayerTitle = (layerId: string, title: string) => async (dispatch) => {
	const titleObject = await AdasLayersProvider.changeAdasLayerTitle(layerId, title);

	let errorMessage = {
		severity: IErrorSeverity.WARN,
		summary: 'Save failed',
		detail: 'Please repeat the action',
	};

	if (titleObject) {
		dispatch(changeLayerTitle(layerId, title));
		errorMessage = {
			severity: IErrorSeverity.SUCCESS,
			summary: 'Save succeeded',
			detail: '',
		};
	}

	dispatch(sendErrorMessage(errorMessage));
};

export const deleteAdasLayer = (layerId: string) => async (dispatch) => {
	const result = await AdasLayersProvider.deleteAdasLayer(layerId);
	console.log('deleteAdasLayer result: ', result);

	let errorMessage = {
		severity: IErrorSeverity.WARN,
		summary: 'Delete layer failed',
		detail: 'Please try again',
	};

	if (result) {
		dispatch(removeAdasLayer(layerId));
		errorMessage = {
			severity: IErrorSeverity.SUCCESS,
			summary: 'Delete layer succeeded',
			detail: '',
		};
	}

	dispatch(sendErrorMessage(errorMessage));
};

export const deleteAdasFeature = (layerId: string, featureId: string) => async (dispatch) => {
	const result = await AdasLayersProvider.deleteAdasFeature(layerId, featureId);

	let errorMessage = {
		severity: IErrorSeverity.WARN,
		summary: 'Delete feature failed',
		detail: 'Please try again',
	};

	if (result) {
		errorMessage = {
			severity: IErrorSeverity.SUCCESS,
			summary: 'Delete feature succeeded',
			detail: '',
		};
	}

	dispatch(sendErrorMessage(errorMessage));
};

export const editFeatureStyle = (layerId: string, featureId: string, style: AdasLayerStylesEnum) => async (dispatch) => {
	await AdasLayersProvider.editFeatureStyle(layerId, featureId, style);
};

