import olMapService from '../../../core/services/ol-map.service';
import { getSensor } from '../selectors/sensor.selectors';
import vectorSourceProvider from '../../../core/services/vector-source.provider';
import SensorDataProvider from '../../../core/services/sensor-data.provider';
import profileConfig from '../../../core/profiles/profileConfig';

export enum SensorsActionTypes {
	TOGGLE_DOTS_VIEW = '[Sensors] Toggle dots view',
	TOGGLE_STRAIGHT_VIEW = '[Sensors] Toggle straight line view',
	TOGGLE_GRADIENT_LINE_VIEW = '[Sensors] Toggle gradient line view',
	TOGGLE_HEAD_HEAT_MAP_VIEW = '[Sensors] Toggle head heat map view',
	CHANGE_SENSOR_STATUS = '[Sensors] Change sensor status',
	REGISTER_SENSOR = '[Sensors] Register Sensor',
	TOGGLE_SENSOR_ROUTE_VISIBILITY = '[Sensors] toggle sensor route visibility'
}

export interface IToggleViewState {
	sensorType: string;
}

export interface IchangeSensorStatus {
	sensorType: string;
	status: string;
}

export interface IRegisterSensorState {
	sensorType: string;
	color: string;
	name: string;
	status?: string;
	visible: string;
	followRoute: boolean;
}

export const toggleSensorRouteVisibility = (payload: IToggleViewState) => dispatch => {
	const sensor = getSensor(payload.sensorType);
	olMapService.toggleLayerVisibility(payload.sensorType, !sensor.displayFlags.visible);
	dispatch({
		type: SensorsActionTypes.TOGGLE_SENSOR_ROUTE_VISIBILITY,
		payload
	});
};

export const toggleDotsView = (payload: IToggleViewState) => (dispatch) => {
	const sensor = getSensor(payload.sensorType);
	vectorSourceProvider.setDotVisibility(sensor.sensorType, !sensor.displayFlags.dots);
	dispatch({
		type: SensorsActionTypes.TOGGLE_DOTS_VIEW,
		payload
	});
};

export const toggleStraightLineView = (payload: IToggleViewState) => (dispatch) => {
	const sensor = getSensor(payload.sensorType);
	vectorSourceProvider.setLineVisibility(sensor.sensorType, !sensor.displayFlags.straight);

	dispatch({
		type: SensorsActionTypes.TOGGLE_STRAIGHT_VIEW,
		payload
	});
};

export const toggleHeadHeatMapView = (payload: IToggleViewState) => ({
	type: SensorsActionTypes.TOGGLE_HEAD_HEAT_MAP_VIEW,
	payload
});

export const toggleGradientLineView = (payload: IToggleViewState) => ({
	type: SensorsActionTypes.TOGGLE_GRADIENT_LINE_VIEW,
	payload
});

export const registerSensor = (payload: IRegisterSensorState) => (dispatch) => {
	olMapService.generateSources([payload]);
	const defaultTraceModeEnv = process.env.REACT_APP_DEFAULT_TRACE_MODE;
	const defaultTraceMode = defaultTraceModeEnv ? defaultTraceModeEnv.trim().toLowerCase() : 'none';
	switch(defaultTraceMode)
	{
		case 'dots':
			vectorSourceProvider.setDotVisibility(payload.sensorType, true);
			break;

		case 'straight':
			vectorSourceProvider.setLineVisibility(payload.sensorType, true);
			break;
		case 'none':
			 return;
		default:
			console.warn(`invalid default trace mode: "${defaultTraceMode}"`);
			return;
	}

	dispatch({
		type: SensorsActionTypes.REGISTER_SENSOR,
		payload
	});
};

export const startSensorStatusListener = (changeSensorStatus) => {
	if(!profileConfig().listenToMineceptSensor) return;
	SensorDataProvider.changeStatusEvent(value => changeSensorStatus(value.sensorStatusObject));
};

export const changeSensorStatus = (payload: IchangeSensorStatus) => (dispatch) => {
	dispatch({
		type: SensorsActionTypes.CHANGE_SENSOR_STATUS,
		payload
	});
};
