import React from 'react';
import useStyles from './alertBox.css';
import soundOn from '../../../../assets/images/soundIcon.svg';
import soundOff from '../../../../assets/images/soundIconOff.svg';
import blinkingWarning from '../../../../assets/images/colorBlindWarning.svg';
import profileConfig from '../../../../core/profiles/profileConfig';

interface Interface{
	disabled:boolean
	isAlert:boolean
	symbol?: string
	label: string
	isSoundOn : boolean
	soundSwitch: ()=>void
	short?: boolean
}

function AlertBox({	disabled, isAlert, symbol, label, isSoundOn, soundSwitch, short= false}:Interface) {
	const classes = useStyles({isAlert, disabled, short});
	return (
		<div className={classes.root} onClick={()=>{soundSwitch()}}>
			{isAlert && !disabled ?
				<img  className={classes.blinkingWarning} src={blinkingWarning}/> : <>
				{!!symbol && <div className={classes.symbol}>{symbol}</div> }
				</>
			}
			<div className={classes.label}>{label}</div>
			{!profileConfig().disableReverseVolumeControl &&
				<div className={classes.speaker}>{
					isSoundOn?
						<img className={classes.speakerImage} src={soundOn}/> :
						<img className={classes.speakerImage} src={soundOff}/>
				}</div>
			}
		</div>
	);
}

export default AlertBox;
