import React, { useEffect, useState } from 'react';
import useStyles from './VolumeButton.css';
import { useDispatch, useSelector } from 'react-redux';
import volumeOnIcon from '../../assets/images/unmute.svg';
import volumeOffIcon from '../../assets/images/mute.svg';
import { getVolume } from '../../store/selectors/core.selector';
import { Collapse } from '@material-ui/core';
import Slider from '@material-ui/core/Slider';
import { setVolume } from '../../store/actions/core.actions';
import { config } from '../../services/config';

interface Interface {
}

let closeVolumeTimeout;

function VolumeButton({}: Interface) {
	const [isVolumeOpen, setIsVolumeOpen] = useState(false);
	const dispatch = useDispatch();
	const volume = useSelector(getVolume);
	const mute = volume === 0;
	const classes = useStyles({ isActive: !mute });

	const closeVolume = () => {
		setIsVolumeOpen(false);
	};

	const setVolumeToClose = () => {
		clearTimeout(closeVolumeTimeout);
		closeVolumeTimeout = setTimeout(closeVolume, 5000);
	};

	const toggleVolumeOpen = () => {
		if (!isVolumeOpen) {
			setVolumeToClose();
		}
		setIsVolumeOpen(!isVolumeOpen);
	};

	const handleVolumeChange = (event, value) => {
		setVolumeToClose();
		if (typeof value === 'number') {
			dispatch(setVolume(value));
			localStorage.setItem('volume', value.toString());
		}
	};

	const icon = mute ? volumeOffIcon : volumeOnIcon;

	useEffect(() => {
		const storedVolume = localStorage.getItem('volume');
		if (storedVolume && !config.general.volume.disableVolumeControl) {
			dispatch(setVolume(parseFloat(storedVolume)));
		}
	}, []);


	return (
		<>
			{
				!config.general.volume.disableVolumeControl && <>
					<div className={classes.root} onClick={toggleVolumeOpen}>
						<img src={icon} className={classes.icon} />
					</div>
					<div className={classes.volumeContainer}>
						<Collapse in={isVolumeOpen}>
							<div className={classes.volumeCard}>

								<Slider
									orientation="vertical"
									value={volume}
									step={0.05}
									min={0}
									max={1}
									className={classes.slider}
									onChange={handleVolumeChange}
								/>
							</div>
						</Collapse>
					</div>
				</>}
		</>
	);
}

export default VolumeButton;
