import React from 'react';
import useStyles from './discAlert.css';

interface Interface{
	isAlert:boolean
	symbol: string
	position: number
	label1?: string
	label2?: string
}

function DiscAlert({isAlert, symbol, position, label1='', label2=''}:Interface) {
	const classes = useStyles({isAlert, position});
	return (
		<div className={classes.root}>
			<div className={classes.symbol}><div>{symbol}</div></div>
			<div className={classes.label}>{label1}{label2!=''? ' | ' : ''}{label2}</div>
		</div>
	);
}

export default DiscAlert;
