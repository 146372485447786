import React from 'react';
import useStyles from './miniAlert.css';
import { getHazardLabel } from '../../core/services/minecept/auxilary';
import { limitStringLength } from '../../core/auxilary/strings';
import {getHazardIcon} from "../../core/services/icons/obstacleIcons";

interface IProps{
	severity: number
	type: number
	description?: string
	small?:boolean
}

function MiniAlert({severity, type, description, small=false}: IProps) {
	const classes = useStyles({severity, small});
	const symbol = getHazardIcon(type, severity);
	const label = getHazardLabel(type, description);
	return (
		<div className={classes.root}>
			<span className={classes.label}>{limitStringLength(label, small? 17 : 20)}</span>
			<img className={classes.symbol} src={ symbol }/>
		</div>
	);
}

export default MiniAlert;
