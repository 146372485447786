import { makeStyles } from '@material-ui/core/styles';
import { config } from '../../../../services/config';

export default makeStyles({
	root: {
		color: 'black',
		position: 'absolute',
		bottom: 10,
		width: 'calc(var(--mainMenuWidth) - 24px)',
		'@media (max-height: 400px)': {
			position: 'fixed',
			bottom: 0,
			left: 10,
			width: 10,
			height: 45,
		},
	},
	ceptionLogo: {
		maxWidth: '100%',
		maxHeight:82,
		'@media (max-height: 400px)': {
			width: 20,
		},
	},
	clientLogo: {
		maxWidth: '100%',
		maxHeight:82,
		marginBottom: 15,
		'@media (max-height: 400px)': {
			width: 20,
		},
	},
	version: () => ({
		color: config.isError ? 'red' :'#99A4B9',
		fontSize: 12,
		textAlign: 'center',
		'@media (max-height: 400px)': {
			fontSize: 8,
		},
	}),
	logout: {
		color: '#99A4B9',
		fontSize: 12,
		textAlign: 'center',
		cursor: 'pointer',
		'@media (max-height: 400px)': {
			fontSize: 8,
		},
	},
});
