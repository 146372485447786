import { makeStyles } from '@material-ui/core/styles';

interface Props{
	disabled:boolean
	isAlert: boolean
	short?: boolean
}
export default makeStyles({
	root: (props:Props) => ({
		position: 'relative',
		justifySelf: 'center',
		alignSelf: 'center',
		width: props.short? 130 : 300,
		height: 55,
		borderRadius: 6,
		padding: 7,
		marginTop:5,
		display: 'grid',
		background: props.disabled ?
			'#abadae' :
			props.isAlert ? '#ff6665' : '#91fc9a',
		'@media (max-width: 1200px)': {
			width: props.short? 130 : 240,
		},
		'@media (min-width: 651px)': {
			gridTemplateAreas: `"a l s"`,
			gridTemplateColumns: '35px auto 35px',
		},
		'@media (max-width: 650px)': {
			gridTemplateAreas: `"a l"
                                "s l"`,
			gridTemplateRows: '1fr 1fr',
			height: 90,
			width: 160,
			padding: '0 7px',
			marginTop:15,
		},
	}),
	symbol:{
		gridArea: 'a',
		width: 32,
		height: 32,
		borderRadius: 16,
		background: '#28334a',
		color: 'white',
		fontSize: 17,
		textAlign: 'center',
		paddingTop: 3,
		alignSelf: 'center',
		fontFamily: 'Mukta Mahee',
	},
	blinkingWarning:{
		width: 32,
		height: 32,
		animation: 'blink 0.8s',
		alignSelf: 'center',
		animationIterationCount: 'infinite',
	},
	label: props => ({
		gridArea: 'l',
		color: props.isAlert && !props.disabled ? 'white' : '#0c1334',
		fontFamily: 'Mukta Mahee',
		fontSize: 22,
		// fontWeight: props.isAlert ? 500 : 'normal',
		fontWeight: 500,
		margin: 'auto',
		'@media (max-width: 1200px)': {
			fontSize: 18,
		},
		'@media (max-width: 650px)': {
			paddingLeft: 17,
		},
	}),
	speaker: {
		gridArea: 's',
		cursor: 'pointer',
		position: 'relative',
		marginTop: 6,
	},
	speakerImage: {
		position: 'absolute',
		left: -3,
		top: -5,
		width: 45,
		height: 45,
		'@media (max-width: 650px)': {
			left: -7,
		},
	},
});
