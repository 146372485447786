import { config } from '../services/config';
import { IMineceptVideoEvent, ITimeline, ITimelineObstacle } from '../store/reducers/mining/IMiningReducerState';

const correctColors = arr => {
	return arr.map(item => {
		if (item > 127) return 0;
		return Math.max(Math.min(item + 1, 4), 0);
	});
};

const isObstaclesEqual = (obstacle1: ITimelineObstacle, obstacle2: ITimelineObstacle) => 
	obstacle1.Type === obstacle2.Type && obstacle1.severity === obstacle2.severity;

const isSectorsEqual = (sectors1: number[], sectors2: number[]) =>
	sectors1.every((x, i) => x === sectors2[i]);

const getValue = (value, key?: string) => {
	if (!key) {
		return value;
	}
	return value[key];
}

export const lowestBinarySearch = (arr, l, r, val, compareKey?: string) => {
	if (r >= l) {
		let mid = l + Math.floor((r - l) / 2);
		const midValue = getValue(arr[mid], compareKey);
		if (
			(r === l && midValue <= val) ||
			midValue === val ||
			(midValue < val && getValue(arr[mid + 1], compareKey) > val)
		) {
			return arr[mid];
		}
		if (midValue > val) {
			if (r === l) {
				return undefined;
			}
			return lowestBinarySearch(arr, l, mid - 1, val, compareKey);
		}
		return lowestBinarySearch(arr, mid + 1, r, val, compareKey);
	}
	return undefined;
};

export const getSectorsByTime = (
	currentTime: number,
	sectors: ITimelinePlaybackSectors[]
) => {
	const sectorsData: ITimelinePlaybackSectors | undefined = lowestBinarySearch(
		sectors, 0, sectors.length - 1, currentTime, 'timestamp');
	if (!sectorsData) {
		return getSectors();
	}
	return sectorsData.value;
};

export const getObstacleByTime = (
	currentTime: number,
	obstacles: ITimelinePlaybackObstacle[],
) => {
	const obstacleData: ITimelinePlaybackObstacle | undefined = lowestBinarySearch(
		obstacles, 0, obstacles.length - 1, currentTime, 'timestamp');
	if (!obstacleData) {
		return undefined;
	}
	return obstacleData;
};

export interface ITimelinePlaybackObstacle {
	timestamp: number;
	value: ITimelineObstacle | undefined
}

export interface ITimelinePlaybackSectors { 
	timestamp: number;
	value: number[];
}

interface ITimelineAggeragtor {
	obstacles: Array<ITimelinePlaybackObstacle>;
	sectors: Array<ITimelinePlaybackSectors>;
	lastObstacleTime: number;
}

export const getSectors = (fill = 0) => {
	const sectorsAmount = config.general.sectors.sectorsAmount;
	return Array(sectorsAmount).fill(fill);
}

const isOnSectorsWithoutObstaclesRemoveObstacle = true;

export const createPlayableTimeline = (playedEvent: IMineceptVideoEvent) => {
	const clearTimeoutMS = config.general.obstacles.clearTimeoutMS;
	const timeline = playedEvent.timeline || {};
	const agg: ITimelineAggeragtor = {
		obstacles: [{ timestamp: -1, value: undefined }],
		sectors: [{ timestamp: -1, value: getSectors() }],
		lastObstacleTime: 0
	};
	const { obstacles, sectors } = Object.entries(timeline).reduce((agg, [key, data]) => {
		const timestamp = Number(key);
		if (data) {
			// Check for required reset from last obstacle
			if ((agg.obstacles[agg.obstacles.length - 1].value !== undefined) &&
				(timestamp - agg.lastObstacleTime > clearTimeoutMS)) {
				const resetTimestamp = agg.lastObstacleTime + clearTimeoutMS;
				agg.lastObstacleTime = resetTimestamp;
				agg.obstacles.push({ timestamp: resetTimestamp, value: undefined });
				agg.sectors.push({ timestamp: resetTimestamp, value: getSectors() });
			}
			const { obstacles, sectors }: ITimeline = data;
			if (obstacles) {
				agg.lastObstacleTime = timestamp;
				const maxObstacle = obstacles.reduce((prev, curr) => prev.severity >= curr.severity ? prev : curr);
				const lastObstacle = agg.obstacles.length === 0 ? undefined : agg.obstacles[agg.obstacles.length - 1].value;
				if (!lastObstacle || !isObstaclesEqual(maxObstacle, lastObstacle)) {
					agg.obstacles.push({ timestamp, value: maxObstacle });
				}
			}
			if (sectors) {
				const fixedSectors = correctColors(sectors);
				const lastSector = agg.sectors[agg.sectors.length - 1].value;
				if (!isSectorsEqual(fixedSectors, lastSector)) {
					agg.sectors.push({ timestamp, value: fixedSectors });
				}
				if (isOnSectorsWithoutObstaclesRemoveObstacle &&
					!obstacles && agg.obstacles[agg.obstacles.length - 1].value) {	
					agg.obstacles.push({ timestamp, value: undefined });
				}
			}
		}
		return agg;
	}, agg);
	return { sectors, obstacles };
};
