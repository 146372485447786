import vectorSourceProvider from '../services/vector-source.provider';
import { Feature, LineString } from 'geojson';

export enum RouteActionTypes {
	UPDATE_ROUTE = '[Route] update route',
	CLEAR_ROUTES = '[Route] clear route',
}

export const clearRoute = () => dispatch => {
	vectorSourceProvider.clearAll();

	dispatch({
		type: RouteActionTypes.CLEAR_ROUTES
	});
};

export interface IupdateRoutePayload {
	line: Feature<LineString, any>,
	sensorType: string
}

export const updateRoute = (payload:IupdateRoutePayload) => dispatch => {
	dispatch({
		type: RouteActionTypes.UPDATE_ROUTE,
		payload: {
			line: payload.line,
			sensorType: payload.sensorType
		}
	});
};

