import { makeStyles } from '@material-ui/core/styles';

interface Props {
	isActive: boolean
}

export default makeStyles({
	root: ({ isActive }: Props) => {
		const style = {
			zIndex: 2,
			width: 80,
			height: 80,
			borderRadius: '50%',
			cursor: 'pointer',
			display: 'grid',
			alignContent: 'center',
			justifyContent: 'center',
			background: '#27354D',
			boxShadow: '0 6px 6px 0 rgba(0, 0, 0, 0.5), 0 0 6px 0 rgba(0, 0, 0, 0.5)',
			// '&:focus, &:hover,&:active,&::before, &:active::before, &:focus::before, &.Mui-active, &.Mui-focusVisible': {
			// 	background: 'rgba(0,0,0,0)',
			// },
		};
		if (isActive) {
			style['background'] = '#00bbf7';
			style['border'] = '1px solid #25334b';
			style['boxShadow'] = 'inset 0 0 12px 2px #25334b';
		}
		return style;
	},
	icon: {
		height: 36,
	},
	volumeContainer: {
		position: 'absolute',
		left: 19,
		top: 90,
		boxShadow: '0 6px 6px 0 rgba(0, 0, 0, 0.5), 0 0 6px 0 rgba(0, 0, 0, 0.5)',
	},
	volumeCard: {
		height: 130,
		width: 40,
		paddingTop: 11,
		paddingBottom: 11,
		paddingLeft: 5,
		webkitBackdropFilter: 'blur(3px)',
		backdropFilter: 'blur(3px)',
		border: 'solid 1px #25334b',
		backgroundColor: 'rgba(37, 51, 75, 0.9)',
	},
	slider:{
		'& .MuiSlider-thumb': {
			backgroundImage: 'linear-gradient(to bottom, #88edff, #2a97ba)',
		},
		'& .MuiSlider-track': {
			backgroundImage: 'linear-gradient(to bottom, #88edff, #2a97ba)',
		},
		'& .MuiSlider-rail': {
			backgroundColor: '#868B96',
		},
	}
});
