import { getCoords } from '@turf/invariant';
import { Feature, LineString } from 'geojson';
import { Coords } from '../../definition/simple-types';
import { selector } from '../../index';

export const getSensorLineString = (sensorType: string): Feature<LineString, any> | undefined => {
	return selector('route')[sensorType];
};

export const getSensorLastPosition = (sensorType: string): Coords | undefined => {
	const lineString = getSensorLineString(sensorType);

	if (lineString) {
		const [coords] = getCoords(lineString) as Coords[];
		return coords;
	}

	return;
};
