import { adasAlertsActionTypes } from '../actions/adas-alerts.actions';

export interface IAdasAlertsState {
    lanes: Set<string>;
    zones: Set<string>;
    showLaneAlert: boolean
}

export const AdasAlertsInitialState = {
    lanes: new Set<string>(),
    zones: new Set<string>(),
    showLaneAlert: false
};

export default (state: IAdasAlertsState = AdasAlertsInitialState, action) => {
    switch (action.type) {
        case adasAlertsActionTypes.HIDE_LANE_ALERT: {
            const showLaneAlert = false;
            return { ...state, showLaneAlert };
        }

        case adasAlertsActionTypes.ADD_LANE_ALERT: {
            const lanes = new Set(state.lanes);
            lanes.add(action.payload.id);
            const showLaneAlert = true;
            return { ...state, lanes, showLaneAlert};
        }

        case adasAlertsActionTypes.REMOVE_LANE_ALERT: {
            const lanes = new Set(state.lanes);
            lanes.delete(action.payload.id);
            const showLaneAlert = lanes.size > 0;
            return { ...state, lanes, showLaneAlert};
        }

        case adasAlertsActionTypes.ADD_ZONE_ALERT: {
            const zones = new Set(state.zones);
            zones.add(action.payload.id);
            return { ...state, zones};
        }

        case adasAlertsActionTypes.REMOVE_ZONE_ALERT: {
            const zones = new Set(state.zones);
            zones.delete(action.payload.id);
            return { ...state, zones};
        }

        case adasAlertsActionTypes.CLEAR_ALERTS: {
            const lanes = new Set();
            const zones = new Set();
            const showLaneAlert = false;
            return { ...state, lanes, zones, showLaneAlert }
        }
    }
    return state;
}
