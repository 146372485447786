import { makeStyles } from '@material-ui/core/styles';
import { getSeverityColors } from '../../../definition/severity';

export default makeStyles({
	obstacleSafeColor: {
		backgroundColor: getSeverityColors(0).icon,
		color: getSeverityColors(0).text
	},
	obstacleLowColor: {
		backgroundColor: getSeverityColors(1).icon,
		color: getSeverityColors(1).text
	},

	obstacleMediumColor: {
		backgroundColor: getSeverityColors(2).icon,
		color: getSeverityColors(2).text
	},

	obstacleHighColor: {
		backgroundColor: getSeverityColors(2).icon,
		color: getSeverityColors(3).text
	},
});
