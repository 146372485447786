import classes from './OverallCharts.module.css';
import React from 'react';
import { Chart, registerables } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { useSelector } from 'react-redux';
import {
	getDashboardGraphs,
	getDashboardPeriod,
	getSites,
} from '../../../store/selectors/mining/mining.selectors';
import { IMineceptDashboardOverallGraphs } from '../../../store/reducers/mining/IMiningReducerState';
import SeverityBySiteChart from './charts/SeverityBySiteChart';
import EventsByTypeChart from './charts/EventsByTypeChart';
import EventsOverTimeChart from './charts/EventsOverTimeChart';

Chart.register(...registerables);
Chart.register(ChartDataLabels);

const OverallCharts = () => {
	const sites = useSelector(getSites);
	const sitesMap = Object.fromEntries(
		(sites || []).map(({ id, name }) => [id, name])
	);
	const graphs: IMineceptDashboardOverallGraphs | undefined = useSelector(
		getDashboardGraphs
	);
	const period: number = useSelector(getDashboardPeriod);

	const getSiteLabels = (graphSites: string[]) =>
		graphSites.map(site => sitesMap[site] || site);

	return (
		<div className={classes.body}>
			<div className={classes.chart}>
				{graphs && graphs.severityBySite && (
					<SeverityBySiteChart
						graph={graphs.severityBySite}
						getSiteLabels={getSiteLabels}
					/>
				)}
			</div>
			<div className={classes.chart}>
				{graphs && graphs.eventsByType && (
					<EventsByTypeChart graph={graphs.eventsByType} />
				)}
			</div>
			<div className={`${classes.chart} ${classes.wideChart}`}>
				{graphs && graphs.eventsOverTime && (
					<EventsOverTimeChart graph={graphs.eventsOverTime} 
						sitesIdToLabelMap={sitesMap} period={period} />
				)}
			</div>
		</div>
	);
};
export default OverallCharts;
