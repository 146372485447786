import { selector } from '../../../index';

export const getAdasZones = (state)=>state.adasAlerts.zones;
export const getLaneAlert = (state)=> state.adasAlerts.showLaneAlert;

export const getAlertedLanes = (): Set<string> => {
	return selector('adasAlerts.lanes');
};

export const getAlertedZones = (): Set<string> => {
	return selector('adasAlerts.zones');
};

export const getShowLaneAlert = (): boolean => {
	return selector('adasAlerts.showLaneAlert');
};
