import classes from './SiteChart.module.css';
import React from 'react';
import { Chart, registerables } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { useSelector } from 'react-redux';
import { getDashboardGraphs, getDashboardPeriod } from '../../../store/selectors/mining/mining.selectors';
import { IMineceptDashboardSiteGraphs } from '../../../store/reducers/mining/IMiningReducerState';
import EventsBySeverityChart from './charts/EventsBySeverityChart';
import EventsByTypeChart from './charts/EventsByTypeChart';
import EventsByHourChart from './charts/EventsByHourChart';

Chart.register(...registerables);
Chart.register(ChartDataLabels);

const SiteChart = () => {
	const graphs: IMineceptDashboardSiteGraphs | undefined = useSelector(
		getDashboardGraphs
	);
	const period: number = useSelector(getDashboardPeriod);

	return (
		<div className={classes.body}>
			<div className={classes.chart}>
				{graphs && graphs.eventsBySeverity && (
					<EventsBySeverityChart graph={graphs.eventsBySeverity} period={period} />
				)}
			</div>
			<div className={classes.chart}>
				{graphs && graphs.eventsByType && (
					<EventsByTypeChart graph={graphs.eventsByType} period={period} />
				)}
			</div>
			<div className={classes.chart}>
				{graphs && graphs.eventsByHour && (
					<EventsByHourChart graph={graphs.eventsByHour} />
				)}
			</div>
		</div>
	);
};
export default SiteChart;
