import { ErrorActionTypes, IErrorPayload } from '../actions/errorMessages.actions';

export interface IErrorMessagesState {
	errorMessage: IErrorPayload | null
}

export const ErrorMessagesInitialState = {
	errorMessage: null
};

export default (state: IErrorMessagesState = ErrorMessagesInitialState, action) => {

	switch (action.type) {

		case ErrorActionTypes.SEND_ERROR_MESSAGE:
			return {
				...state,
				errorMessage: action.payload
			};

		case ErrorActionTypes.RESET_ERROR_MESSAGES:
			return {
				...state,
				errorMessage: null
			};
	}

	return state;
}
