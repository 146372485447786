import { makeStyles } from '@material-ui/core/styles';
import background from '../../assets/images/backgrounds/login.svg';
import profileConfig from '../../core/profiles/profileConfig';

export default makeStyles({
	root:{
		width: '100%',
		height: '100%',
		backgroundImage: `url(${background})`,
		backgroundSize: 'cover',
		position: 'relative',
	},
	loginSection:{
		position: 'absolute',
		top: '20vh',
		left: 50,
		width: '45%',
		maxWidth: 520,
		height: '60vh',
		background: 'white',
		borderRadius: 17,
		paddingTop: 50,
		paddingBottom: 50,
		paddingLeft: 'min(5%, 52px)',
		paddingRight: 'min(5%, 52px)',
		'@media (max-height: 800px)' : {
			top: '10vh',
			height: '80vh',
		},
		'@media (max-width: 500px)' : {
			width: '80%',
			maxWidth: '80%',
		}
	},
	title:{
		fontFamily: 'Mukta Mahee',
		fontSize: 'min(4vw, 32px)',
		fontWeight: 500,
		textAlign: 'center',
		color: '#0f2365',
	},
	logoutButton:{
		position: 'absolute',
		bottom: 'min(10vh, 60px)',
		width: '80%',
		textAlign: 'center',
		paddingTop: '3%',
		paddingBottom: '3%',
		borderRadius: 34,
		boxShadow: '0 2px 4px 3px rgba(224, 224, 224, 0.5)',
		backgroundImage: 'linear-gradient(to left, #48bfe3 -5%, #4390ec 104%)',
		color: 'white',
		fontSize: 20,
		cursor: 'pointer',
		'@media (max-height: 650px)' : {
			bottom: 'min(5vh, 60px)',
		}
	},
	logo:{
		position: 'absolute',
		bottom: '25vh',
		right: 'max(30%, 20px)',
		height: '20vh',
		'@media (max-width: 500px)' : {
			bottom: profileConfig().showClientLogo ? '25vh' :'40vh',
			height: profileConfig().showClientLogo ? '12vh' :'20vh',
			opacity: 0.2,
			right: profileConfig().showClientLogo ? '55vw' :'calc(50vw - 9.3vh)',
		}
	},
	clientLogo: {
		position: 'absolute',
		right: '5%',
		maxWidth: '40vh',
		maxHeight: '40vh',
		bottom: '25vh',
		'@media (max-width: 500px)' : {
			bottom: '25vh',
			width: '12vh',
			opacity: 0.2,
			left: '55vw',
		}
	},
});
