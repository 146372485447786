import 'ol/ol.css';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { setActiveMenu } from '../../../store/actions/core.actions';
import xIcon from '../../../assets/images/x-icon.svg';
import deleteIcon from '../../../assets/images/garbish.svg';
import { deleteAdasFeature, editFeatureStyle } from '../effects/adas-layers.effects';
import { editFeature } from '../actions/adas-layers.actions';
import { AdasLayerStylesEnum } from '../../../definition/adas-layer-styles-enum';
import Dialog from '@material-ui/core/Dialog/Dialog';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import Button from '@material-ui/core/Button/Button';
import './EditFeatureModePanel.scss';
import olMapService from '../../../core/services/ol-map.service';

interface IProps {
	display: boolean;
	layerTitle: string,
	layerId: string,
	featureId: string;
	activeMenu: string;
	setActiveMenu: (string) => void;
	editFeature: (string) => void;
	editFeatureStyle;
	deleteAdasFeature;
}

interface IDeletestate {
	openDialog: boolean;
}

class EditFeatureModePanel extends Component<IProps, IDeletestate> {

	state: IDeletestate = {
		openDialog: false
	};

	constructor(props) {
		super(props);
	}

	get className() {
		return classNames({
			'panel-container': true,
			'edit-feature-mode-panel-container': true,
			'visible': this.props.display
		});
	}

	onDeleteAdasFeature() {
		this.props.deleteAdasFeature(this.props.layerId, this.props.featureId);
		this.closeDialog();
		this.closeWindow();
	}

	closeWindow() {
		this.props.setActiveMenu('edit-layer-mode');
		this.props.editFeatureStyle(this.props.layerId, this.props.featureId, AdasLayerStylesEnum.HIGHLIGHT);
		olMapService.clearSelection();
		this.props.editFeature('');
	}

	openDialog() {
		this.setState({ openDialog: true });
	};

	closeDialog() {
		this.setState({ openDialog: false });
	};

	render() {

		return (
			<div className={this.className}>

				<div className='edit-mode-header'>
					<div className='title-box'>
						<div className='title'> Edit Feature mode </div>
					</div>
					<div className='x-icon-box' onClick={() => this.closeWindow()}>
						<img className='x-icon' src={ xIcon }/>
					</div>
				</div>

				<div className='edit-feature-mode-container'>
					<div className='title'>
						{ this.props.layerTitle }
						<div className='title-line'/>
					</div>

					<div className='edit-mode-footer'>
						<div className='edit-mode-footer-row' onClick={() => this.openDialog()}>
							<div className='delete-icon-box'>
								<img className='delete-icon' src={ deleteIcon } />
							</div>
							<div className='delete-title-box'>
								<div className='delete-title'> Delete feature </div>
							</div>
						</div>
					</div>

					<div>
						<Dialog
							open={ this.state.openDialog }
							onClose={ this.closeDialog }
							aria-labelledby="alert-dialog-title"
							aria-describedby="alert-dialog-description">
							<DialogContent>
								<DialogContentText id="alert-dialog-description">
									Are you sure you want to delete this feature?
								</DialogContentText>
							</DialogContent>
							<DialogActions>
								<Button onClick={() => this.closeDialog() } color="primary">
									Cancel
								</Button>
								<Button onClick={() => this.onDeleteAdasFeature()} color="primary" autoFocus={ true }>
									Delete
								</Button>
							</DialogActions>
						</Dialog>
					</div>

				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	layerTitle: state.adasLayers.adasEditLayer.title,
	layerId: state.adasLayers.adasEditLayer.id,
	featureId: state.adasLayers.featureId,
	activeMenu: state.core.activeMenu
});

const mapDispatchToProps = {
	setActiveMenu,
	deleteAdasFeature,
	editFeature,
	editFeatureStyle
};

export default connect(mapStateToProps, mapDispatchToProps)(EditFeatureModePanel);
