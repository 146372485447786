
import {generateHazardIcon} from '../../../services/icons-generator-service/icons-generator-service';

export const getHazardIcon = (type: number = 0, severity: number = 0, selected = false) => {
	try {
		return generateHazardIcon(type,selected,severity);
	} catch(err) {
		console.log(`Failed to generate hazard icon, type=${type}, severity=${severity}, selected=${selected}, reason: ${err.message}`);
	}
	return generateHazardIcon(0, false, 0);

};
