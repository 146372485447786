import React from 'react';
import useStyles from './arrow.css';

interface Interface {
	isAlert: boolean
	start: number
	end: number
}

function Arrow({ isAlert, start, end }: Interface) {
	const classes = useStyles({ isAlert, start, end});
	return (
		<div className={classes.root}>
			{/*<img src={truck} width={truckWidth}/>*/}
		</div>
	);
}

export default Arrow;
