import sensorDataProvider from '../services/sensor-data.provider';
import mineceptServerSocket from '../services/minecept/mineceptServerSocket';
import profileConfig from '../profiles/profileConfig';
import { initGPS } from '../services/gps';
import { config } from '../../services/config';

export const startListener = () => {
	if (!config.isMultiSite) {
		mineceptServerSocket.initSocketListeners();
	}
	if(!profileConfig().listenToMineceptSensor){
		if(profileConfig().useGps) initGPS();
		return;
	}
	sensorDataProvider.newLocationEvent();
	sensorDataProvider.newFallingEvent();
	sensorDataProvider.newTruckStatusEvent();
	sensorDataProvider.updateVectorMap();
	sensorDataProvider.updateObstaclesEvent();
	sensorDataProvider.mineceptStatusEvent();
	sensorDataProvider.disconnectEvent();
};
