import { point } from '@turf/turf';
import Feature from 'ol/Feature';
import { Icon, Style } from 'ol/style';
import arrowImage from '../../assets/images/arrow.svg';
import { GeoJSON } from 'ol/format';
import arrowImageSl from '../../assets/images/sl-arrow.png';
import arrowImageGps from '../../assets/images/gps-arrow.png';
import arrowImage30k from '../../assets/images/30k-arrow.png';
import { Coords } from '../../definition/simple-types';
import selfLocationIcon1 from '../../assets/images/selfIcons/selfLocation1.svg';
import selfLocationIcon2 from '../../assets/images/selfIcons/selfLocation2.svg';
import selfLocationIcon3 from '../../assets/images/selfIcons/selfLocation3.svg';
import selfLocationIcon4 from '../../assets/images/selfIcons/selfLocation4.svg';
import { Feature as GeoFeature, Geometry, Point } from 'geojson';

const SelfIconId = parseInt(process.env.REACT_APP_SELF_ICON_VARIANT as string);
//TODO: if self location icon needs to be changed it's here
const locationIcons = [
	selfLocationIcon1,
	selfLocationIcon2,
	selfLocationIcon3,
	selfLocationIcon4
];

const selfIcon = locationIcons[SelfIconId] || locationIcons[0];

export class ArrowFeature {

	arrowFeature!: Feature;
	arrowFeatureId = 'arrow';
	_followRoute = true;
	dataProjection = 'EPSG:4326';
	featureProjection = 'EPSG:3857';

	sensorColors = {
		"sl": {
			main: "#3CD0FF",
			gradient: "#0E7CC3"
		},
		"30k": {
			main: "#E040FB",
			gradient: "#AF00D5"
		},
		"gps": {
			main: "#FF5722", 
			gradient: "#C24725"
		},
		"another": {
			main: "",
			gradient: ""
		}
	};

	get followRoute(){
		return this._followRoute;
	}
	set followRoute(value) {
		this._followRoute = value;
		this.updateRotateWithView(value);
	}

	constructor(sensorType: string) {
		this.createFeature(sensorType);
	}

	private createFeature(sensorType: string) {

		this.arrowFeature = new Feature({
			type: 'arrow'
		});

		this.arrowFeature.setId(this.arrowFeatureId);

		const image = this.createIcon(selfIcon);
		// const image = this.createIcon(this.getIconSvg(this.sensorColors[sensorType].main, this.sensorColors[sensorType].gradient));
		const arrowStyle = new Style({ image, zIndex: 100 });
		this.arrowFeature.setStyle(arrowStyle);
	}

	getFeature(): Feature {
		return this.arrowFeature;
	}

	// 1976D2
	getIconSvg(mainColor: string, gradientColor: string){
		return 'data:image/svg+xml;base64,' +  btoa(`<?xml version="1.0" encoding="UTF-8"?>
					<svg width="98px" height="122px" viewBox="0 0 98 122" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
						<!-- Generator: Sketch 53.2 (72643) - https://sketchapp.com -->
						<title>noun_Cursor_451584 Copy</title>
						<desc>Created with Sketch.</desc>
						<defs>
							<linearGradient x1="50%" y1="100%" x2="50%" y2="-2.91086054%" id="linearGradient-1">
								<stop stop-color="${mainColor}" offset="0%"></stop>
								<stop stop-color="${gradientColor}" offset="100%"></stop>
							</linearGradient>
						</defs>
						<g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-opacity="0.7">
							<g id="cursor-copy" transform="translate(-26.000000, -14.000000)" fill="url(#linearGradient-1)" fill-rule="nonzero" stroke="#25334B" stroke-width="1.5">
								<g id="noun_Cursor_451584-Copy" transform="translate(27.000000, 15.000000)">
									<path d="M9.28808713,120 C10.6862601,120 12.084433,119.623824 13.4826059,118.871473 L47.9126142,100.626959 L82.3426225,118.871473 C83.7407954,119.623824 85.1389683,120 86.5371413,120 C89.6830303,120 92.6541478,118.30721 94.401864,115.485893 C96.1495801,112.664577 96.4991233,109.278997 95.275722,106.081505 L56.651195,6.20689655 C55.2530221,2.44514107 51.9323614,0 47.9126142,0 C43.892867,0 40.7469779,2.44514107 39.1740334,6.20689655 L0.72427796,105.893417 C-0.49912335,109.090909 -0.149580118,112.476489 1.59813604,115.297806 C3.17108058,118.30721 6.14219805,120 9.28808713,120 Z" id="Shape"></path>
								</g>
							</g>
						</g>
					</svg>`)
	}

	updateImageRotation(rotation: number ) {
		const arrowStyle: Style = this.arrowFeature.getStyle();
		const rotationInRadians = this.followRoute ? rotation : 0 ;
		arrowStyle.getImage().setRotation(rotationInRadians);
	}

	updateRotateWithView(rotateWithView: boolean){
		const arrowStyle: Style = this.arrowFeature.getStyle();
		arrowStyle.getImage().setRotateWithView(rotateWithView);
	}

	updateFeature(point: Geometry) {
		this.arrowFeature.setGeometry(point);
	}

	private createIcon(arrowImage: string): Icon {
		return new Icon({
			src: arrowImage,
			scale: 0.7,
			anchor: [0.5, 0.5],
			rotateWithView: true
		});
	}
}

export default ArrowFeature;
