import { makeStyles } from '@material-ui/core/styles';

interface Props{
	shiftDistance: number
}
export default makeStyles({
	snack: (props:Props) => ({
		left: 100,
		transform: `translate(${props.shiftDistance }px,0px)`,
		transition: '0.5s'
	}),
});
