import { getHazardLabel} from '../../../core/services/minecept/auxilary';
import { unixToDateTime } from '../../../core/auxilary/formatting';
import {getHazardIcon} from "../../../core/services/icons/obstacleIcons";

export const phraseHazardFeature = (feature) => {
	const phrasedData = {
		label: getHazardLabel(feature.obstacle_type, feature.obstacle_description),
		symbol: getHazardIcon(feature.obstacle_type, feature.severity),
		fields: [
			{ title: 'Machine ID', value: feature.created_by },
			{/* title: 'Operator', value: feature.operator_id */},
			// { title: 'Other Information', value: feature.other || '---' },
			{ title: 'Time of Event', value: unixToDateTime(feature.creation_time)},
			{ title: 'Last Seen By Vehicle', value: feature.last_seen_by },
			{ title: 'Last Seen By Operator', value: feature.last_seen_by_operator },
			{ title: 'Last Seen At', value: unixToDateTime(feature.last_seen_time)},
		],
	};
	if(!feature.is_active){
		phrasedData.fields.push({title: 'Removed At', value: unixToDateTime(feature.inactive_time) })
	}
	if(feature.other){
		phrasedData.fields.splice(2, 0, { title: 'Event Details', value: feature.other });
	}
	return phrasedData;
};
