import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
	root:{
		zIndex:1,
		backgroundColor: '#3a475d',
		borderRadius: 6,
		position: 'absolute',
		left:0,
		top:40,
		width: 200,
		color: 'white',
		fontFamily: 'Mukta Mahee',
		fontSize: 16,
	},
	titleBar:{
		display: 'flex',
		justifyContent: 'space-between',
		padding: 9,
	},
	closeButton:{
		width:14,
		height: 14
	},
	radioButton:{
		color: 'white !important',
	}
});
