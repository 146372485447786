import { generateSafetyIcon } from '../../../services/icons-generator-service/icons-generator-service';

export const getSafetyEventIcon = (type: number = 0, severity: number = 0, selected = false) => {
	try {
		return generateSafetyIcon(type, selected, severity);
	} catch (err) {
		console.log(`Failed to generate safety icon, type=${type}, severity=${severity}, selected=${selected}, reason: ${err.message}`);
	}
	return generateSafetyIcon(0, false, 0);

};
