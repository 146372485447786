import { makeStyles } from '@material-ui/core/styles';
import profileConfig from '../../../../core/profiles/profileConfig';

const axisPosition = parseFloat(process.env.REACT_APP_REVERSE_AXIS_POSITION as string);
const vehiclePositionOnAxis = parseFloat(process.env.REACT_APP_REVERSE_VEHICLE_PORTION_ON_AXIS as string);

interface Props{
	truckWidth:number
	imgRatio: number
}

export default makeStyles({
	root: {
		zIndex: -1,
	},
// @ts-ignore
	truck: (props:Props) => {
		const style = {
			position: 'relative',
			width: props.truckWidth,
			left: `calc(50% - ${props.truckWidth / 2}px)`,
		};
		const distanceFromAxis = props.truckWidth * props.imgRatio * vehiclePositionOnAxis;
		if (profileConfig().flipSafeUnloadingScreen) {
			return {
				...style,
				bottom: `calc(${axisPosition*100}vh - ${distanceFromAxis}px)`,
			};
		}
		return {
			...style,
			top: `calc(${axisPosition*100}vh - ${distanceFromAxis}px)`,
			transform: 'rotate(180deg)'
		};
	},
});
