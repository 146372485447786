import { lineString, point } from '@turf/turf';
import { Feature, LineString, Point } from 'geojson';
import { coordAll } from '@turf/meta';
import { getCoord } from '@turf/invariant';
import { isEmpty } from 'lodash';

export class GeoJsonActionService {
	public static concatLineString(line: Feature<LineString, any>, point: Feature<Point, any>): Feature<LineString, any> {
		const lineStringCords = coordAll(line);
		const pointCoords = getCoord(point);
		return lineString(lineStringCords.concat([pointCoords])) as Feature<LineString, any>;
	}

	public static removeLineStringFirstItem(line: Feature<LineString, any>): Feature<LineString, any> {
		const lineStringCords = coordAll(line);
		return lineString(lineStringCords.slice(1)) as Feature<LineString, any>;
	}

	public static newLineString(pointA: Feature<Point, any>, pointB: Feature<Point, any>): Feature<LineString, any> {
		return lineString([getCoord(pointA), getCoord(pointB)]) as Feature<LineString, any>;
	}

	public static getLastLineStringPoint(line: Feature<LineString, any>): Feature<Point, any> | undefined {
		if (isEmpty(line)) {
			return undefined;
		}
		const lineStringCoords = coordAll(line);
		const [lastPoint] = lineStringCoords.slice(-1);
		return point(lastPoint) as Feature<Point, any>;
	}
}

export default new GeoJsonActionService();
