import React from 'react';
import useStyles from './obstacleTooltip.css';
import { useSelector, useDispatch } from 'react-redux';
import {getObstacleTooltipData } from '../../../store/selectors/mining/mining.selectors';
import { closeTooltip } from '../../../store/actions/mining.actions';
import olMapService from '../../../core/services/ol-map.service';
import closeImage from '../../../assets/images/clear.svg';
import { config } from '../../../services/config';

function ObstacleTooltip() {
	const dispatch = useDispatch();
	const {isOpen, severity, obstacleType, obstacleDescription} = useSelector(getObstacleTooltipData);
	const classes = useStyles({severity});
	const close = ()=>{
		dispatch(closeTooltip());
		olMapService.clearSelection();
	};
	const obstacleLabels = config.hazardAndSafetyLabels.hazards;
	const label = obstacleType !==0 || obstacleDescription === ''? obstacleLabels[obstacleType]: obstacleDescription;
	const description = label || obstacleLabels[0];
	return (
		<div>
			{
				isOpen &&
				<div className={classes.root}>
					<span className={classes.label}>{description}</span>
					<span className={classes.closeButton} onClick={close}><img className={classes.closeSymbol} src={ closeImage }/></span>
				</div>
			}
		</div>
	);
}

export default ObstacleTooltip;
