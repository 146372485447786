import { createSelector } from 'reselect';
import profileConfig from '../../core/profiles/profileConfig';

export const getAdasEditLayer = (state) => state.adasLayers.adasEditLayer;

export const getAdasEditLayerId = createSelector(
[getAdasEditLayer],
(adasEditLayer) =>
		adasEditLayer ? adasEditLayer.id : ''
);
