import { adasLayersActionTypes } from '../actions/adas-layers.actions';
import { DrawModes } from '../../../definition/draw-modes';
import { AdasLayerStylesEnum } from '../../../definition/adas-layer-styles-enum';

export interface IAdasLayersState {
	adasLayersMap: Map<string, IAdasLayersMap>;
	adasEditLayer: IEditAdasLayer;
	featureId: string;
}

export interface IAdasLayersMap {
	title: string;
	isDisplay: boolean;
}

export interface IAdasLayer {
	id: string;
	title: string;
	isDisplay: boolean;
}

export interface IEditAdasLayer {
	id: string;
	title: string,
	style: AdasLayerStylesEnum;
	drawMode: DrawModes;
}

export const AdasLayersInitialState = {
	adasLayersMap: new Map(),
	adasEditLayer: {
		id: '',
		title: '',
		style: AdasLayerStylesEnum.DEFAULT,
		drawMode: DrawModes.NONE
	},
	featureId: ''
};

export default (state: IAdasLayersState = AdasLayersInitialState, action) => {

	switch (action.type) {

		case adasLayersActionTypes.ADD_ADAS_LAYERS: {
			const adasLayersMap = new Map(state.adasLayersMap);
			action.payload.forEach(adasLayer => {
				const value = { ...adasLayer, isDisplay: true };
				adasLayersMap.set(adasLayer.id, value);
			});
			return { ...state, adasLayersMap };
		}

		case adasLayersActionTypes.REMOVE_ADAS_LAYER: {
			const adasLayersMap = new Map(state.adasLayersMap);
			adasLayersMap.delete(action.payload);
			return { ...state, adasLayersMap };
		}

		case adasLayersActionTypes.TOGGLE_ADAS_LAYER_DISPLAY: {
			const adasLayersMap = new Map(state.adasLayersMap);
			const adasLayer = adasLayersMap.get(action.payload.adasLayerKey);
			if (adasLayer) {
				const value = { ...adasLayer, isDisplay: !adasLayer.isDisplay };
				adasLayersMap.set(action.payload.adasLayerKey, value);
			}
			 return { ...state, adasLayersMap };
		}

		case adasLayersActionTypes.EDIT_ADAS_LAYER: {
			return { ...state, adasEditLayer: action.payload };
		}

		case adasLayersActionTypes.EDIT_FEATURE: {
			return { ...state, featureId: action.payload };
		}

		case adasLayersActionTypes.CHANGE_LAYER_TITLE: {
			const adasLayersMap = new Map(state.adasLayersMap);
			const adasLayer = adasLayersMap.get(action.payload.adasLayerKey);
			let adasEditLayer;
			if (state.adasEditLayer) {
				adasEditLayer = { ...state.adasEditLayer, title: action.payload.title };
			}
			if (adasLayer) {
				const value = { ...adasLayer, title: action.payload.title };
				adasLayersMap.set(action.payload.adasLayerKey, value);
			}
			return { ...state, adasLayersMap, adasEditLayer };
		}

	}
	return state;
};
