const truckId = parseInt(process.env.REACT_APP_VEHICLE_ID as string);
const obstacleMinSeverityToShow = parseInt(process.env.REACT_APP_OBSTACLE_MINIMUM_SEVERITY_TO_SHOW as string);
const maxObstacleAge = parseInt(process.env.REACT_APP_OBSTACLE_MAXIMUM_AGE_TO_SHOW_MINUTES as string) * 60;
const obstacleTypesToShow = JSON.parse(process.env.REACT_APP_OBSTACLE_TYPE_TO_SHOW as string);

export const filterObstacles = (obstacles, filterSelf = true)=>{
	const minCreationDate = (Date.now() / 1000 | 0) - maxObstacleAge;

	return obstacles.filter(obstacle=>{
		if(filterSelf && obstacle.created_by === truckId) return false;
		if(obstacle.severity < obstacleMinSeverityToShow) return false;
		if(!obstacleTypesToShow.includes( obstacle.obstacle_type as number)) return false;
		return !obstacle.creation_time || obstacle.creation_time >= minCreationDate;
	});
	//
	//
	// const output = {
	// 	type: "FeatureCollection",
	// 	features:[] as Array<any>
	// }
	// console.log(obstacles);
	// obstacles.forEach(obstacle=>{
	//
	// 	output.features.push({
	// 		"type": "Feature",
	// 		"properties": {
	//
	//
	// 		},
	// 		"geometry": {
	// 			"type": "Point",
	// 			"coordinates": [
	// 				obstacle.longitude,
	// 				obstacle.latitude
	// 			]
	// 		}
	// 	});
	// })
	// return output;
};


// export const filterObstacles = (geoJsonObject, filterSelf = true)=>{
// 	const minCreationDate = (Date.now() / 1000 | 0) - maxObstacleAge;
// 	const output = {
// 		type: "FeatureCollection",
// 		features:[] as Array<any>
// 	}
// 	geoJsonObject.features.forEach(obstacle=>{
// 		if(filterSelf && obstacle.properties.created_by === truckId) return;
// 		if(obstacle.properties.severity < obstacleMinSeverityToShow) return;
// 		if(!obstacleTypesToShow.includes( obstacle.properties.obstacleType as number)) return;
// 		if(obstacle.properties.creation_time < minCreationDate) return;
// 		output.features.push(obstacle);
// 	})
// 	return output;
// };
