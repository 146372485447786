import { Fill, Stroke, Style } from 'ol/style';

const styleFunction = () => {
	return new Style({
		stroke: new Stroke({
			color: '#45afcd',
			width: 2,
			lineDash: [1,1],
		}),
		fill: new Fill({
			color: 'rgba(69, 175, 205, 0.1)',
		}),
	});
};

export default styleFunction;
