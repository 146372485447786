import TileLayer from 'ol/layer/Tile';
import BingMaps from 'ol/source/BingMaps';
import OSM from 'ol/source/OSM';
import WMTS from 'ol/source/WMTS';
import WMTSTileGrid from 'ol/tilegrid/WMTS';
import { getWidth, getTopLeft } from 'ol/extent.js';
import { get as getProjection } from 'ol/proj.js';

import { sourceMapOptions } from '../../definition/source-map-options';
import * as proj from 'ol/proj'

let customLayerExtent;
try{
	customLayerExtent = proj.transformExtent(JSON.parse(process.env.REACT_APP_GEOSERVER_LAYERS_EXTENT as string), 'EPSG:4326', 'EPSG:3857');
}catch (e){}

class MapSourceProvider {

	// extentLayers: number[][] = [
	// 	proj.transformExtent([35.172916367, 31.106724343, 35.212154962, 31.122825698], 'EPSG:4326', 'EPSG:3857'),
	// 	proj.transformExtent([35.1852962894912, 31.79432906352229, 35.20212633268614, 31.819351279358003], 'EPSG:4326', 'EPSG:3857'),
	// 	proj.transformExtent([35.20212633268614, 31.79432906352229, 35.2189544913233, 31.819351279358003], 'EPSG:4326', 'EPSG:3857'),
	// 	proj.transformExtent([35.16846624629626, 31.769306847686583, 35.1852962894912, 31.79432906352229], 'EPSG:4326', 'EPSG:3857'),
	// 	proj.transformExtent([35.1852962894912, 31.769306847686583, 35.20212633268614, 31.79432906352229], 'EPSG:4326', 'EPSG:3857'),
	// 	proj.transformExtent([35.20212633268614, 31.769306847686583, 35.2189544913233, 31.79432906352229], 'EPSG:4326', 'EPSG:3857'),
	// 	proj.transformExtent([35.16846624629626, 31.744286516408643, 35.1852962894912, 31.769306847686583], 'EPSG:4326', 'EPSG:3857'),
	// 	proj.transformExtent([35.1852962894912, 31.744286516408643, 35.20212633268614, 31.769306847686583], 'EPSG:4326', 'EPSG:3857'),
	// 	proj.transformExtent([35.20212633268614, 31.744286516408643, 35.2189544913233, 31.769306847686583], 'EPSG:4326', 'EPSG:3857'),
	// ];

	provideLayers(layerType: string): TileLayer[] {
		return this.createMapLayers(this.getSource(layerType), layerType);
	}

	private createMapLayers(sources: any | any[], key: string): TileLayer[] {
		if (!Array.isArray(sources)) {
			sources = [sources];
		}
		return sources.map((source, index) => {
			const layer = new TileLayer({ source });
			layer.set('id', key);
			if (key === sourceMapOptions.DETAILED_SATELLITE) {
				layer.setZIndex(10);
				if(customLayerExtent){
					layer.setExtent(customLayerExtent);
				}
				// const extent = this.extentLayers[index];
				// if (extent) {
				// 	layer.setExtent(extent);
				// }
			}
			return layer;
		});
	}

	private getSource(layerType: string): any {
		switch (layerType) {
			case sourceMapOptions.OSM:
				return this.getOSMSource();
			case sourceMapOptions.ROADS:
				return this.getBingSource('Road');
			case sourceMapOptions.SATELLITE:
				return this.getBingSource('Aerial');
			case sourceMapOptions.DETAILED_SATELLITE:
				return this.getWMTSSources();
			default :
				return [];
		}
	}

	private getOSMSource(): OSM {
		return new OSM();
	}

	private getBingSource(imagerySet: string): BingMaps {
		return new BingMaps({
			key: process.env.REACT_APP_BING_API_KEY as any,
			imagerySet,
			maxZoom:19,
		});
	}

	private getWMTSSources(): WMTS[] {

		const workspace = process.env.REACT_APP_GEOSERVER_RASTERS_WORKSPACE as string;
		const url = `${process.env.REACT_APP_GEOSERVER_BASE_URL as string}/${process.env.REACT_APP_GEOSERVER_GWC_URL as string}/wmts`;
		const layersAmount = parseInt(process.env.REACT_APP_GEOSERVER_LAYERS_AMOUNT as string, 10);
		const params = this.getWmtsParams();
		const sources: WMTS[] = [];

		for (let i = 0; i < layersAmount; i++) {
			const layer =  `${workspace}:${this.getSLLayerName(i)}`;

			const source = new WMTS({
				url,
				layer,
				matrixSet: params.matrixSet,
				format: 'image/png',
				projection: params.projection,
				tileGrid: params.tileGrid,
				style: '',
				wrapX: true,
				maxZoom:19,
			});
			sources.push((source));
		}

		return sources;
	}

	private getWmtsParams(): any {

		const matrixSet = process.env.REACT_APP_GEOSERVER_MATRIXSET as string;
		const tileSize = parseInt(process.env.REACT_APP_GEOSERVER_TILE_SIZE as string, 10);
		const maxZoom = parseInt(process.env.REACT_APP_GEOSERVER_MAX_ZOOM_LEVEL as string, 10);

		const projection = getProjection(matrixSet);
		const projectionExtent = projection.getExtent();
		const size = getWidth(projectionExtent) / tileSize;
		const resolutions = new Array(maxZoom);
		const matrixIds = new Array(maxZoom);

		for (let z = 0; z < maxZoom; z++) {
			resolutions[z] = size / Math.pow(2, z);
			matrixIds[z] = `${matrixSet}:${z}`;
		}

		const tileGrid = new WMTSTileGrid({
			origin: getTopLeft(projectionExtent),
			resolutions,
			matrixIds,
			tileSize,
			maxZoom:19,
		});

		return {
			matrixSet,
			tileGrid,
			projection
		};
	}

	private getSLLayerName(index: number): string {
		const layername = process.env.REACT_APP_GEOSERVER_LAYER_NAME as string;
		return `${layername}_${(index + 1)}`;
	}
}

export default new MapSourceProvider();
