import { Style, Icon } from 'ol/style';
import { config } from '../../../../services/config';
import {
	cartesian,
	generateHazardIcon,
	selectedOptions,
	severityOptions,
} from '../../../../services/icons-generator-service/icons-generator-service';

const styleCache: any = [];

const obstacleIconStyle = (isSelected, obstacleType, severity) => {
	const svg = generateHazardIcon(obstacleType, isSelected, severity);
	return new Style({
		image: new Icon({
			anchor: [0.5, 0.5],
			src: svg,
			scale: 0.7,
		}),
	});
};

const getCacheKey = (selected, obstacleType, severity) => {
	return `${selected}|${obstacleType}|${severity}`;
};

const styleFunction = selected => feature => {
	const obstacleType = feature.values_.obstacleType || 0;
	const severity = feature.values_.severity || 0;
	try {
		const styleId = getCacheKey(selected, obstacleType, severity);
		if (!styleCache[styleId]) {
			styleCache[
				getCacheKey(true, obstacleType, severity)
			] = obstacleIconStyle(true, obstacleType, severity);
			styleCache[
				getCacheKey(false, obstacleType, severity)
			] = obstacleIconStyle(false, obstacleType, severity);
		}
		return styleCache[styleId];
	} catch {
		console.warn('Style not found for', {
			selected,
			obstacleType,
			severity,
		});
	}
	return obstacleIconStyle(0, 0, 0);
};

export const generateCache = () => {
	const obstacleTypeOptions = Object.keys(
		config.hazardAndSafetyIcons.hazards
	);
	cartesian(selectedOptions, obstacleTypeOptions, severityOptions).forEach(
		([selected, obstacleType, severity]) => {
			styleCache[
				getCacheKey(selected, obstacleType, severity)
			] = obstacleIconStyle(selected, obstacleType, severity);
		}
	);
};

export default {
	selected: styleFunction(true),
	unselected: styleFunction(false),
};
