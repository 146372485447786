import { Feature, LineString } from 'geojson';
import { RouteActionTypes } from '../../core/actions/route.actions';

export interface IRouteState {
	[key: string]: Feature<LineString, any>;
}

export const RouteInitialState = {};

export default (state: IRouteState = RouteInitialState, action) => {
	switch (action.type) {
		case RouteActionTypes.UPDATE_ROUTE:
			const { line } = action.payload;
			return {
				...state,
				[action.payload.sensorType]: line
			};

		case RouteActionTypes.CLEAR_ROUTES:
			const clearedState = {};
			Object.keys(state).forEach((key) => {
				clearedState[key] = undefined;
			});
			return clearedState;

	}
	return state;
};
