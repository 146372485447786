import 'ol/ol.css';
import './ErrorGrowl.scss';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Growl } from 'primereact/components/growl/Growl';
import { IErrorSeverity } from '../../../definition/error-enum';
import { getErrorMessage$ } from '../selectors/errorMessages.selectors';
import { IErrorPayload } from '../actions/errorMessages.actions';

class ErrorMessages extends Component<any, void> {

	growl: any;
	isShowSuccessMessages;

	constructor(props) {
		super(props);
		this.isShowSuccessMessages = process.env.REACT_APP_SHOW_SUCCESS_MESSAGES === 'true';
	}

	componentDidMount() {
		getErrorMessage$().subscribe((errorMessage : IErrorPayload) => {
			if (!errorMessage) {
				return;
			}
			this.showError(errorMessage.severity, errorMessage.summary, errorMessage.detail);
		});
	}

	showError(severity: IErrorSeverity, summary: string, detail: string) {
		if (!this.isShowSuccessMessages && severity === IErrorSeverity.SUCCESS) {
			return;
		}
		this.growl && this.growl.show({ life: process.env.REACT_APP_ERROR_MESSAGE_TIME, severity, summary, detail });
	}

	render() {

		return (
			<div className='content-section implementation p-fluid'>
				<div className='growl-bottomleft'>
					<Growl ref={(el) => this.growl = el} position='bottomleft'/>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	errorMessage: state.error.errorMessage
});

export default connect(mapStateToProps)(ErrorMessages);

