import { config } from '../../../services/config';
import {
	cartesian,
	generateToasterIcon,
	severityOptions,
} from '../../../services/icons-generator-service/icons-generator-service';

export const getToasterHazardIcon = (
	obstacleType: number = 0,
	severity: number = 0
) => {
	try {
		const color = severity === 0 ? 'gray' : 'white';
		return generateToasterIcon(obstacleType, color);
	} catch (err) {
		console.log(
			`Failed to generate hazard toaster icon, type=${obstacleType}, severity=${severity}, reason: ${
				err.message
			}`
		);
	}
	return generateToasterIcon(0, 'gray');
};

export const generateCache = () => {
	const obstacleTypeOptions = Object.keys(
		config.hazardAndSafetyIcons.hazards
	);
	cartesian(obstacleTypeOptions, severityOptions).forEach(
		([obstacleType, severity]) =>
			getToasterHazardIcon(obstacleType, severity)
	);
};
