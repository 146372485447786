import Keycloak, { KeycloakTokenParsed } from "keycloak-js";
import { IUserInfo } from "../store/reducers/core.reducer";

class KeycloakService {
    _isInitialized: boolean;
    _keycloak: Keycloak | undefined;
    _token: KeycloakTokenParsed | undefined;
    _userInfo: IUserInfo | undefined;

    constructor() {
        this._isInitialized = false;
        this._keycloak = undefined;
        this._token = undefined;
        this._userInfo = undefined;
    }

    /**
     * Set keycloak state
     * @param isInitialized Is keycloak initialized
     * @param keycloak Keycloak instance
     * @returns True if changed, mainly used to notify user changed
     */
    setKeycloak(isInitialized: boolean, keycloak: Keycloak): boolean {
        this._isInitialized = isInitialized;
        this._keycloak = keycloak;
        if (this.isAuthenticated() && this._token !== keycloak.tokenParsed) {
            const tokenData = keycloak.tokenParsed;
            this._token = tokenData;
            if (tokenData) {
                this._userInfo = {
                    user: tokenData.preferred_username,
                    name: tokenData.name,
                    email: tokenData.email,
                    sites: (tokenData.groups && tokenData.groups.map((x: string) => x.substring(1))) || [],
                    roles: (tokenData.realm_access && tokenData.realm_access.roles) || []
                };
            } else {
                this._userInfo = undefined;
            }
            return true;
        }
        return false;
    }

    isInitialized() { 
        return this._isInitialized;
    };

    isAuthenticated() {
        return Boolean(this._isInitialized && this._keycloak && this._keycloak.authenticated);
    }

    userInfo() {
        return this._userInfo;
    }

    login() {
        if (!this._keycloak) {
            return;
        }
        this._keycloak.login();
    }

    logout() {
        if (!this._keycloak) {
            return;
        }
        this._keycloak.logout();
    }

    async authToken() {
        if (!this.isAuthenticated() || !this._keycloak) {
            return undefined;
        }
        if (this._keycloak.isTokenExpired(5)) {
            try {
              await this._keycloak.updateToken(5);
            } catch (e) {
              console.error("Token refresh failed", e);
              return undefined;
            }
        }
        return this._keycloak.token;
    }

    async authHeaders() {
        const token = await this.authToken();
        if (!token) {
            return undefined;
        }
        return { Authorization: `Bearer ${token}` };
    }
}

export const keycloakService = new KeycloakService();