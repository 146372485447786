import { getSafetyEventLabel} from '../../../core/services/minecept/auxilary';
import { unixToDateTime } from '../../../core/auxilary/formatting';
import { getSafetyEventIcon } from '../../../core/services/icons/safetyEventIcons';
import consts from '../consts.json';

export const phraseSafetyEventFeature = (feature) => {
	const phrasedData = {
		label: getSafetyEventLabel(feature.type_of_event, feature.event_description),
		symbol: getSafetyEventIcon(feature.type_of_event, feature.severity),
		fields: [
			{ title: 'Vehicle ID', value: feature.created_by },
			// { title: 'Operator', value: feature.operator_id || '' },
			// { title: 'Speed', value: feature.speed },
			// { title: 'Distance', value: feature.distance },
			// { title: 'Angle', value: feature.angle },
			{ title: 'Time of Event', value: unixToDateTime(feature.creation_time)}
		],
	};
	if(!feature.is_active && feature.inactive_time){
		phrasedData.fields.push({title: 'Removed At', value: unixToDateTime(feature.inactive_time) })
	}
	if(feature.other){
		phrasedData.fields.splice(5, 0, { title: consts.features.eventDetails, value: feature.other });
	}
	return phrasedData;
};
