import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
	reverseView: {
		position: 'relative',
		height: '100vh',
		width: 'calc(100vw - 250px)',
	},
	alertView: {
		position: 'relative',
		height: '100vh',
		width: 250,
		backgroundColor: '#0C1334',
	},
	alerts: {
		position:'absolute',
		bottom: 225,
		marginLeft: 10,
	},
	miniMap: {
		position: 'fixed',
		bottom: 30,
		right: 25,
		zIndex: 501,
	},

	statusBar: {
		position: 'fixed',
		bottom: 0,
		right: 0,
	},
});
