import io from 'socket.io-client';
import { store } from '../../index';
import { addNewFeature } from '../effects/route.effects';
import {
	setIsFalling,
	setMineceptStatus,
	setObstacleAlerts,
	setTruckStatus, setWarningSectors,
} from '../../store/actions/mining.actions';
import mineceptVectorLayerProvider from './layers/mineceptVectorLayerProvider';
import { convertObstacleToJson, filterObstaclesValidPosition, tagObstacles } from '../auxilary/gis';
import profileConfig from '../profiles/profileConfig';
import { MineceptLayers } from '../../definition/mineceptLayers';
import { Falling } from '../../definition/Enums';


class SensorDataProvider {

	serverPort: number = parseInt(process.env.REACT_APP_SERVER_PORT as string, 10);
	host: string = process.env.REACT_APP_HOST as string;
	socket;

	constructor() {
		if(!profileConfig().listenToMineceptSensor) return;
		this.socket = io(`http://${this.host}:${this.serverPort}`);
	}

	newLocationEvent(): void {
		this.frameDropListen('new-location',(data)=>{
			const point = data.data;
			// point.properties.rotation = 180 * Math.PI / 180;
			store.dispatch(addNewFeature({ point }) as any);
		});
	}

	newFallingEvent(): void {
		if(!this.socket) return;
		this.socket.on('falling', data => {
			const falling = data.data as Falling;
			store.dispatch(setIsFalling(falling) as any);
		});
	}

	newTruckStatusEvent(): void {
		this.frameDropListen('truckStatus',(data)=>{
			store.dispatch(setTruckStatus(data.data) as any);
		});
	}

	updateVectorMap(): void {
		this.frameDropListen('vectorMap',(data)=>{
			mineceptVectorLayerProvider.updateLayerRelativeData(MineceptLayers.berm, data.data);
		});
	}

	changeStatusEvent(callback?: (obj: { sensorStatusObject }) => void): void {
		this.frameDropListen('change-status',(data)=>{
			const sensorStatusObject = data.data;
			callback && callback({ sensorStatusObject });
		});
	}

	mineceptStatusEvent(): void {
		this.frameDropListen('mineceptStatus',( { data })=>{
			// Support previous API when data was just a number, current is structure of { status, label, text }
			const status = data.status !== undefined ? data.status : data;
			const label = data.label;
			const text = data.text;
			store.dispatch(setMineceptStatus({ status, label, text }) as any);
		});
	}

	updateObstaclesEvent(): void {
		this.frameDropListen('obstacles',(obstacleMessage)=>{
			const obstacleJson = tagObstacles(filterObstaclesValidPosition(obstacleMessage.data));
			mineceptVectorLayerProvider.updateLayerData(MineceptLayers.obstacles,obstacleJson);
			const highSeverityObstacles = obstacleMessage.data.features.
			map(obstacle=>({
				severity: obstacle.properties.severity,
				obstacleType: obstacle.properties.obstacleType,
				obstacleDescription: obstacle.properties.obstacleDescription
			}));
			store.dispatch(setObstacleAlerts(highSeverityObstacles) as any);
		});

		this.socket.on('warningSectors', msg => {
			store.dispatch(setWarningSectors(msg.data) as any);
		});

		if(profileConfig().showHazards){
			this.socket.on('obstacleEvent', ({data})=>{
				if (data.isPositionValid === false) {
					return;
				}
				const geoJson = convertObstacleToJson(data);
				mineceptVectorLayerProvider.addFeaturesToLayer(MineceptLayers.hazards, geoJson);
			});
		}
	}

	disconnectEvent(): void {
		if(!this.socket) return;
		this.socket.on('disconnect', () => {
			store.dispatch(setMineceptStatus({ status: 3, label: '', text: ''})as any);
		});
	}

	frameDropListen(event:string, callback:any):void{
		if(!this.socket) return;
		this.socket.on(event, data => {
			const lag = Date.now() - data.timeStamp;
			if(Math.random() > Math.max(1001 - lag, 0) * 0.008 + 0.2) return;
			callback(data);
		});
	}
}



export default new SensorDataProvider();
