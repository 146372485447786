import { store } from "../..";
import { IUserInfo } from "../reducers/core.reducer";
import { updateUrlWithSite } from "./mining.actions";

export interface ISetCurrentPage {
	page: string;
	isSetUrl: boolean;
}

export enum CoreActionTypes {
	SET_CURRENT_PAGE= '[Core] SET_CURRENT_PAGE',
	START_LISTEN_TO_SENSORS = '[Core] Start Listen to sensors',
	SET_ACTIVE_MENU = '[Core] active menu',
	SET_VOLUME = '[Core] SET_VOLUME',
	SET_USER_INFO = '[Core] SET_USER_INFO'
}

export const setCurrentPage = (payload: ISetCurrentPage) => {
	if (payload.isSetUrl) {
		updateUrlWithSite(store.getState().mining.multiSite.selectedSite, payload.page);
	}
	return {
		type: CoreActionTypes.SET_CURRENT_PAGE,
		payload: payload.page
	}
};

export const setActiveMenu = (payload: string) => dispatch => {
	dispatch({
		type: CoreActionTypes.SET_ACTIVE_MENU,
		payload
	});
};

export const setVolume = (payload: number) => ({
	type: CoreActionTypes.SET_VOLUME,
	payload: Math.min(1,Math.max(0,payload))
})

export const setUserInfo = (payload: IUserInfo) => ({
	type: CoreActionTypes.SET_USER_INFO,
	payload
});
