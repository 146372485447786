import React from 'react';
import useStyles from './vehicleTooltip.css';
import { useSelector, useDispatch } from 'react-redux';
import olMapService from '../../core/services/ol-map.service';
import closeImage from '../../assets/images/clearBlue.svg';
import { getVehicleTooltipData } from '../../store/selectors/mining/mining.selectors';
import { setVehicleTooltip } from '../../store/actions/mining.actions';
import { convertTimeToText } from '../../core/auxilary/formatting';
import { isFullLengthMenuOpen } from '../../store/selectors/core.selector';

const NO_SIGNAL_TIME = parseInt(process.env.REACT_APP_VEHICLE_NO_SIGNAL_TIME as string)*1000;

function VehicleTooltip() {
	const dispatch = useDispatch();
	const vehicle = useSelector(getVehicleTooltipData);
	const menuOpen = useSelector(isFullLengthMenuOpen);
	const classes = useStyles({menuOpen});

	if(!vehicle) return (<></>);

	const close = ()=>{
		dispatch(setVehicleTooltip());
		olMapService.clearSelection();
	};
	const noSignal = Date.now() - vehicle.lastSeen > NO_SIGNAL_TIME;
	const lastSeen = convertTimeToText(vehicle.lastSeen/1000);
	return (
		<div className={classes.root}>
			<div>
				<div>
					<span className={classes.id}>{vehicle.id}</span>
					{noSignal &&
						<span className={classes.noSignal}>No Signal</span>
					} </div>
				<div className={classes.lastSeen}>Last Seen: {lastSeen}</div>
			</div>
			<span className={classes.closeButton} onClick={close}><img className={classes.closeSymbol} src={ closeImage }/></span>
		</div>
	);
}

export default VehicleTooltip;
