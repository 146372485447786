import { makeStyles } from '@material-ui/core/styles';
import profileConfig from '../../../../core/profiles/profileConfig';

interface Props{
	isAlert:boolean
	position:number
}

export default makeStyles({
// @ts-ignore
	root: (props:Props) => {
		const style = {
			position: 'absolute',
			// zIndex: 100,
			left: `calc(50% - 16px)`,
			height: 32,
			display: 'flex',
			backgroundColor: props.isAlert ? 'rgba(255,106,105,0.5)' : 'rgba(145,252,154,0.4)',
			paddingRight :10,
			// borderRadius: 16,
			borderRadius: '16px 6px 6px 16px',
			zIndex:1
		};
		if (profileConfig().flipSafeUnloadingScreen) {
			return {
				...style,
				bottom: props.position,
			};
		}
		return {
			...style,
			top: props.position,
		};
	},
	symbol: props => ({
		paddingTop:4,
		paddingLeft:1,
		width: 32,
		height: 32,
		borderRadius: 16,
		background: props.isAlert ? '#ff6665' : '#91fc9a',
		color: props.isAlert ? 'white' : '#28334a',
		fontSize: 17,
		fontFamily: 'Mukta Mahee',
		textAlign: 'center',
	}),
	label:{
		paddingLeft:20,
		color: 'white',
		fontFamily: 'Mukta Mahee',
		fontSize: 20,
		fontWeight: 600,
		margin: 'auto',
	},
});
