import React from 'react';
import './AdasAlertsDisplay.scss';
import { connect } from 'react-redux';
import LaneAlertIcon from '../../../assets/images/alert-lane.svg';
import ZoneAlertIcon from '../../../assets/images/alert-zone.svg';
import { getObstacleAlerts } from '../../../store/selectors/mining/mining.selectors';
import useStyles from './AdasAlertsDisplay.css';
import { getToasterHazardIcon } from '../../../core/services/icons/toasterHazardIcons';
import { config } from '../../../services/config';

const obstacleMinSeverityToAlert = parseInt(process.env.REACT_APP_OBSTACLE_MINIMUM_SEVERITY_TO_ALERT as string);


interface IProps {
	zoneAlert: boolean
	laneAlert: boolean
	noAlerts: boolean
	obstacleAlerts: any
	isGoingReverse: boolean
}

const AdasAlertsDisplay = (props: IProps) => {
	const classes = useStyles();
	const obstacleColorClasses = [
		classes.obstacleSafeColor,
		classes.obstacleLowColor,
		classes.obstacleMediumColor,
		classes.obstacleHighColor
	];

	const laneMessage = 'You just crossed a lane';
	const zoneMessage = 'You are in a restricted zone';
	const noAlertsMessage = 'Editing (alerting is off)';

	let zoneAlert;
	if (props.zoneAlert) {
		zoneAlert = (
			<div className="alert-item alert-rectangle zone-alert-color">
				<img className='zone-alert-icon' src={ZoneAlertIcon} />
				<div className='zone-alert-text'>{zoneMessage.toUpperCase()}</div>
			</div>
		);
	}

	let laneAlert;
	if (props.laneAlert) {
		laneAlert = (
			<div className="alert-item alert-rectangle lane-alert-color">
				<img className='lane-alert-icon' src={LaneAlertIcon} />
				<div className='lane-alert-text'>{laneMessage.toUpperCase()}</div>
			</div>
		);
	}

	let noAlerts;
	if (props.noAlerts) {
		noAlerts = (
			<div className="alert-item-const alert-rectangle no-alerts-color">
				<img className='no-alerts-icon' />
				<div className='no-alerts-text'>{noAlertsMessage.toUpperCase()}</div>
			</div>
		);
	}

	const obstacleLabels = config.hazardAndSafetyLabels.hazards;
	const obstacles = [];
	let tmpObstacle;
	props.obstacleAlerts.forEach((obstacle, key)=>{
		if(obstacle.severity < obstacleMinSeverityToAlert) return;
		let label= obstacle.obstacleType !==0 || obstacle.obstacleDescription === '' ? obstacleLabels[obstacle.obstacleType] : obstacle.obstacleDescription;
		const obstacleDescription = label || obstacleLabels[0];
		const obstacleIcon = getToasterHazardIcon(obstacle.obstacleType,obstacle.severity);
		tmpObstacle = (
		<div className={`alert-item alert-rectangle ${obstacleColorClasses[obstacle.severity]}`} key={key}>
			<img className='zone-alert-icon' src={obstacleIcon} />
			<div className='obstacle-alert-text'>{obstacleDescription.toUpperCase()}</div>
		</div>
		);
		// @ts-ignore
		obstacles.push(tmpObstacle);
	});

	return (
		<div className='alert-container'>
			{!props.isGoingReverse && zoneAlert}
			{!props.isGoingReverse && laneAlert}
			{!props.isGoingReverse && noAlerts}
			{!props.isGoingReverse && obstacles}
		</div>
	);
};

const showNoAlertsNotification = process.env.REACT_APP_ALLOW_ALERTS_WHILE_EDITING !== 'true' &&
	process.env.REACT_APP_SHOW_NO_ALERTS_NOTIFICATION === 'true';

const mapStateToProps = (state) => ({
	zoneAlert: state.adasAlerts.zones.size > 0,
	laneAlert: state.adasAlerts.showLaneAlert,
	noAlerts: showNoAlertsNotification && state.adasLayers.adasEditLayer.id,
	isGoingReverse: state.mining.isGoingReverse,
	obstacleAlerts: getObstacleAlerts(state),
});

export default connect(mapStateToProps)(AdasAlertsDisplay);
