import AdasLayersService from './adas-layers.service';
import { AdasLayerStylesEnum } from '../../../definition/adas-layer-styles-enum';

class AdasLayersProvider {
	async provideAdasLayers() {
		try {
			return await AdasLayersService.getAdasLayers();
		} catch (err) {
			console.error('provideAdasLayers: ', err);
			return [];
		}
	}

	async createNewAdasLayer(title: string): Promise<string> {
		try {
			return await AdasLayersService.createNewAdasLayer(title);
		} catch (err) {
			console.error('createNewAdasLayer: ', err);
			return '';
		}
	}

	async changeAdasLayerTitle(layerId: string, title: string): Promise<any> {
		try {
			return await AdasLayersService.changeAdasLayerTitle(layerId, title);
		} catch (err) {
			console.error('changeAdasLayerTitle: ', err);
			return null;
		}
	}

	async deleteAdasLayer(layerId: string): Promise<boolean> {
		try {
			return await AdasLayersService.deleteLayer(layerId);
		} catch (err) {
			console.error('deleteAdasLayer: ', err);
			return false;
		}
	}

	async editFeatureStyle(layerId: string, featureId: string, style: AdasLayerStylesEnum) {
		try {
			await AdasLayersService.editFeatureStyle(layerId, featureId, style);
		} catch (err) {
			console.error('editFeatureStyle: ', err);
		}
	}

	async deleteAdasFeature(layerId: string, featureId: string): Promise<boolean> {
		try {
			return await AdasLayersService.deleteFeature(layerId, featureId);
		} catch (err) {
			console.error('deleteAdasFeature: ', err);
			return false;
		}
	}
}

export default new AdasLayersProvider();
