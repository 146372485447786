import { makeStyles } from '@material-ui/core/styles';

interface Props{
	leftStatusBar: boolean;
	isShowConnectedBar: boolean;
}


export default makeStyles({
	miniMap:{
		position: 'fixed',
		bottom: 30,
		right: 50,
		zIndex: 51,
	},
	// @ts-ignore
	statusBar:({leftStatusBar}:Props) => {
		const style = {
			position: 'fixed',
			bottom: 0,
		};
		if(leftStatusBar){
			style['left'] = 100;
		}else{
			style['right'] = 0;
		}
		return style;
	},
	// @ts-ignore
	logoutBar:({leftStatusBar, isShowConnectedBar}:Props) => {
		const style = {
			position: 'fixed',
			bottom: isShowConnectedBar ? 29 : 0,
		};
		if(leftStatusBar){
			style['left'] = 100;
		}else{
			style['right'] = 0;
		}
		return style;
	}
});
