import { makeStyles } from '@material-ui/core/styles';
import profileConfig from '../../../core/profiles/profileConfig';

const flipReverseView = profileConfig().flipSafeUnloadingScreen;

export default makeStyles({
	root: {
		display: 'grid',
		gridTemplateColumns: 'auto 400px 400px 400px auto',
		position: 'fixed',
		zIndex: 1,
		width: '100%',
		height: '68px',
		'@media (max-width: 1200px)': {
			gridTemplateColumns: 'auto 300px 300px 300px auto',
		},
		'@media (max-width: 650px)': {
			gridTemplateColumns: '10px 1fr 1fr 1fr 10px',
			justifyItems: 'center',
		},
	},
	arrow:{
		position: 'fixed',
		left: 'calc(50% - 5px)',
		transform: `rotate(${flipReverseView?270:90}deg)`,
		width:600,
		height:10,
	},
	reverseScreenLogo:{
		position:'fixed',
		bottom:10,
		left: 10,
		width:75,
	}
});
