import { Subject } from "rxjs";
import { tap, pairwise, auditTime, filter, map } from "rxjs/operators";
import { BBox, lineString, lineIntersect, polygon, booleanPointInPolygon } from '@turf/turf';
import { getCoord } from '@turf/invariant';
import bbox from '@turf/bbox';
import { LineString, Point, Polygon } from 'geojson';
import olMapService from '../services/ol-map.service';
import { getAdasLayersMap$, getAdasEditLayerId$, getFeatureId } from '../../features/adas-layers/selectors/adas-layers.selectors';
import { IAdasLayer, IAdasLayersMap } from '../../features/adas-layers/reducers/adas-layers.reducer';
import VectorLayer from 'ol/layer/Vector';
import * as proj from 'ol/proj'
import Feature from 'ol/Feature';
import Geometry from 'ol/geom/Geometry';
import GeomType from 'ol/geom/GeometryType';
import { hideLaneAlert, addLaneAlert, removeLaneAlert, addZoneAlert, removeZoneAlert } from "../../features/adas-alerts/actions/adas-alerts.actions";
import { store } from '../../index';
import { getAlertedZones, getAlertedLanes } from "../../features/adas-alerts/selectors/adas-alerts.selectors";
import AdasLayersStyles from './layers/styles/adas-layers-styles'
import { AdasLayerStylesEnum } from '../../definition/adas-layer-styles-enum';
import VectorSource from 'ol/source/Vector';
import { Coords } from '../../definition/simple-types';

class AdasAlertService {
    private _positions: Subject<any>;
    private _isLayersRetrivalInitialized: boolean;
    private _visibleVectorSources: VectorSource[] | [];
    private _allowAlertsWhileEditing: boolean;
    private _isInLayerEdit: boolean;

    constructor() {
        this._allowAlertsWhileEditing = process.env.REACT_APP_ALLOW_ALERTS_WHILE_EDITING === 'true';
        this._isInLayerEdit = false;
        this._isLayersRetrivalInitialized = false;
        this._positions = new Subject<Coords[]>();
	    this._visibleVectorSources = [];
        this.initializeLaneTesting();
        this.initializeZoneTesting();
        if (!this._allowAlertsWhileEditing) {
            setTimeout(() => this.initializeClearAlertsOnEdit(), 100);
        }
    }

    addLatestSensorPosition(point: Feature<Point>): void {
        this._positions.next(getCoord(point));
    }

    private initializeClearAlertsOnEdit() {
        getAdasEditLayerId$().pipe(
            tap(x => this._isInLayerEdit = Boolean(x)),
            filter(x => Boolean(x)),
            tap(x => this.clearAlerts())
        ).subscribe();
    }

    private clearAlerts() {
        //Clear lane alerts (including feature)
        const alertedLanes = Array.from(getAlertedLanes());
        alertedLanes.forEach(x => this.removeLaneAleryById(x));

        //Clear zone alerts (including feature)
        const alertedZones = Array.from(getAlertedZones());
        alertedZones.forEach(x => this.removeZoneAlert(x));
    }

    private initializeAdasLayersRetrieval(): void {
        this._isLayersRetrivalInitialized = true;
        getAdasLayersMap$().pipe(
            tap((layersMap: Map<string, IAdasLayersMap>) => this.updateVisibleAdasLayers(layersMap))
        ).subscribe();
    }

    private initializeLaneTesting(): void {
        this._positions.pipe(
            pairwise(),
            // auditTime(parseInt(process.env.REACT_APP_LANE_AUDIO_TIME as string, 10)),
            filter(x => !this._isInLayerEdit),
            tap((points: Coords[])  => this.testLaneAlert(points))
        ).subscribe();
    }

    private initializeZoneTesting(): void {
        this._positions.pipe(
            auditTime(parseInt(process.env.REACT_APP_ZONE_AUDIO_TIME as string, 10)),
            filter(x => !this._isInLayerEdit),
            tap((point: Coords) => this.testZoneAlert(point))
        ).subscribe();
    }

    private updateVisibleAdasLayers(layers: Map<string, IAdasLayersMap>): void {
        if(!layers) return;
        //TODO check if can be shorter
        const adasLayers: IAdasLayer[] = [];
        for (const [key, value] of layers.entries()) {
            adasLayers.push({ ...value, id: key });
        }
        const visibleAdasLayers: IAdasLayer[] = adasLayers.filter(vector => vector.isDisplay);
        const vectors: VectorLayer[] = visibleAdasLayers.map(vector => olMapService.getVectorLayerById(vector.id)).filter(layer => !!layer);
        this._visibleVectorSources = vectors.length === 0 ? [] : olMapService.getVisibleVectorSources(vectors);
    }

    private getVisibleVectorSources(): VectorSource[] {
        if (!this._isLayersRetrivalInitialized) {
            this.initializeAdasLayersRetrieval();
        }
        return this._visibleVectorSources;
    }

    private getFeaturesInLineExtent(line: Feature<LineString>, vectorSources: VectorSource[]): Array<Feature<Geometry>> {
        const extent: BBox = bbox(line);
        const transformedExtent = proj.transformExtent(extent, 'EPSG:4326', 'EPSG:3857');
        const features: Array<Feature<Geometry>> = vectorSources.map(source =>
            source.getFeaturesInExtent(transformedExtent)).flatMap(feature => feature.flat());
        return features;
    }

    private testLaneAlert(points: Coords[]): void {
        const vectorSources: VectorSource[] = this.getVisibleVectorSources();
        if (!vectorSources) {
            return;
        }
        const sensorLine: Feature<LineString> = lineString(points);
        const features: Array<Feature<Geometry>> = this.getFeaturesInLineExtent(sensorLine, vectorSources);

        if (features.length === 0) {
            return;
        }
        // Check for first line intersecting feature
        features.find((feature: Feature) => this.testLineIntersection(sensorLine, feature));
    }

    private testLineIntersection(sensorLine: Feature<LineString>, feature: Feature<Geometry>): boolean {
		if (!feature) {
			return false;
		}
        const geom: Geometry = feature.getGeometry();
        if (geom.getType() !== GeomType.LINE_STRING) {
            return false;
        }
        const projectedCoordinates: Coords[] = feature.getGeometry().getCoordinates().map((coordinate: Coords) =>
            proj.transform(coordinate, 'EPSG:3857', 'EPSG:4326'));
        const featureLineString: Feature<LineString> = lineString(projectedCoordinates);
        const intersections = lineIntersect(sensorLine, featureLineString);

        if (intersections.features.length === 0) {
			return false;
		}

        const featureId: string = feature.getId();
        store.dispatch(<any>hideLaneAlert());
        store.dispatch(<any>addLaneAlert({ id: featureId }));
        feature.setStyle(AdasLayersStyles.getLayerStyle(AdasLayerStylesEnum.CROSS));
        setTimeout(() => this.removeLaneAlert(feature), parseInt(process.env.REACT_APP_LANE_ALERT_TIME_LENGTH as string, 10));
        return true;
    }

    private removeLaneAleryById(featureId: string): void {
        const idParts: string[] = featureId.split('.');
        const feature: Feature<Geometry> = olMapService.getFeatureById(idParts[0], idParts[1]);
        this.removeLaneAlert(feature);
    }

    private removeLaneAlert(feature: Feature<LineString>): void {
        if (!feature) {
            return;
        }

        this.checkEditFeatureMode(feature);

        const featureId: string = feature.getId();
        store.dispatch(<any>removeLaneAlert({ id: featureId }));
    }

    private testZoneAlert(point: Coords) {
        const vectorSources: VectorSource[] = this.getVisibleVectorSources();
        if (!vectorSources) {
            return;
        }
        const sensorLine: Feature<LineString> = lineString([point, point]);
        const features: Array<Feature<Geometry>> = this.getFeaturesInLineExtent(sensorLine, vectorSources);

        const alertedZones = new Set(getAlertedZones());

        // Find or verify alerted zones
        features.forEach((feature: Feature<Geometry>) => {
            if (this.testZoneIntersection(point, feature)) {
                const featureId = feature.getId();
                // Check if new zone
                if (!alertedZones.delete(featureId)) {
                    store.dispatch(<any>addZoneAlert({ id: featureId }));
                    feature.setStyle(AdasLayersStyles.getLayerStyle(AdasLayerStylesEnum.CROSS));
                }
            }
        });
        // Remove non-alerted zones
        Array.from(alertedZones).forEach((featureId: string) => {
            this.removeZoneAlert(featureId);
        });
    }

    private testZoneIntersection(point, feature: Feature<Geometry>): boolean {
        const geom = feature.getGeometry();
        if (geom.getType() !== GeomType.POLYGON) {
            return false;
        }
        const projectedCoordinates: Coords[] = feature.getGeometry().getCoordinates()[0].map((coordinate: Coords) =>
            proj.transform(coordinate, 'EPSG:3857', 'EPSG:4326'));
        const featurePolygon: Feature<Polygon> = polygon([projectedCoordinates]);
        const isInPolygon: boolean = booleanPointInPolygon(point, featurePolygon);
        return isInPolygon;
    }

    private removeZoneAlert(featureId: string): void {
        const idParts: string[] = featureId.split('.');
        const feature: Feature<Geometry> = olMapService.getFeatureById(idParts[0], idParts[1]);
        if (feature) {
			feature.setStyle(null);
        }

		this.checkEditFeatureMode(feature);

        store.dispatch(<any>removeZoneAlert({ id: featureId }));
    }

    private checkEditFeatureMode(feature: Feature) {
        if (!feature) {
            return;
        }
        const editFeatureId = getFeatureId();
        const currentFeatureId = feature.getId().split('.')[1];
		if (editFeatureId &&  currentFeatureId === editFeatureId) {
			feature.setStyle(AdasLayersStyles.getLayerStyle(AdasLayerStylesEnum.EDIT));
		} else {
			olMapService.clearSelection();
			feature.setStyle(null);
		}
    }
}

export default new AdasAlertService();
