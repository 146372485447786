import { Style, Icon } from 'ol/style';
import { config } from '../../../../services/config';
import {
	cartesian,
	generateSafetyIcon,
	selectedOptions,
	severityOptions,
} from '../../../../services/icons-generator-service/icons-generator-service';

const styleCache: any = [];

const eventIconStyle = (isSelected, eventType, severity) => {
	const svg = generateSafetyIcon(eventType, isSelected, severity);

	return new Style({
		image: new Icon({
			anchor: [0.5, 0.5],
			src: svg,
			// size: [30,30]
			scale: 1,
		}),
	});
};

const getCacheKey = (selected, eventType, severity) => {
	return `${selected}|${eventType}|${severity}`;
};

const styleFunction = selected => feature => {
	const eventType = feature.values_.typeOfEvent || 0;
	const severity = feature.values_.severity || 0;
	try {
		const styleId = getCacheKey(selected, eventType, severity);
		if (!styleCache[styleId]) {
			styleCache[getCacheKey(true, eventType, severity)] = eventIconStyle(
				true,
				eventType,
				severity
			);
			styleCache[
				getCacheKey(false, eventType, severity)
			] = eventIconStyle(selected, eventType, severity);
		}
		return styleCache[styleId];
	} catch {
		return eventIconStyle(0, 0, 0);
	}
};

export const generateCache = () => {
	const eventTypeOptions = Object.keys(config.hazardAndSafetyIcons.safeties);
	cartesian(selectedOptions, eventTypeOptions, severityOptions).forEach(
		([selected, eventType, severity]) => {
			styleCache[
				getCacheKey(selected, eventType, severity)
			] = eventIconStyle(selected, eventType, severity);
		}
	);
};

export default {
	selected: styleFunction(true),
	unselected: styleFunction(false),
};
