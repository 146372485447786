import { store } from '../../index';
import { config } from '../../services/config';
import { setCurrentPage } from '../../store/actions/core.actions';
import { setSites } from '../../store/actions/mining.actions';
import { startListener } from '../effects/core.effects';
import { getSites } from '../services/minecept/mineceptServer';

export const onLogIn = async () => {
	if (config.isMultiSite) {
		const sitesData = await getSites();
		const existingSites = store.getState().mining.multiSite.sites;
		if (existingSites && existingSites.length > 0) {
			return;
		}
		if (sitesData) {
			const page = getPageFromUrl();
			store.dispatch(setCurrentPage({ page }));

			const siteFromUrl = getSiteFromUrl();
			const selectedSite = isSiteIdValid(siteFromUrl, sitesData.sites) 
				? siteFromUrl : sitesData.defaultSite;
			store.dispatch(setSites({
				sites: sitesData.sites,
				selectedSite
			}));
		} else {
			console.warn('Failed to get sites list, trying again in one second');
			setTimeout(() => onLogIn(), 1000);
			return;
		}
	}
	startListener();
};

const getSiteFromUrl = () => {
	const queryString = window.location.search;
	const params = new URLSearchParams(queryString);
	return params.get('site');
};

const getPageFromUrl = () => {
	return window.location.pathname.substring(1).toLowerCase();
}

const isSiteIdValid = (siteId, sites) => {
	return sites.some(x => x.id === siteId);
}
