import React from 'react';

const videoContext = React.createContext({
    currentTime: 0,
    lastPlayedEventTime: 0,
    isPlayed: true,
    isPlayedChange: (value: boolean): void => {
    },
    currentTimeChange: (value: number): void => {
    },
    lastPlayedEventTimeChange: (value: number): void => {
    },
    clearContext: (): void => {
    },
});
export default videoContext;
