import { IAdasLayer, IEditAdasLayer } from '../reducers/adas-layers.reducer';

export enum adasLayersActionTypes {
	ADD_ADAS_LAYERS = '[AdasLayer] add ADAS layers to the display list',
	REMOVE_ADAS_LAYER = '[AdasLayer] remove ADAS layer from the display list',
	TOGGLE_ADAS_LAYER_DISPLAY = '[AdasLayer] toggle ADAS layer display',
	CREATE_NEW_ADAS_LAYER = '[AdasLayer] create new ADAS layer on geoserver and in postgis database',
	EDIT_ADAS_LAYER = '[AdasLayer] update the selected ADAS layer to be edited',
	CHANGE_LAYER_TITLE = '[AdasLayer] change the ADAS layer title on Geoserver',
	EDIT_FEATURE = '[AdasLayer] update the selected Feature ID to be edited',
}

export const addAdasLayers = (adasLayers: IAdasLayer[]) => (dispatch) => {
	dispatch({
		type: adasLayersActionTypes.ADD_ADAS_LAYERS,
		payload: adasLayers
	});
};

export const removeAdasLayer = (adasLayerKey: string) => (dispatch) => {
	dispatch({
		type: adasLayersActionTypes.REMOVE_ADAS_LAYER,
		payload: adasLayerKey
	});
};

export const toggleAdasLayerDisplay = (adasLayerKey: string, isDisplay: boolean) => (dispatch) => {
	dispatch({
		type: adasLayersActionTypes.TOGGLE_ADAS_LAYER_DISPLAY,
		payload: {
			adasLayerKey,
			isDisplay
		}
	});
};

export const editAdasLayer = (adasLayer: IEditAdasLayer) => (dispatch) => {
	dispatch({
		type: adasLayersActionTypes.EDIT_ADAS_LAYER,
		payload: adasLayer
	});
};

export const changeLayerTitle = (adasLayerKey: string, title: string) => (dispatch) => {
	dispatch({
		type: adasLayersActionTypes.CHANGE_LAYER_TITLE,
		payload: {
			adasLayerKey,
			title
		}
	});
};

export const editFeature = (featureId: string) => (dispatch) => {
	dispatch({
		type: adasLayersActionTypes.EDIT_FEATURE,
		payload: featureId
	});
};



