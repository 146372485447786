import { makeStyles } from '@material-ui/core/styles';


export default makeStyles({
	card: {
		width: 305,
		height: 64,
		display: 'grid',
		gridTemplateColumns: '60px auto',
		gridTemplateRows: '1fr 1fr',
		backgroundColor: '#ffffff',
		borderRadius: 9,
		boxShadow: '0 2px 4px 0 rgba(184, 173, 173, 0.5)'
	},
	symbol: {
		gridColumn: '1 / 2',
		gridRow: '1 / 3',
		width: 35,
		height: 35,
		justifySelf: 'center',
		alignSelf: 'center',
		margin: 9
	},
	label: {
		gridColumn: '2 / 3',
		gridRow: '1 / 2',
		fontFamily: 'Mukta Mahee',
		fontSize: 18,
		fontWeight: 'bold',
		alignSelf: 'end',
		lineHeight: 0.8,
	},
	time: {
		gridColumn: '2 / 3',
		gridRow: '2 / 3',
		fontFamily: 'Mukta Mahee',
		fontSize: 14,
		fontWeight: 'normal',
		alignSelf: 'start',
	},
});
