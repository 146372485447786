import classNames from 'classnames';
import React, {Component} from 'react';
import {connect} from 'react-redux';
// import Switch from 'react-switch';
import {
    IToggleViewState,
    toggleDotsView,
    toggleGradientLineView,
    toggleHeadHeatMapView,
    toggleSensorRouteVisibility,
    toggleStraightLineView
} from '../actions/sensors.actions';
import {ISensorMetadataState} from '../reducers/sensors.reducer';
import './SensorItemRaw.scss';

interface IProps {
    sensor: ISensorMetadataState;
    toggleDotsView: (payload: IToggleViewState) => void;
    toggleGradientLineView: (payload: IToggleViewState) => void;
    toggleHeadHeatMapView: (payload: IToggleViewState) => void;
    toggleStraightLineView: (payload: IToggleViewState) => void;
    toggleSensorRouteVisibility;
}

class SensorItemRaw extends Component<IProps, void> {
    constructor(props) {
        super(props);
    }

    getClassNames() {
        return classNames({
            ...this.props.sensor.displayFlags,
            'sensor-display-flags': true
        });
    }

    toggleDisplayType(displayType) {
        if (!this.props.sensor.displayFlags.visible) {
            return;
        }
        const sensorType = this.props.sensor.sensorType;
        switch (displayType) {
            case 'dots':
                return this.props.toggleDotsView({sensorType});
            case 'straight':
                return this.props.toggleStraightLineView({sensorType});
            case 'gradient':
                return this.props.toggleGradientLineView({sensorType});
            case 'head-heatmap':
                return this.props.toggleHeadHeatMapView({sensorType});
        }
    }

    handleChange() {
        this.props.toggleSensorRouteVisibility({sensorType: this.props.sensor.sensorType});
    }

    traceOptions() {
        const traceModes = process.env.REACT_APP_ALLOWED_TRACE_MODES as string;
        if (!traceModes) return;
        else {
            const traceModesArray = traceModes.split(',').map(item=>item.toLowerCase().trim()).filter(item => Boolean(item));
            const optionsToShow = traceModesArray.map(mode => (
                <div className={`display-box toggle-${mode}`} key={`track-${mode}`} onClick={() => this.toggleDisplayType(mode)}>
                    <span className={`icon-track-${mode}`}/>
                </div>));
            return optionsToShow;
        }
    }

    render() {
        return (
            <div className='sensor-display'>
                <header>
                    {/*<h2>{this.props.sensor.name}</h2>*/}
                    <h2>Trace</h2>
                    {/*<Switch*/}
                    {/*	onChange={this.handleChange.bind(this)}*/}
                    {/*	checked={this.props.sensor.displayFlags.visible}*/}
                    {/*	onColor='#307c9e'*/}
                    {/*	onHandleColor='#3ac5f2'*/}
                    {/*	handleDiameter={25}*/}
                    {/*	uncheckedIcon={false}*/}
                    {/*	checkedIcon={false}*/}
                    {/*	height={20}*/}
                    {/*	width={48}*/}
                    {/*	className='react-switch'*/}
                    {/*/>*/}
                </header>
                <div className={this.getClassNames()}>
                    {/*<div className='display-box toggle-dots' onClick={() => this.toggleDisplayType('dots')}>
                        <span className='icon-track-dots'/>
                    </div>
                        <div className='display-box toggle-straight' onClick={() => this.toggleDisplayType('straight')}>
                        <span className='icon-track-straight' />
                        </div>*/}
                    {this.traceOptions() }
                    {/*<div className='display-box toggle-gradient disabled'>*/}
                    {/*	<span className='icon-track-gradient ' />*/}
                    {/*</div>*/}
                    {/*<div className='display-box toggle-header-heat-map disabled'>*/}
                    {/*	<div className='heat-map-icon-wrapper'>*/}
                    {/*		<span className='icon-heatmap-cursor'>*/}
                    {/*			<span className='path2' />*/}
                    {/*		</span>*/}
                    {/*	</div>*/}
                    {/*</div>*/}
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = {
    toggleDotsView,
    toggleGradientLineView,
    toggleHeadHeatMapView,
    toggleStraightLineView,
    toggleSensorRouteVisibility
};

export default connect(null, mapDispatchToProps)(SensorItemRaw);
