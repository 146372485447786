import React, { Component } from 'react';
import { connect } from 'react-redux';
import './StatusBar.scss';
import StatusItem from '../status-item/StatusItem';
import { ISensorMetadataState, ISensorState } from '../../reducers/sensors.reducer';

interface IProps {
	sensors: ISensorMetadataState
}

class StatusBar extends Component<IProps, void> {

	constructor(props) {
		super(props);
	}

	render() {

		return (
			<div className="status-bar">
				<ul>
					{Object.values(this.props.sensors).map(sensor =>
						<li key={sensor.sensorType}>
							<StatusItem key={sensor.sensorType} sensor={sensor}/>
						</li>
					)}
				</ul>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	sensors: state.sensors.sensors
});

export default connect(mapStateToProps)(StatusBar);
