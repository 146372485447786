import { makeStyles } from '@material-ui/core/styles';
import profileConfig from '../../../../core/profiles/profileConfig';

interface Props{
	start:number
	end:number
	isAlert:boolean
}
export default makeStyles({
// @ts-ignore
	root: (props:Props) =>{
		const style ={
			zIndex: 0,
			position: 'absolute',
			width: 2,
			height: props.end - props.start,
			left: `calc(50% - 1px)`,
			border: `1px solid ${props.isAlert? '#ff6665' : '#91fc9a'}`
		}
		if(profileConfig().flipSafeUnloadingScreen){
			return {...style,
				bottom: props.start
			}
		}
		return {...style,
			top: props.start
		}
	},
});
