import { ISensorMetadataState, ISensorState } from '../reducers/sensors.reducer';
import { selector } from '../../../index';

export const getSensor = (sensorType): ISensorMetadataState => {
	const sensors: ISensorState = selector('sensors.sensors');
	return sensors[sensorType];
};

export const isAllSensorsOff =  (): boolean => {
	const sensors: ISensorState = selector('sensors.sensors');
	let isAllSensorsOff = true;
	
	for(let sensor in sensors){
		if (sensors[sensor].displayFlags.visible){
			isAllSensorsOff = false;
		}
	}

	return isAllSensorsOff;
}
