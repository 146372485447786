import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import bermStyleFunction from './styles/bermLayer-styles';
import obstacleLayerStyle from './styles/obstacleLayer-styles';
import safetyEventLayerStyle from './styles/safetyEventLayer-styles';
import vehicleLayerStyle from './styles/vehicleLayerStyle';
import GeoJSON from 'ol/format/GeoJSON';
import Point from 'ol/geom/Point';
import Feature from 'ol/Feature';
import { convertWgs84ToWebMercator } from '../projections';
import { MineceptLayers } from '../../../definition/mineceptLayers';
import profileConfig from '../../profiles/profileConfig';
import { FeatureType } from '../../../definition/Enums';
import { Dictionary } from '../../../interfaces/Dictionary';

const NO_SIGNAL_TIME = parseInt(process.env.REACT_APP_VEHICLE_NO_SIGNAL_TIME as string)*1000;

export const layerList = {};

if(profileConfig().listenToMineceptSensor){
	layerList[MineceptLayers.obstacles]={
		style: obstacleLayerStyle.unselected,
		zIndex: 499,
	};
	if(profileConfig().includeSafeUnloadingScreen){
		layerList[MineceptLayers.berm]={
			style: bermStyleFunction,
			zIndex: 500
		};
	}
}
if(profileConfig().showAllSiteVehicles){
	layerList[MineceptLayers.vehicles]={
		style: vehicleLayerStyle.unSelected,
		zIndex: 550,
		title: 'Heavy Machines'
	};
}

if(profileConfig().showHazards){
	layerList[MineceptLayers.hazards]={
		style: obstacleLayerStyle.unselected,
		zIndex: 502,
		title: 'Hazards'
	};
}

if(profileConfig().showSafetyEvents){
	layerList[MineceptLayers.safetyEvents]={
		style: safetyEventLayerStyle.unselected,
		zIndex: 503,
		title: 'Events',
	};
}

export const layerTitles = Object.keys(layerList).filter(layerId=>!!layerList[layerId].title).map(id=>({id, title:layerList[id].title}));

interface SourceMap {
	[id: string]: VectorSource
}

interface LayerMap {
	[id: string]: VectorLayer
}

class SensorVectorLayerProvider {
	private geoJson = new GeoJSON();
	private sources:SourceMap = {};
	private layers:LayerMap = {};

	constructor() {
		Object.keys(layerList).forEach(layerId=>{
			this.sources[layerId] = new VectorSource();
			this.layers[layerId] = new VectorLayer({
				renderMode: 'image',
				source: this.sources[layerId],
				style:layerList[layerId].style,
				zIndex: layerList[layerId].zIndex
			});
		});
	}

	getLayers(): VectorLayer {
		return Object.values(this.layers);
	}

	clearLayerData(layerId):void {
		if(!this.sources[layerId]) return;
		this.sources[layerId].clear();
	}

	updateLayerRelativeData(layerId,geoJsonObject): void {
		if(!this.sources[layerId]) return;
		this.sources[layerId].clear();
		this.sources[layerId].addFeatures(
			this.geoJson.readFeatures(geoJsonObject)
		);
	}

	updateLayerData(layerId, geoJsonObject): void {
		if(!this.sources[layerId]) return;
		this.sources[layerId].clear();
		this.sources[layerId].addFeatures(
			this.geoJson.readFeatures(geoJsonObject, {
				dataProjection: 'EPSG:4326',
				featureProjection: 'EPSG:3857'
			})
		);
	}

	addFeaturesToLayer(layerId,geoJsonObject): void {
		if(!this.sources[layerId]) return;
		this.sources[layerId].addFeatures(
			this.geoJson.readFeatures(geoJsonObject, {
				dataProjection: 'EPSG:4326',
				featureProjection: 'EPSG:3857'
			})
		);
	}

	getFeatureById(layerId,featureId): any {
		if(!this.sources[layerId]) return;
		const features = this.sources[layerId].getFeatures();
		const feature = features.filter(f=>f.getProperties().id === featureId);
		if (feature.length) return feature[0];
		return null;
	}

	removeFeatureFromLayer(layerId, featureId): void {
		if(!this.sources[layerId]) return;
		const featureToRemove = this.sources[layerId].getFeatures().filter(feature=>feature.values_.id === featureId);
		if(featureToRemove.length>0){
			this.sources[layerId].removeFeature(featureToRemove[0]);
		}
	}

	removeFeaturesFromLayerByParam(layerId, param, value): void {
		if(!this.sources[layerId]) return;
		const featureToRemove = this.sources[layerId].getFeatures().filter(feature=>feature.values_[param] === value);
		if(featureToRemove.length>0){
			this.sources[layerId].removeFeature(featureToRemove[0]);
		}
	}

	removeFeaturesFromLayerByIds(layerId, feature_ids:Dictionary<any>): Dictionary<any>|undefined {
		if(!this.sources[layerId]) return;
		const featureIds = {...feature_ids};
		const featuresToRemove = this.sources[layerId].getFeatures().filter(feature=>{
			if(!featureIds[feature.values_.id]) return true;
			delete featureIds[feature.values_.id];
		});
		featuresToRemove.forEach(feature=>{
			this.sources[layerId].removeFeature(feature);
		});
		return featureIds;
	}

	hideLayer(layerId):void{
		if(!this.layers[layerId])return;
		this.layers[layerId].setVisible(false);
	}

	showLayer(layerId):void{
		if(!this.layers[layerId])return;
		this.layers[layerId].setVisible(true);
	}

	setLayerVisibility(layerId,isVisible):void{
		if(!this.layers[layerId])return;
		this.layers[layerId].setVisible(isVisible);
	}

	updateFeaturePosition(layerId, featureId, isPositionValid, position, properties):void{
		if(!this.layers[layerId])return;
		const existingFeature = this.sources[layerId].getFeatureById(featureId);
		if(existingFeature){
			if (isPositionValid) {
				existingFeature.setProperties({...properties,category: FeatureType.vehicle});
				existingFeature.setGeometry(new Point(convertWgs84ToWebMercator(position)));
			} else {
				// TODO hide instead of remove?
				this.sources[layerId].removeFeature(existingFeature);
			}
			return;
		}
		if (!isPositionValid) {
			return; // TODO hide instead of not create?
		}
		const newFeature = new Feature({
			geometry: new Point(convertWgs84ToWebMercator(position)),
			category: FeatureType.vehicle,
			...properties
		});
		newFeature.setId(featureId);
		this.sources[layerId].addFeature(newFeature);
	}

	updateInactiveProperty(layerId){
		if(!this.layers[layerId])return;
		const features = this.sources[layerId].getFeatures();
		if(!features) return;
		const latestActiveTime = Date.now() - NO_SIGNAL_TIME;
		features.forEach(feature=>{
			feature.setProperties({
				inActive: (feature.getProperties().lastSeen < latestActiveTime)
			}, false);
		});
	}
}

export default new SensorVectorLayerProvider();
