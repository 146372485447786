import { Subject } from 'rxjs';
import { selector$ } from '../../../store/store';
import { IAdasLayersMap, IEditAdasLayer } from '../reducers/adas-layers.reducer';
import { selector } from '../../../index';

export const getAdasLayersMap$ = (): Subject<Map<string, IAdasLayersMap>> => {
	return selector$('adasLayers.adasLayersMap');
};

export const getAdasEditLayer$ = (): Subject<IEditAdasLayer> => {
	return selector$('adasLayers.adasEditLayer');
};

export const getAdasEditLayerId$ = (): Subject<string> => {
	return selector$('adasLayers.adasEditLayer.id');
};

export const getFeatureId$ = (): Subject<string> => {
	return selector$('adasLayers.featureId');
};

export const getFeatureId = (): string => {
	return selector('adasLayers.featureId');
};

