import { makeStyles } from '@material-ui/core/styles';
import { mineceptStatusColors } from '../../../definition/mineceptStatus';

interface Props{
	status:number
}

export default makeStyles({
// @ts-ignore
	root: (props:Props) => {
		const warningColor = mineceptStatusColors[props.status];
		return {
			height: 26,
			backgroundColor: warningColor,
			display: 'flex',
			position: 'relative',
		};
	},
	label: props => ({
		paddingRight: 20,
		paddingLeft: props.status === 0 ? 5 : 20,
		fontFamily: 'Mukta Mahee',
		fontSize: 15,
		fontWeight: 'normal',
		fontStretch: 'normal',
		fontStyle: 'normal',
		lineHeight: 'normal',
		letterSpacing: 'normal',
		color: props.status === 1 ? '#242222' : '#e0dede',
	}),
	dot: {
		paddingTop: 5,
		paddingLeft: 5,
	},
});
