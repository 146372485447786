import profiles from '../../profiles.json';
import defaultProfile from './defaultProfile.json';
const guiType = process.env.REACT_APP_GUI_TYPE;

let appConfig;

export const selectProfile = (profile) =>{
	const selectedConfig = !!profiles[profile] ? profiles[profile] : {};
	//Validation
	selectedConfig.showHazardList = !!selectedConfig.showHazards && !!selectedConfig.showHazardList;
	selectedConfig.showSafetEventList = !!selectedConfig.showSafetyEvents && !!selectedConfig.showSafetEventList;
	appConfig = {...defaultProfile,...selectedConfig};
}

selectProfile(guiType);
const getSelectedConfig = () => appConfig;

export default getSelectedConfig;
