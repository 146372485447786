import { makeStyles } from '@material-ui/core/styles';
import { getSeverityColors } from '../../../definition/severity';

interface Props{
	severity:number
}

export default makeStyles({
	root: (props:Props) => {
		const warningColor = getSeverityColors(props.severity).icon;
		return {
			display: 'flex',
			position: 'absolute',
			justifyContent: 'space-between',
			// left: Math.max(props.position[0] - 100, 0),
			// top: Math.max(props.position[1] - 60, 0),
			left: 33,
			bottom: 51,
			zIndex: 1,
			// width: 200,
			height: 80,
			fontSize: 26,
			fontFamily: 'Mukta Mahee',
			backgroundColor: '#0f2365',
			borderRadius: 6,
			borderLeft: `21px solid ${warningColor}`,
			padding: 5,
			paddingLeft: 12,
			paddingTop:20,
			textAlign: 'center',
			color: warningColor,
			margin: 'auto 5px',
		};
	},
	closeButton: {
		color: 'white',
		cursor: 'pointer',
		paddingRight: 30,
		paddingTop: 5,
	},
	closeSymbol:{
		width:23,
	},
	label:{
		paddingRight:20,
		paddingLeft:5
	}
});
