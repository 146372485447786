import classNames from 'classnames';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import SensorItemRaw from '../sensor-item-row/SensorItemRaw';
import './SensorPanel.scss';
import enhanceWithClickOutside from 'react-click-outside';
import { setActiveMenu } from '../../../store/actions/core.actions';
import { ISensorState } from '../reducers/sensors.reducer';


interface IProps {
	sensors: ISensorState,
    display: boolean,
    setActiveMenu: (string) => void,
    parentClass: string,
    menuClass: string
}

class SensorPanel extends Component<IProps, void> {
	constructor(props) {
		super(props);
	}

	getClassName() {
		return classNames({
			'sensor-panel-container': true,
			'visible': this.props.display,
			'panel-container': true
		});
    }

    handleClickOutside(event) {
        if(event.target.closest(`.${this.props.parentClass}`) ||
        event.target.classList.contains(this.props.parentClass)){
            return;
        }

        if(event.target.closest(`.${this.props.menuClass}`) ||
        event.target.classList.contains(this.props.menuClass)){
            return;
        }

        if(!this.props.display){
            return;
        }

		this.props.setActiveMenu('');
	}

	render() {
		return (
			<div className={this.getClassName()}>
				{Object.values(this.props.sensors).map(sensor =>
					<SensorItemRaw key={sensor.sensorType} sensor={sensor}/>
				)}
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	sensors: state.sensors.sensors
});

const mapDispatchToProps = {
	setActiveMenu
};

export default connect(mapStateToProps, mapDispatchToProps)(enhanceWithClickOutside(SensorPanel));
