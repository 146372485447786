export const limitStringLength = (str, len) =>{
	if(str.length < len) return str;
	return `${str.substr(0, len-3)} ...`;
};

export const titleCase = (str) => str.replace(
	/\w\S*/g,
	function(txt) {
		return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
	}
);
