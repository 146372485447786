import uuidv4 from 'uuid/v4';
import { store } from '../../index';
import { addNewFeature, IFeaturePoint } from '../effects/route.effects';

const updateInterval = parseInt(process.env.REACT_APP_GPS_UPDATE_INTERVAL as string);

const formatGpsToPoint = (positionObject) => {
	return {
		geometry: { type: 'Point',
			coordinates: [positionObject.longitude, positionObject.latitude] },
		properties: {
			rotation: positionObject.heading || 0,
			sensorType: 'sl',
			id: uuidv4()
		},
		type: 'Feature',
	};
};

const updatePosition = (position) =>{
	if(!position.coords) return;
	const point = formatGpsToPoint(position.coords) as IFeaturePoint;
	store.dispatch(addNewFeature({ point }) as any);
};

const getGpsData = () =>{
	if (navigator.geolocation) {
		navigator.geolocation.getCurrentPosition(updatePosition);
	}
}

export const initGPS = () => {
	setInterval(getGpsData, updateInterval);
};
