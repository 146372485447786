import axios from 'axios';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import GeoJSON from 'ol/format/GeoJSON';
import { bbox as bboxStrategy } from 'ol/loadingstrategy';
import { AdasLayerStylesEnum } from '../../../definition/adas-layer-styles-enum';
import Feature from 'ol/Feature';
import Geometry from 'ol/geom/Geometry';
import Select from 'ol/interaction/Select';
import Interaction from 'ol/interaction/Interaction';
import { Draw, Modify, Snap, DoubleClickZoom } from 'ol/interaction';
import olMapService from '../ol-map.service';
import { editAdasLayer, editFeature } from '../../../features/adas-layers/actions/adas-layers.actions';
import { store } from '../../../index';
import { DrawModes } from '../../../definition/draw-modes';
import { IEditAdasLayer } from '../../../features/adas-layers/reducers/adas-layers.reducer';
import AdasLayersStyles from './styles/adas-layers-styles'
import { sendErrorMessage } from '../../../features/error-messages/actions/errorMessages.actions';
import { IErrorSeverity } from '../../../definition/error-enum';
import uuidv4 from 'uuid/v4';

class AdasLayersService {

	adasBaseUrl = process.env.REACT_APP_ADAS_SERVER as string;
	addedInteractions: Interaction[] = [];

	setLayerStyle(id: string, style: AdasLayerStylesEnum) {
		const layer = olMapService.getVectorLayerById(id);
		if (!layer) {
			return;
		}
		layer.setStyle(AdasLayersStyles.getLayerStyle(style));
	}

	setFeatureStyle(id: string, feature: Feature<Geometry>, style: AdasLayerStylesEnum) {
		if (!feature) {
			return;
		}
		feature.setStyle(AdasLayersStyles.getLayerStyle(style));
	}

	clearPreviousInteractions() {
		if (this.addedInteractions.length > 0) {
			this.addedInteractions.forEach((interaction) => {
				olMapService.removeInteraction(interaction);
			});
			this.addedInteractions = [];
		}
	}

	drawNewFeature(adasEditLayer: IEditAdasLayer) {
		this.clearPreviousInteractions();

		const layer = olMapService.getVectorLayerById(adasEditLayer.id);
		if (!adasEditLayer.id) {
			return;
		}
		const layerName = adasEditLayer.id;

		const draw = new Draw({
			source: layer.getSource(),
			type: adasEditLayer.drawMode
		});
		this.addedInteractions.push(draw);
		olMapService.addInteraction(draw);
		const doubleClickZoom = olMapService.getInteraction(DoubleClickZoom);
		if (doubleClickZoom) {
			console.log('found double click zoom');
			olMapService.removeInteraction(doubleClickZoom);
		}

		draw.on('drawend', (e) => {
			const featureId = uuidv4();
			console.log('New Feature id: ', featureId);
			let errorMessage = {
				severity: IErrorSeverity.WARN,
				summary: 'Save failed',
				detail: 'Please repeat the action'
			};
			this.updateFeature(layerName, e.feature, featureId)
				.then(result => {
					if (result) {
						errorMessage = {
							severity: IErrorSeverity.SUCCESS,
							summary: 'Save succeeded',
							detail: ''
						};
						store.dispatch(editAdasLayer({
							...adasEditLayer,
							style: AdasLayerStylesEnum.HIGHLIGHT,
							drawMode: DrawModes.NONE
						}) as any);
					}
					store.dispatch(sendErrorMessage(errorMessage) as any);
				})
				.catch(err => {
					console.log(err);
					store.dispatch(sendErrorMessage(errorMessage) as any);
				});

			draw.setActive(false);
			olMapService.removeInteraction(draw);
			if (doubleClickZoom) {
				// Adding synchrnous causes zoom
				setTimeout(() => olMapService.addInteraction(doubleClickZoom), 100);
			}
		});

	}

	selectAndModify(adasEditLayer: IEditAdasLayer) {
		this.clearPreviousInteractions();

		const layer = olMapService.getVectorLayerById(adasEditLayer.id);
		const select = new Select({
			wrapX: false,
			layers: [layer],
			hitTolerance: parseInt(process.env.REACT_APP_SELECT_TOLERANCE as string, 10)
		});
		let isModified = false;
		this.addedInteractions.push(select);
		olMapService.addInteraction(select);

		const selectFunction = (e) => {
			if (e.deselected.length > 0) {
				const feature = e.deselected[0];
				if (feature.getId()) {
					if (isModified) {
						const splitId = feature.getId().split('.');
						const layerName = splitId[0];
						const featureId = splitId[1];
						this.updateFeature(layerName, feature, featureId);
					}
					store.dispatch(editFeature('') as any);
					this.setFeatureStyle(feature.getId(), feature, AdasLayerStylesEnum.NONE);
				}
			}
			if (e.selected.length > 0) {
				const feature = e.selected[0];
				if (feature.getId()) {
					const featureId: string = feature.getId().split('.')[1];
					store.dispatch(editFeature(featureId) as any);
					this.setFeatureStyle(feature.getId(), feature, AdasLayerStylesEnum.EDIT);
				}
			}
			isModified = false;
		};

		select.on('select', selectFunction);

		const features = select.getFeatures();

		const modify = new Modify({ features });
		modify.on('modifyend', (e) => {
			isModified = true;
		});
		this.addedInteractions.push(modify);
		olMapService.addInteraction(modify);

		const snap = new Snap({ features });
		this.addedInteractions.push(snap);
		olMapService.addInteraction(snap);
	}

	createNewAdasLayer(title: string): Promise<string> {
		return axios.post(this.adasBaseUrl, { title })
			.then(layer => layer.data.id )
			.catch(error => {
				console.error(error);
				return '';
			});
	};

	getAdasLayers(): any {
		return axios.get(this.adasBaseUrl)
			.then(layers => {
				return layers.data.map(layer => ({
					id: layer.id,
					title: layer.name
				}))
			})
			.catch(error => {
				console.error(error);
				return [];
			});
	};

	changeAdasLayerTitle(layerId: string, title: string): Promise<any> {
		const url = `${this.adasBaseUrl}${layerId}/title`;
		return axios.patch(url, { title })
			.then(layer => layer.data )
			.catch(error => {
				console.error(error);
				return null;
			});
	};

	getWfsVectorSource(id: string): VectorSource {
		const url =
			`${process.env.REACT_APP_ADAS_GEOSERVER_BASE_URL as string}/${process.env.REACT_APP_GEOSERVER_WFS as string}${id}`;

		const vectorSource = new VectorSource({
			format: new GeoJSON(),
			url,
			strategy: bboxStrategy,
			wrapX: false,
			id
		});

		return vectorSource;
	};

	getWfsVectorLayer(id: string, vectorSource: VectorSource): VectorLayer {
		return new VectorLayer({
			source: vectorSource,
			style: (feature) => AdasLayersStyles.getLayerStyle(AdasLayerStylesEnum.DEFAULT),
			zIndex: 19,
			id
		});
	};

	editFeatureStyle(layerId: string, featureId: string, style: AdasLayerStylesEnum ) {
		const feature = olMapService.getFeatureById(layerId, featureId);
		if (!feature) {
			return;
		}
		this.setFeatureStyle(featureId, feature, style);
	};

	updateFeature(layerId: string, feature: Feature<Geometry>, featureId: string): Promise<boolean> {
		feature.setId(`${layerId}.${featureId}`);

		const url = `${this.adasBaseUrl}${layerId}/features/${featureId}`;

		const writer = new GeoJSON({
			defaultDataProjection: 'EPSG:4326'
		});
		const geom = writer.writeFeatureObject(feature, {
			dataProjection: 'EPSG:4326',
			featureProjection: 'EPSG:3857'
		});

		return axios.post(url, geom)
			.then(() => {
				console.log('succeed to UPDATE the Feature!');
				return true;
			})
			.catch((err) => {
				console.error('update Feature ERROR: ', err);
				return false;
			});
	};

	deleteLayer(layerId: string): Promise<boolean> {

		const url = `${this.adasBaseUrl}${layerId}`;

		return axios.delete(url)
			.then(() => {
				console.log(`succeed to DELETE Layer ${layerId}!`);
				return olMapService.deleteLayer(layerId);
			})
			.catch((err) => {
				console.error('delete Layer ERROR: ', err);
				return false;
			});
	}

	async deleteFeature(layerId: string, featureId: string): Promise<boolean> {

		const url = `${this.adasBaseUrl}${layerId}/features/${featureId}`;

		return await axios.delete(url)
			.then(() => {
				console.log(`succeed to DELETE Feature ${featureId}!`);
				return olMapService.deleteFeature(layerId, featureId);
			})
			.catch((err) => {
				console.error('delete Feature ERROR: ', err);
				return false;
			});
	}

}

export default new AdasLayersService();

