import produce from 'immer';
import { CoreActionTypes } from '../actions/core.actions';

const MANAGER_ID = process.env.REACT_APP_MANAGER_ID as string;

export interface IUserInfo {
	user: string;
	name: string;
	email: string;
	sites: string[];
	roles: string[];
}

export interface ICoreState {
	page: string;
	activeMenu: string;
	result: string;
	volume: number;
	userInfo: IUserInfo;
}

const initialState: ICoreState = {
	page: '',
	activeMenu: '',
	result: '',
	volume: 1,
	userInfo: {
		user: MANAGER_ID,
		email: '',
		name: MANAGER_ID,
		sites: [],
		roles: []
	}
};

const coreReducer = produce(
	(state, action) => {
		switch (action.type) {
			case CoreActionTypes.SET_ACTIVE_MENU:
				state.activeMenu = action.payload;
				return;
			case CoreActionTypes.SET_CURRENT_PAGE:
				state.page = action.payload;
				return;
			case CoreActionTypes.SET_VOLUME:
				state.volume = action.payload;
				return;
			case CoreActionTypes.SET_USER_INFO:
				state.userInfo = action.payload;
				return;
		}
	},
initialState,
);

export default coreReducer;
