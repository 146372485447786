import videoContext from "./videoContext";
import React, {useReducer} from "react";


const defaultState = {
    currentTime: 0,
    lastPlayedEventTime: 0,
    isPlayed: true
};

enum videoContextActions {
    CURRENT_TIME_CHANGE = 'CURRENT_TIME_CHANGE',
    LAST_PLAYED_EVENT_TIME_CHANGE = 'LAST_PLAYED_EVENT_TIME_CHANGE',
    IS_PLAYED_CHANGE = 'IS_PLAYED_CHANGE',
    CLEAR = 'CLEAR'
}

const Reducer = (state, action) => {
    if (action.type === videoContextActions.CURRENT_TIME_CHANGE) {
        return {
            currentTime: action.payload,
            lastPlayedEventTime: state.lastPlayedEventTime,
            isPlayed: state.isPlayed
        };

    }
    if (action.type === videoContextActions.LAST_PLAYED_EVENT_TIME_CHANGE) {
        return {
            currentTime: state.currentTime,
            lastPlayedEventTime: action.payload,
            isPlayed: state.isPlayed
        };
    }
    if (action.type === videoContextActions.IS_PLAYED_CHANGE) {
        return {
            currentTime: state.currentTime,
            lastPlayedEventTime: state.lastPlayedEventTime,
            isPlayed: action.payload
        };
    }
    if (action.type === videoContextActions.CLEAR) {
        return defaultState;
    }

    return defaultState;
};


const VideoProvider = ({children}) => {
    const [videoState, dispatchAction] = useReducer(Reducer, defaultState);


    const currentTimeChangeHandler = (value) => {
        dispatchAction({type: videoContextActions.CURRENT_TIME_CHANGE, payload: value});
    };

    const lastPlayedEventTimeChangeHandler = (value) => {
        dispatchAction({type: videoContextActions.LAST_PLAYED_EVENT_TIME_CHANGE, payload: value});
    };
    const isPlayedChangeHandler = (value) => {
        dispatchAction({type: videoContextActions.IS_PLAYED_CHANGE, payload: value});
    };

    const clearContextHandler = () => {
        dispatchAction({type: videoContextActions.CLEAR});
    };

    const videoCtx = {
        currentTime: videoState.currentTime,
        lastPlayedEventTime: videoState.lastPlayedEventTime,
        isPlayed: videoState.isPlayed,
        currentTimeChange: currentTimeChangeHandler,
        lastPlayedEventTimeChange: lastPlayedEventTimeChangeHandler,
        isPlayedChange: isPlayedChangeHandler,
        clearContext: clearContextHandler,
    };


    // @ts-ignore
    return <videoContext.Provider value={videoCtx}>
        {children}
    </videoContext.Provider>;
};
export default VideoProvider;

