import moment from 'moment';

export const convertTimeToText = (time) => moment(time*1000).fromNow();

export const unixToDateTime = (unixTime)=> {
	const timestamp = moment.unix(unixTime);
	return `${timestamp.format('HH:mm') } ${timestamp.format('DD/MM/YY')}`;
};

export const unixToDateFormat = (unixTime, format) => {
	if (typeof unixTime === 'string') {
		unixTime = new Date(unixTime).getTime() / 1000;
	}
	const timestamp = moment.unix(unixTime);
	return timestamp.format(format);
}

export const formatDateRange = (range, isReturnOnlyEnd) => {
	if (!range) {
		return '';
	}
	if (Array.isArray(range)) {
		range = { start: range[0], end: range[1] }
	}
	const format = 'DD-MM-YYYY';
	const start = unixToDateFormat(range.start, format);
	const end = unixToDateFormat(range.end, format);
	if (isReturnOnlyEnd) {
		return `${end}`;
	}
	return `${start} - ${end}`;
}

const degToDms =(deg) => {
	const degrees = Math.floor (deg);
	const minFloat = (deg-degrees)*60;
	const minutes = Math.floor(minFloat);
	const secFloat = (minFloat-minutes)*60;
	const seconds = secFloat.toFixed(1);
	return `${degrees}°${minutes}'${seconds}`;
};

export const convertPositionToText = (coordinates) => `${degToDms(coordinates[1])}N ${degToDms(coordinates[0])}E`;

export const minMaxInt = (val, min, max) =>
	Math.min(
		max,
		Math.max(
			min,
			parseInt(val)
		)
	);
