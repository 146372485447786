import { isEmpty } from 'lodash';
import LineString from 'ol/geom/LineString';
import proj4 from 'proj4';
import { Coords } from '../../definition/simple-types';
import Emitter from '../../emitter';


export class InterpolationService {
	private positions!: LineString;
	private followRouteFlag!: boolean;
	private maxTime = 0;

	constructor() {
		this.positions = new LineString([], 'XYZM');
		Emitter.on('set map.followRoute', value => {
			this.followRouteFlag = value;
		});
	}

	/**
	 *
	 * @description modulo for negative values
	 */
	mod(n: number) {
		return ((n % (2 * Math.PI)) + (2 * Math.PI)) % (2 * Math.PI);
	}

	invertAngle(angle) {
		return (angle + Math.PI) % (2 * Math.PI);
	}

	rotateMapAngle(angle) {
		return angle - (angle + Math.PI) % (2 * Math.PI);
	}

	radToDeg(rad) {
		return rad * 180 / Math.PI ;
	}

	degToRad(deg) {
		return deg * Math.PI / 180;
	}

	deltaMean() {
		const coords = this.positions.getCoordinates() as any;
		const len = coords.length;

		if (len >= 2) {
			return (coords[len - 1][3] - coords[0][3]) / (len - 1);
		}

		/* the minimum speed */
		return 500;
	}

	addPosition(position, rotation, m) {
		const x = position[0];
		const y = position[1];

		const coords = this.positions.getCoordinates() as any[];
		const [previous] = coords.slice(-1);
		const previousHeading = previous && previous[2];
		if(previousHeading){
			let headingDiff = rotation - this.mod(previousHeading);
			/* force the rotation change to be less than 180° */
    		if (Math.abs(headingDiff) > Math.PI) {
      			const sign = (headingDiff >= 0) ? 1 : -1;
      			headingDiff = -sign * (2 * Math.PI - Math.abs(headingDiff));
    		}
    		rotation = previousHeading + headingDiff;
		}

		this.positions.appendCoordinate([x, y, rotation, m] as any);
		if(coords.length > 20){
			this.positions.setCoordinates(coords.slice(-20));
		}
	}

	getCenterWithHeading(size, position, rotation, resolution, reverseView = false): [number, number] {
		const height = size[1];

		if(reverseView){
			return [
				position[0] - Math.sin(rotation) * height * resolution * 6 / 16,
				position[1] - Math.cos(rotation) * height * resolution * 6 / 16
			];
		}

		return [
			position[0] + Math.sin(rotation) * height * resolution * (1 / 4),
			position[1] + Math.cos(rotation) * height * resolution * (1 / 4)
		];
	}

	interpolatedCenter(): number[] | undefined {
		const delta = 1.5;
		let m = Date.now() - (this.deltaMean() * delta);
		m = Math.max(m, this.maxTime);
		this.maxTime = m;
		const c = this.positions.getCoordinateAtM(m, true) as number[];
		if (!isEmpty(c)) {
			const coords = proj4('EPSG:3857', [c[0], c[1]]) as Coords;
			const center = [...coords, c[2]];
			return center;
		}

		return;
	}

	calcArrowAngle(previousPoint: Coords | number[], currentPoint: Coords | number[]): number {
		const dx = currentPoint[0] - previousPoint[0];
		const dy = currentPoint[1] - previousPoint[1];
		return Math.atan2(dx, dy);
	}

	clear() {
		this.positions = new LineString([], 'XYZM');
	}
}

export default new InterpolationService();
