import { withStyles, Checkbox } from '@material-ui/core';
import React from 'react';

interface Iprops {
	checked:boolean,
	onClick?: Function,
	className?: any
}

const TmpWhiteCheckbox = withStyles({
	root: {
		color: '#ffffff',
		'&$checked': {
			color: '#ffffff',
		},
	},
	checked: {},
})((props:any) => <Checkbox color="default" size={'small'} {...props} />);


function WhiteCheckbox({ checked, onClick }: Iprops) {
	return (
		<TmpWhiteCheckbox checked={checked} onClick={onClick} />
	);
}

export default WhiteCheckbox;
