import { IErrorSeverity } from '../../../definition/error-enum';

export interface IErrorPayload {
	severity: IErrorSeverity;
	summary: string;
	detail: string;
}

export enum ErrorActionTypes {
	SEND_ERROR_MESSAGE = '[Error] send error message',
	RESET_ERROR_MESSAGES = '[Error] reset the error message state'
}

export const sendErrorMessage = (payload: IErrorPayload) => dispatch => {
	dispatch({
		type: ErrorActionTypes.SEND_ERROR_MESSAGE,
		payload
	});
};

export const resetErrorMessages = () => dispatch => {
	dispatch({
		type: ErrorActionTypes.RESET_ERROR_MESSAGES
	});
};
