import proj4 from 'proj4';

export const convertWgs84ToWebMercator = (coordinates)=>{
	return proj4( 'EPSG:4326','EPSG:3857', coordinates);
}

export const convertWebMercatorToWgs84 = (coordinates)=>{
	return proj4( 'EPSG:3857', 'EPSG:4326', coordinates);
}

export const convertExtentWebMercatorToWgs84 = (coordinates)=>{
	//ts-ignore
	const topLeft = convertWebMercatorToWgs84([coordinates[0],coordinates[1]]);
	const bottomRight = convertWebMercatorToWgs84([coordinates[2],coordinates[3]]);
	return [...topLeft,...bottomRight];
}
