import React from 'react';
import { useSelector } from 'react-redux';
import {
	getFilteredHazards,
	getFilteredSafetyEvents, getMultiSelected,
} from '../../../store/selectors/mining/mining.selectors';
import mineceptVectorLayerProvider from '../../../core/services/layers/mineceptVectorLayerProvider';
import { MineceptLayers } from '../../../definition/mineceptLayers';
import { convertObstaclesToJson, convertSafetyEventsToJson } from '../../../core/auxilary/gis';
import olMapService from '../../../core/services/ol-map.service';

const updateLayer = (features, layerId, convertToGeoJson)=>{
	const featuresByIds = {};
	features.forEach(feature=>{
		featuresByIds[feature.id] = feature;
	});
	const featuresMissingFromMap = mineceptVectorLayerProvider.removeFeaturesFromLayerByIds(layerId, featuresByIds);
	if(!featuresMissingFromMap) return;
	const featuresToAdd = Object.keys(featuresMissingFromMap).map(key=>featuresMissingFromMap[key]);
	const geoJson = convertToGeoJson(featuresToAdd);
	mineceptVectorLayerProvider.addFeaturesToLayer(layerId, geoJson);

};

const ReduxListeners = () =>{
	const events = useSelector(getFilteredSafetyEvents);
	const hazards = useSelector(getFilteredHazards);
	const multiSelected = useSelector(getMultiSelected);

	try{
		// Update Safety Events
		updateLayer(events,MineceptLayers.safetyEvents, convertSafetyEventsToJson);
		// Update Hazards
		updateLayer(hazards,MineceptLayers.hazards, convertObstaclesToJson);
		//update MultiSelected
		olMapService.multiSelectFeatures(multiSelected);
	}catch (e){
		console.log(e);
	}
	return (<></>);
};

export default ReduxListeners;
