import obstacleStyle from './obstacleLayer-styles';
import safetyEventStyle from './safetyEventLayer-styles';
import { FeatureType } from '../../../../definition/Enums';
import vehicleLayerStyle from './vehicleLayerStyle';

const styleFunction = (feature, resolution) => {
	if(feature.values_.category === FeatureType.hazard) return obstacleStyle.selected(feature);
	if(feature.values_.category === FeatureType.obstacle) return obstacleStyle.selected(feature);
	if(feature.values_.category === FeatureType.safetyEvent) return safetyEventStyle.selected(feature);
	if(feature.values_.category === FeatureType.vehicle) return vehicleLayerStyle.selected(feature, resolution);
	return null;
};

export default styleFunction;
