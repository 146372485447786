import { SensorsActionTypes } from '../actions/sensors.actions';

export interface ISensorMetadataState {
	name: string;
	color: string;
	sensorType: string;
	status?: string;

	followRoute: boolean;

	displayFlags: {
		straight: boolean;
		gradient: boolean;
		dots: boolean;
		headHeatMap: boolean;
		visible: boolean;
	};
}

export interface ISensorState {
	[key: string]: ISensorMetadataState;
}

export interface ISensorsReducerState {
	sensors: ISensorState;
}

export default (state: ISensorsReducerState = { sensors: {} }, action) => {
	switch (action.type) {
		case SensorsActionTypes.TOGGLE_DOTS_VIEW:
		case SensorsActionTypes.TOGGLE_GRADIENT_LINE_VIEW:
		case SensorsActionTypes.TOGGLE_STRAIGHT_VIEW:
		case SensorsActionTypes.TOGGLE_HEAD_HEAT_MAP_VIEW:
		case SensorsActionTypes.TOGGLE_SENSOR_ROUTE_VISIBILITY:
			if (!state.sensors[action.payload.sensorType]) {
				return state;
			}
			const displayFlags = { ...state.sensors[action.payload.sensorType].displayFlags };

			if (action.type === SensorsActionTypes.TOGGLE_DOTS_VIEW) {
				displayFlags.dots = !displayFlags.dots;
			}

			if (action.type === SensorsActionTypes.TOGGLE_GRADIENT_LINE_VIEW) {
				displayFlags.gradient = !displayFlags.gradient;
				if (displayFlags.straight) {
					displayFlags.straight = false;
				}
			}

			if (action.type === SensorsActionTypes.TOGGLE_STRAIGHT_VIEW) {
				displayFlags.straight = !displayFlags.straight;
				if (displayFlags.gradient) {
					displayFlags.gradient = false;
				}
			}

			if (action.type === SensorsActionTypes.TOGGLE_HEAD_HEAT_MAP_VIEW) {
				displayFlags.headHeatMap = !displayFlags.headHeatMap;
			}

			if (action.type === SensorsActionTypes.TOGGLE_SENSOR_ROUTE_VISIBILITY) {
				displayFlags.visible = !displayFlags.visible;
			}

			return {
				...state,
				sensors: {
					...state.sensors,
					[action.payload.sensorType]: {
						...state.sensors[action.payload.sensorType],
						displayFlags
					}
				}
			};

		case SensorsActionTypes.CHANGE_SENSOR_STATUS:
			return {
				...state,
				sensors: {
					...state.sensors,
					[action.payload.sensorType]: {
						...state.sensors[action.payload.sensorType],
						status: action.payload.status
					}
				}
			};

		case SensorsActionTypes.REGISTER_SENSOR:
		{
			let displayFlags = {
				straight: false,
				gradient: false,
				dots: false,
				headHeatMap: false,
				visible: action.payload.visible === 'true' ? true : false
			}
			const defaultTraceModeEnv = process.env.REACT_APP_DEFAULT_TRACE_MODE;
			const defaultTraceMode = defaultTraceModeEnv ? defaultTraceModeEnv.trim().toLowerCase() : 'none';
			switch(defaultTraceMode)
			{
				case 'dots':
					displayFlags.dots=true;
					break;
				case 'straight':
					displayFlags.straight=true;
					break;
				case 'none':
					return;
				default:
					console.warn(`invalid default trace mode: "${defaultTraceMode}"`);
					return;
			}
			return {
				...state,
				sensors: {
					...state.sensors,
					[action.payload.sensorType]: {
						name: action.payload.name,
						color: action.payload.color,
						sensorType: action.payload.sensorType,
						status: action.payload.status,
						followRoute: action.payload.followRoute === 'true' ? true : false,
						displayFlags
					}
				}
			};
		}
	}

	return state;
};
