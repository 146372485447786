import { Fill, Stroke, Style } from 'ol/style';
import GeomType from 'ol/geom/GeometryType';
import { fromExtent as polygonFromExtent } from 'ol/geom/Polygon';
import { buffer } from 'ol/extent';
import { AdasLayerStylesEnum } from '../../../../definition/adas-layer-styles-enum';

class AdasLayersStyles {

	defaultLineStringColor = '#4791eb';
	defaultPolygonStrokeColor = '#d64a4a';
	defaultPolygonFillColor = 'rgba(211, 149, 156, 0.4)';
	defaultWidth = 5;

	editOutLineColor = '#000000';
	selectOutLineColor = '#ffffff';
	editWidth = 10;

	crossLineStringColor = '#e8df68';
	crossPolygonFillColor = 'rgba(249, 40, 64, 0.4)';

	defaultLineStringStroke = new Stroke({
		color: this.defaultLineStringColor,
		width: this.defaultWidth
	});
	defaultPolygonStroke = new Stroke({
		color: this.defaultPolygonStrokeColor,
		width: this.defaultWidth
	});
	defaultPolygonFill = new Fill({
		color: this.defaultPolygonFillColor
	});

	editOutLineStroke = new Stroke({
		color: this.editOutLineColor,
		width: this.editWidth
	});

	selectOutLineStroke = new Stroke({
		color: this.selectOutLineColor,
		width: this.editWidth
	});

	selectBboxPolygon = new Stroke({
		color: '#ffffff',
		width: 2,
		lineDash: [7]
	});

	crossLineStringStroke = new Stroke({
		color: this.crossLineStringColor,
		width: this.defaultWidth
	});
	crossPolygonFill = new Fill({
		color: this.crossPolygonFillColor
	});

	private _defaultStyles = [
		new Style({
			geometry: (feature) => {
				const geom = feature.getGeometry();
				if (geom.getType() !== GeomType.POLYGON) {
					return undefined;
				}
				return geom;
			},
			stroke: this.defaultPolygonStroke,
			fill: this.defaultPolygonFill
		}),
		new Style({
			geometry: (feature) => {
				const geom = feature.getGeometry();
				if (geom.getType() !== GeomType.LINE_STRING) {
					return undefined;
				}
				return geom;
			},
			stroke: this.defaultLineStringStroke
		})
	];

	private _highlightStyles = [
		new Style({
			stroke: this.editOutLineStroke
		}),
		new Style({
			geometry: (feature) => {
				const geom = feature.getGeometry();
				if (geom.getType() !== GeomType.POLYGON) {
					return undefined;
				}
				return geom;
			},
			stroke: this.defaultPolygonStroke,
			fill: this.defaultPolygonFill
		}),
		new Style({
			geometry: (feature) => {
				const geom = feature.getGeometry();
				if (geom.getType() !== GeomType.LINE_STRING) {
					return undefined;
				}
				return geom;
			},
			stroke: this.defaultLineStringStroke
		})
	];

	private _editFeatureStyles = [
		new Style({
			stroke: this.selectOutLineStroke
		}),
		new Style({
			geometry: (feature) => {
				const geom = feature.getGeometry();
				if (geom.getType() !== GeomType.POLYGON) {
					return undefined;
				}
				return geom;
			},
			stroke: this.defaultPolygonStroke,
			fill: this.defaultPolygonFill
		}),
		new Style({
			geometry: (feature) => {
				const geom = feature.getGeometry();
				if (geom.getType() !== GeomType.LINE_STRING) {
					return undefined;
				}
				return geom;
			},
			stroke: this.defaultLineStringStroke
		}),
		new Style({
			geometry: (feature) => {
				const extent = feature.getGeometry().getExtent();
				const value = this.editWidth + 3;
				const bufferExtent = buffer(extent, value);
				return polygonFromExtent(bufferExtent);
			},
			stroke: this.selectBboxPolygon
		})
	];

	private _crossStyles = [
		new Style({
			geometry: (feature) => {
				const geom = feature.getGeometry();
				if (geom.getType() !== GeomType.POLYGON) {
					return undefined;
				}
				return geom;
			},
			stroke: this.defaultPolygonStroke,
			fill: this.crossPolygonFill
		}),
		new Style({
			geometry: (feature) => {
				const geom = feature.getGeometry();
				if (geom.getType() !== GeomType.LINE_STRING) {
					return undefined;
				}
				return geom;
			},
			stroke: this.crossLineStringStroke
		})
	];

	public getLayerStyle(style: AdasLayerStylesEnum): Style {
		switch (style) {
			case AdasLayerStylesEnum.DEFAULT:
				return this._defaultStyles;
			case AdasLayerStylesEnum.HIGHLIGHT:
				return this._highlightStyles;
			case AdasLayerStylesEnum.EDIT:
				return this._editFeatureStyles;
			case AdasLayerStylesEnum.CROSS:
				return this._crossStyles;
			case AdasLayerStylesEnum.NONE:
				return null;
			default:
				return this._defaultStyles;
		}
	}
}

export default new AdasLayersStyles();
