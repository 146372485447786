import React from 'react';
import useStyles from './sortBar.css';
import { SortBy } from '../../../../definition/Enums';

import Radio from '@material-ui/core/Radio';
import closeImage from '../../../../assets/images/clear.svg';

interface Iprops{
	title: string,
	setSort: (SortBy)=>void,
	currentValue: SortBy,
	options: Array<{
		label:string,
		value: SortBy
	}>
	close: ()=>void
}

function SortBar({setSort, currentValue, options, close, title}:Iprops) {

	const classes = useStyles();

	return (
		<div className={classes.root}>
			<div  className={classes.titleBar}>
				<span>{title}</span>
				<img className={classes.closeButton} src={ closeImage } onClick={close}/>
			</div>
		{
			options.map(option=>(
				<div key={option.value}>

					<Radio
						checked={currentValue === option.value}
						onChange={()=>setSort(option.value)}
						className={classes.radioButton}
						classes={{
							checked:classes.radioButton
						}}
						value={option.value}
					/>
					<span>{option.label}</span>
				</div>
			))
		}
		</div>
	);
}

export default SortBar;
