import classNames from 'classnames';
import React, { Component } from 'react';
import enhanceWithClickOutside from 'react-click-outside';
import { connect } from 'react-redux';
import defaultImage from '../../../assets/images/default.png';
import satellite from '../../../assets/images/satellite-detailed.png';
import { setActiveMenu } from '../../../store/actions/core.actions';
import { sourceMapOptions } from '../../../definition/source-map-options';
import { addMapLayer, removeMapLayer } from '../../../store/actions/map.actions';
import './SourceProviderPanel.scss';

interface IProps {
	display: boolean;
    parentClass: string;

    menuClass: string;
    mapSources: sourceMapOptions[];
    setActiveMenu: (string) => void;
    addMapSource: (sourceMapOptions) => void;
    removeMapSource: (sourceMapOptions) => void;
}
class SourceProviderPanel extends Component<IProps, void> {

	handleClickOutside(event) {
		if (event.target.closest(`.${this.props.parentClass}`) || event.target.classList.contains(this.props.parentClass)) {
			return;
        }

        if (event.target.closest(`.${this.props.menuClass}`) || event.target.classList.contains(this.props.menuClass)) {
			return;
        }

        if(!this.props.display){
            return;
        }
		this.props.setActiveMenu('');
	}

	get className() {
		return classNames({
			'panel-container': true,
			'source-panel-container': true,
            'visible': this.props.display,

		});
    }

    hasMap(mapSourceValue:sourceMapOptions){
        return this.props.mapSources.indexOf(mapSourceValue) > -1;
    }

    toggleMapView(mapSourceValue:sourceMapOptions){
        if(this.hasMap(mapSourceValue)) {
            this.props.removeMapSource(mapSourceValue);
        }else{
            this.props.addMapSource(mapSourceValue);
        }
    }

	render() {
		return (
			<div className={this.className}>
				<div className='map-source-row'>
					<div className='map-source-box'>
						<div className={'map-source-icon ' + (this.hasMap(sourceMapOptions.ROADS) ? "selected" : "") }
                            onClick={() => this.toggleMapView(sourceMapOptions.ROADS)}
                        >
                            <img src={defaultImage} />
                        </div>
						<div className='map-source-description'>Default</div>
					</div>
					<div className='map-source-box'>
						<div className={'map-source-icon ' + (this.hasMap(sourceMapOptions.SATELLITE) ? "selected" : "") }
                            onClick={() => this.toggleMapView(sourceMapOptions.SATELLITE)}
                        >
                            <img src={satellite}/>
                        </div>
						<div className='map-source-description'>Satellite</div>
					</div>
					<div className='map-source-box'>
						<div className={'map-source-icon ' + (this.hasMap(sourceMapOptions.DETAILED_SATELLITE) ? "selected" : "") }
                            onClick={() => this.toggleMapView(sourceMapOptions.DETAILED_SATELLITE)}
                        >
                            <img src={satellite}/>
                        </div>
						<div className='map-source-description'>Detailed<br/> Satellite</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
    mapSources: state.map.mapSources || []
});

const mapDispatchToProps = {
    setActiveMenu,
    removeMapSource: removeMapLayer,
    addMapSource: addMapLayer
};

export default connect(mapStateToProps, mapDispatchToProps)(enhanceWithClickOutside(SourceProviderPanel));
