import { makeStyles } from '@material-ui/core/styles';
import profileConfig from '../../../core/profiles/profileConfig';

const flipReverseView = profileConfig().flipSafeUnloadingScreen;

export default makeStyles({
	root: {
		zIndex: 1,
		position: "absolute",
		height: '100%',
		width: '100%'
	},
	arrow:{
		position: 'absolute',
		left: 'calc(50% - 5px)',
		transform: `rotate(${flipReverseView?270:90}deg)`,
		width:600,
		height:10,
	},
	reverseScreenLogo:{
		position:'fixed',
		bottom:10,
		left: 10,
		width:75,
	}
});
