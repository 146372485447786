import { getCoord, lineString } from '@turf/turf';
import { Feature as GeoFeature, Geometry, Point } from 'geojson';
import Feature from 'ol/Feature';
import { GeoJSON } from 'ol/format';
import { LineString, MultiPoint } from 'ol/geom';
import VectorSource from 'ol/source/Vector';
import { Circle as CircleStyle, Stroke, Style } from 'ol/style';
import { IRegisterSensorState } from '../../features/sensors/actions/sensors.actions';
import { ArrowFeature } from './arrow-feature';

export class VectorSourceService {
	source!: VectorSource;
	color: string;
	sensorType: string;
	line!: boolean;
	dot!: boolean;
	featuresIds: string[] = [];
	multiPoint = new MultiPoint([]);
	arrowFeature!: ArrowFeature;
	dataProjection = 'EPSG:4326';
	featureProjection = 'EPSG:3857';

	constructor(sensor: IRegisterSensorState) {
		this.sensorType = sensor.sensorType;
		this.color = sensor.color;
		this.arrowFeature = new ArrowFeature(this.sensorType);
	}

	getSource(): VectorSource {
		this.source = new VectorSource({} as any);

		this.source.addFeature(
			this.multiPointFeature()
		);

		this.source.addFeature(
			this.getArrowFeature()
		);
		return this.source;
	}

	getArrowFeature(): Feature {
		return this.arrowFeature.getFeature();
	}

	multiPointFeature(): Feature {
		const feature = new Feature({
			geometry: this.multiPoint,
			type: 'point',
			id: 'multi-point'
		});
		feature.setId('multi-point');
		return feature;
	}

	removeFeaturesFromHead(count: number): void {
		this.removeMultiPoint(count);
		const featuresIdsToRemove = this.featuresIds.splice(0, count);

		featuresIdsToRemove.forEach((featureId: string) => {
			const lineFeature = this.source.getFeatureById(`line-${featureId}`);
			if (lineFeature) {
				this.source.removeFeature(lineFeature);
			}
		});
	}

	addPoint(point: GeoFeature<Point, any>): void {
		this.multiPoint.appendPoint(this.getFeatureGeometry(point));
	}

	getFeatureGeometry(geoFeature: GeoFeature<Geometry, any>): Geometry {
		return new GeoJSON()
			.readFeature(geoFeature,
				{
					dataProjection: this.dataProjection,
					featureProjection: this.featureProjection
				})
			.getGeometry();
	}

	removeMultiPoint(count: number): void {
		const coordinates = this.multiPoint.getCoordinates();
		if (coordinates.length > count + 2) {
			this.multiPoint.setCoordinates(coordinates.slice(count));
		}
	}

	addNewFeature(point: GeoFeature<Point, any>, previousPoint?: GeoFeature<Point, any>): void {
		this.addPoint(point);
		this.arrowFeature.updateFeature(this.getFeatureGeometry(point));
		this.arrowFeature.updateImageRotation(point.properties.rotation);

		if (previousPoint) {
			// @todo check if there was no disruption and create single line string feature
			const lineFeature = this.addNewLineString(point, previousPoint);
			this.source['addFeatureInternal'](lineFeature);
		}
	}

	addNewLineString(point: GeoFeature<Point, any>, previousPoint): Feature {
		const pointCoordinates = getCoord(point);
		const previousPointCoordinates = getCoord(previousPoint);

		const line: GeoFeature<LineString, any>= lineString([pointCoordinates as number[], previousPointCoordinates as number[]]);

		const lineFeature = new Feature({
			geometry: this.getFeatureGeometry(line),
			type: 'line'
		});
		lineFeature.setId(`line-${point.properties.id}`);
		this.featuresIds.push(point.properties.id);
		return lineFeature;
	}

	getVectorStyle(): (feature: Feature) => { [key: string]: Style | Style[] } {
		const image = new CircleStyle({
			radius: 2,
			fill: new Stroke({ color: 'black' }),
			stroke: new Stroke({ color: this.color, width: 2 })
		});

		const styles = {
			line: [
				new Style({
					stroke: new Stroke({
						width: 2,
						color: this.color
					})
				})
			],
			point: new Style({ image })
		};

		return (feature: Feature) => {
			if (!this.dot && feature.get('type') === 'point') {
				return null;
			}
			if (!this.line && feature.get('type') === 'line') {
				return null;
			}
			return styles[feature.get('type')];
		};
	}

	clear(): void {
		this.featuresIds = [];
		this.source.clear();

		const multiPoint = this.multiPointFeature() as Feature;
		const arrowFeature = this.getArrowFeature() as Feature;

		multiPoint.getGeometry()['setCoordinates']([]);

		this.source.addFeature(multiPoint);
		this.source.addFeature(arrowFeature);
	}

	setDotVisibility(isVisible: boolean) {
		this.dot = isVisible;
		this.source.changed();
	}

	setLineVisibility(isVisible: boolean) {
		this.line = isVisible;
		this.source.changed();
	}

	setFollowRoute(value: boolean){
		this.arrowFeature.followRoute = value;
	}
}
