import { makeStyles } from '@material-ui/core/styles';

interface Props{
	menuOpen:boolean
}

export default makeStyles({
	root:(props:Props)=> ({
			display: 'flex',
			position: 'absolute',
			justifyContent: 'space-between',
			alignContent: 'center',
			left: 33,
			bottom: 51,
			zIndex: 1,
			width: 250,
			height: 80,
			fontFamily: 'Mukta Mahee',
			backgroundColor: 'white',
			borderRadius: 6,
			padding: 10,
			transform:  `translate(${props.menuOpen? 290 : 0 }px,0px)`,
			transition: '0.5s',
	}),
	closeButton: {
		color: '#0f2365',
		cursor: 'pointer',
		display: 'flex',
		alignItems: 'center',
	},
	closeSymbol:{
		width:14,
		height:14
	},
	id:{
		color: '#0f2365',
		fontSize: 20,
		fontWeight:600,
	},
	lastSeen:{
		color: '#0f2365',
		fontSize: 15,
	},
	noSignal:{
		color: '#FF2500',
		fontSize: 16,
		fontWeight:500,
		paddingLeft: 10,
	}
});
