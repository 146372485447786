import coreReducer from './core.reducer';
import sensorReducer from '../../features/sensors/reducers/sensors.reducer';
import routeReducer from './route.reducer';
import mapReducer from './map.reducer';
import adasLayersReducer from '../../features/adas-layers/reducers/adas-layers.reducer';
import adasAlertsReducer from '../../features/adas-alerts/reducers/adas-alerts.reducer';
import errorMessagesReducer from '../../features/error-messages/reducers/error-messages.reducer';
import miningReducer from './mining/mining.reducer';

export default {
	core: coreReducer,
	sensors: sensorReducer,
	route: routeReducer,
	map: mapReducer,
	adasLayers: adasLayersReducer,
	adasAlerts: adasAlertsReducer,
	error: errorMessagesReducer,
	mining: miningReducer
};
